import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropdownItem,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackButton,
  PageHeader,
  KebabDropdown,
} from '../../components';
import { getCurrIsolate, deleteIsolate } from '../../redux/samples/actions';
import IsolateForm from './IsolateForm';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { useConfirmAction, useRole } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';

export default function EditIsolate () {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const confirmAction = useConfirmAction();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currIsolate = useSelector((s) => s.samples.currIsolate);
  const pending = useSelector((s) => s.samples.getCurrIsolatePending);
  const error = useSelector((s) => s.samples.getCurrIsolateError);

  // Load currIsolate if not already
  useEffect(() => {
    if (currOrg && _.get(currIsolate, 'id') != id) {
      dispatch(getCurrIsolate(id));
      setStartedLoading(true);
    }
  }, [currOrg, currIsolate]);

  // Failed to load isolate. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/water-bodies`);
    }
  }, [startedLoading, error, history]);

  const removeIsolate = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'isolate',
      subject: 'this isolate',
      onConfirm: () => dispatch(deleteIsolate(Number(id))),
      pendingPath: 'samples.deleteIsolatePending',
      errorPath: 'samples.deleteIsolateError',
      onSuccess: history.goBack,
    });
  }, [currOrg, currIsolate, confirmAction]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Isolate"
        coverMenu
      >
        <BackButton />
        { role != 'read' && (
          <KebabDropdown right>
            <DropdownItem onClick={removeIsolate}>
              <FontAwesomeIcon icon="trash" />
              Delete Isolate
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      { (!currIsolate || pending)
        ?
        <div className={layout.pageContent}>
          <div className="text-center">
            <Spinner />
          </div>
        </div>
        :
        <IsolateForm
          onSubmit={history.goBack}
          isolate={currIsolate}
        />
      }
    </div>
  );
}

