import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import layout from '../../styles/layout.module.scss';
import { signup, loginRequired } from '../../redux/account/actions';
import { getInvite } from '../../redux/orgs/selectors';
import { validateInvite } from '../../redux/orgs/actions';
import { GeneralErrors, PageHeader } from '../../components';

export default function Signup ()  {
  const dispatch = useDispatch();
  const history = useHistory();
  const { key } = useParams();
  const signupPending = useSelector((s) => s.account.signupPending);
  const signupError = useSelector((s) => s.account.signupError);
  const invite = useSelector(getInvite);
  const invitePending = useSelector((s) => s.orgs.invitePending);
  const inviteError = useSelector((s) => s.orgs.inviteError);

  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm();
  const { isSubmitted } = formState;

  useEffect(() => {
    dispatch(validateInvite(key));
  }, [dispatch, key]);

  useEffect(() => {
    if (invite) {
      if (invite.org.user_exists) {
        // Signing up with existing email. Ask for login
        dispatch(loginRequired(`/app/accept/${invite.key}`));
        history.replace('/login');
      } else {
        // Pre-populate email via invite
        reset({ email: invite.org.email });
      }
    }
  }, [invite, reset]);

  const onSubmit = useCallback((formData) => {
    dispatch(signup({
      ...formData,
      invite: invite.key,
    }));
  }, [invite]);

  return (
    <div className={layout.app}>
      <div className={layout.page}>
        <PageHeader title="Sign Up" />
        <div className={layout.pageContent}>
          <div className={layout.responsiveContainer}>
            <p className={layout.pageDesc}>
              Welcome to the Fish Health Clinical Database!  Please fill out
              the fields below to complete your signup. If you&apos;re already
              a member, click <Link to="/login">HERE</Link> to login!
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  required
                  innerRef={register}
                  type="text"
                  name="name"
                  placeholder="Name"
                  invalid={ isSubmitted && _.has(signupError, 'name') }
                />
                <FormFeedback role="alert">
                  { _.get(signupError, 'name') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  required
                  innerRef={register}
                  type="text"
                  name="email"
                  placeholder="Email"
                  invalid={ isSubmitted && _.has(signupError, 'email') }
                />
                <FormFeedback role="alert">
                  { _.get(signupError, 'email') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  required
                  innerRef={register}
                  type="password"
                  name="password1"
                  placeholder="Password"
                  autoComplete="new-password"
                  invalid={ isSubmitted && _.has(signupError, 'password1') }
                />
                <FormFeedback role="alert">
                  { _.get(signupError, 'password1') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  required
                  innerRef={register}
                  type="password"
                  name="password2"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  invalid={ isSubmitted && _.has(signupError, 'password2') }
                />
                <FormFeedback role="alert">
                  { _.get(signupError, 'password2') }
                </FormFeedback>
              </FormGroup>
              { isSubmitted &&
                <GeneralErrors
                  error={signupError}
                  includeKeys={['invite']}
                />
              }
              <GeneralErrors
                error={inviteError}
                includeKeys={['invite']}
              />
              <Button
                color="primary"
                disabled={signupPending || invitePending || !!inviteError}
                block
              >
                Join {_.get(invite, 'org.name', ' ')}
                {
                  (signupPending || invitePending) &&
                  <Spinner size="sm" />
                }
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
