import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { getCurrOrg } from '../../redux/orgs/selectors';
import { setUserRole, inviteUser } from '../../redux/users/actions';
import { CancelButton, GeneralErrors } from '../../components';

import layout from '../../styles/layout.module.scss';

export default function UserForm ({ user, onSubmit }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
  } = useForm({ defaultValues: user || {} });
  const { isSubmitted } = formState;

  const currOrg = useSelector(getCurrOrg);
  const pending = useSelector((s) => s.users.changeUserPending);
  const error = useSelector((s) => s.users.changeUserError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    if (user) {
      dispatch(setUserRole(currOrg.slug, user.id, data.role));
    } else {
      dispatch(inviteUser({ ...data, org: currOrg.slug }));
    }
  }, [user, currOrg]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>User Email</Label>
          <Input
            required
            innerRef={register}
            type="email"
            name="email"
            placeholder="User Email"
            invalid={ isSubmitted && _.has(error, 'email') }
            disabled={!!user}
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'email') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Access level</Label>
          <CustomInput
            innerRef={register}
            id="role"
            type="select"
            name="role"
            className="form-control"
            invalid={ isSubmitted && _.has(error, 'role') }
          >
            <option value="read">Read access</option>
            <option value="write">Write access</option>
            <option value="admin">Admin access</option>
          </CustomInput>
          <FormFeedback role="alert">
            { _.get(error, 'role') }
          </FormFeedback>
        </FormGroup>
        { isSubmitted && <GeneralErrors error={error} /> }
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Submit
          { pending && <Spinner size="sm" /> }
        </Button>
        <CancelButton />
      </Form>
    </div>
  );
}
UserForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
};
