export const RESET_LOAD_CLIENTS = 'RESET_LOAD_CLIENTS';
export const LOAD_CLIENTS = 'LOAD_CLIENTS';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_ERROR = 'LOAD_CLIENTS_ERROR';
export const CHANGE_CLIENT_ERROR = 'CHANGE_CLIENT_ERROR';
export const CHANGE_CLIENT_PENDING = 'CHANGE_CLIENT_PENDING';
export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_ERROR = 'GET_CLIENT_DETAILS_ERROR';
export const GET_EDIT_CLIENT = 'GET_EDIT_CLIENT';
export const GET_EDIT_CLIENT_SUCCESS = 'GET_EDIT_CLIENT_SUCCESS';
export const GET_EDIT_CLIENT_ERROR = 'GET_EDIT_CLIENT_ERROR';
export const SET_EDIT_CLIENT = 'SET_EDIT_CLIENT';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR';
export const UPDATE_CLIENT_ERROR = 'UPDATE_CLIENT_ERROR';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR';

export const loadClients = (slug, page, query='') => {
  return {
    type: LOAD_CLIENTS,
    slug,
    page,
    query,
  };
};

export const createClient = (formData) => {
  return {
    type: CREATE_CLIENT,
    formData,
  };
};

export const updateClient = (formData) => {
  return {
    type: UPDATE_CLIENT,
    formData,
  };
};

export const deleteClient = (id) => {
  return {
    type: DELETE_CLIENT,
    id,
  };
};

export const setEditClient = (client) => {
  return {
    type: SET_EDIT_CLIENT,
    client,
  };
};

export const getEditClient = (id) => {
  return {
    type: GET_EDIT_CLIENT,
    id,
  };
};
