import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getCurrWaterBody } from '../../redux/waterbodies/actions';
import WaterBodyForm from './WaterBodyForm';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';

export default function EditWaterBody () {
  const dispatch = useDispatch();
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currWaterBody = useSelector((s) => s.waterBodies.currWaterBody);
  const pending = useSelector((s) => s.waterBodies.getCurrWaterBodyPending);
  const error = useSelector((s) => s.waterBodies.getCurrWaterBodyError);

  // Load currWaterBody if not already
  useEffect(() => {
    if (currOrg && _.get(currWaterBody, 'id') != id) {
      dispatch(getCurrWaterBody(id));
      setStartedLoading(true);
    }
  }, [currOrg, currWaterBody]);

  // Failed to load waterbody. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/water-bodies`);
    }
  }, [startedLoading, error, history]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Water Body"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { (!currWaterBody || pending)
        ?
        <div className="text-center">
          <Spinner />
        </div>
        :
        <WaterBodyForm
          onSubmit={history.goBack}
          waterBody={currWaterBody}
        />
      }
    </div>
  );
}
