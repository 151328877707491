import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import * as _ from 'lodash';

import { useRole, useConfirmAction } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { deleteClient, setEditClient } from '../../redux/clients/actions';
import { resetWaterBodies } from '../../redux/waterbodies/actions';

import text from '../../styles/text.module.scss';
import tables from '../../styles/tables.module.scss';
import styles from './Client.module.scss';

export default function Client ({ client }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const currOrg = useSelector(getCurrOrg);
  const confirmAction = useConfirmAction();

  const onEditClient = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(setEditClient(client));
    history.push(`/app/${currOrg.slug}/clients/${client.id}/edit`);
  }, [currOrg, client, history]);

  const onDeleteClient = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    confirmAction({
      action: 'delete',
      subjectType: 'client',
      subject: client.name,
      onConfirm: () => dispatch(deleteClient(client.id)),
      pendingPath: 'clients.deleteClientPending',
      errorPath: 'clients.deleteClientError',
    });
  }, [client, currOrg, confirmAction]);

  const gotoClientWaterbody = useCallback(() => {
    dispatch(resetWaterBodies(client));
    history.push(`/app/${currOrg.slug}/water-bodies/`);
  }, [currOrg, history, client]);

  return (
    <tr
      onClick={gotoClientWaterbody}
      className={styles.clientLink}
    >
      <td>
        <div className={cx(styles.name, text.ellipsize)}>
          { client.name }
        </div>
        {client &&
          <i className={cx(styles.owner, text.ellipsize)}>
            Owner: { client.owner }
          </i>
        }
      </td>
      <td className={tables.actions}>
        { (!_.includes(['read'], role)) && (
          <Button
            color="link"
            title={`Edit info for ${client.name}`}
            onClick={onEditClient}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </Button>
        )}
        { (!_.includes(['read'], role)) && (
          <Button
            color="link"
            title={`Remove ${client.name}`}
            onClick={onDeleteClient}
          >
            <FontAwesomeIcon icon="trash" />
          </Button>
        )}
      </td>
    </tr>
  );
}
