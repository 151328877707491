import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import SearchResults from './SearchResults';
import NotFound from '../NotFound';
import SearchFilters from './SearchFilters';

export default function SearchIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <SearchResults />
      </Route>
      <Route path={`${path}/filter`}>
        <SearchFilters />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
