import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import cx from 'classnames';

import styles from './Report.module.scss';

export function ReportSection (props) {
  const {
    table,
    title,
    children,
    ...rest
  } = props;

  return (
    <div
      className={cx(
        styles.section,
        { [styles.sectionTable]: table },
      )}
      {...rest}
    >
      <h3 className={styles.sectionTitle}>
        { title }
      </h3>
      <Row className={styles.sectionChildren}>
        { children }
      </Row>
    </div>
  );
}
ReportSection.propTypes = {
  title: PropTypes.string,
  table: PropTypes.bool,
};
ReportSection.defaultProps = {
  table: false,
};

