import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './PageHeader.module.scss';

export function PageHeader (props) {
  const {
    title,
    placeholder,
    coverMenu,
    children,
  } = props;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <h3
      id="maincontent"
      className={cx(
        styles.pageHeader,
        { [styles.pageHeaderCover]: coverMenu },
      )}
      tabIndex={-1}
    >
      { children }
      <span className={cx({[styles.loadingText]: placeholder})}>
        { title }
      </span>
    </h3>
  );
}
PageHeader.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.bool,
  coverMenu: PropTypes.bool,
};
PageHeader.defaultProps = {
  title: 'Welcome to the App!',
  placeholder: false,
  coverMenu: false,
};
