import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import cx from 'classnames';

import { useClients } from '../../utils/hooks';
import { SearchDropdown } from './SearchDropdown';

import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const ClientSelect = React.forwardRef((props, ref) => {
  const {
    value: client,
    onChange,
    invalid,
    placeholder,
    clearable,
  } = props;
  const {
    clients,
    hasMore,
    loading,
    noResults,
    reset,
    loadMore,
  } = useClients();

  const onClientSelected = useCallback((c) => onChange(c), [onChange]);

  const onClear = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange(null);
  }, [onChange]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder={placeholder}
      displayText={_.get(client, 'name')}
      firstLoad={reset}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      onClear={client && clearable ? onClear : undefined}
    >
      { noResults && (
        <div className="text-center">
          No clients
        </div>
      )}
      { _.map(
        clients,
        (c) => (
          <DropdownItem
            key={`client-${c.id}`}
            onClick={() => onClientSelected(c)}
            className={cx({'selected': c.id == _.get(client, 'id')})}
          >
            <div className={text.ellipsize}>
              { c.name }
            </div>
            <i className={text.ellipsize}>
              Owner: { c.owner }
            </i>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
ClientSelect.displayName = 'ClientSelect';
ClientSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};
ClientSelect.defaultProps = {
  placeholder: 'Select Client',
  clearable: false,
  invalid: false,
};
