import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSampleMedia } from '../../redux/samples/selectors';
import { loadSampleMedia, RESET_LOAD_SAMPLE_MEDIA } from '../../redux/samples/actions';
import { action } from '../../redux/helpers';

export const useSampleMedia = (id) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const sampleMedia = useSelector(getSampleMedia);
  const currPage = useSelector((s) => s.samples.currSampleMediaPage);
  const hasMore = useSelector((s) => s.samples.hasMoreSampleMedia);
  const loading = useSelector((s) => s.samples.loadSampleMediaPending);
  const sampleMediaUploads = useSelector((s) => s.samples.sampleMediaUploads);
  const uploads = useMemo(() => {
    return _.values(_.get(sampleMediaUploads, id, {}));
  }, [sampleMediaUploads, id]);

  useEffect(() => {
    dispatch(action(RESET_LOAD_SAMPLE_MEDIA));
    setResetCalled(true);
  }, []);

  const loadMore = useCallback(() => {
    dispatch(loadSampleMedia(id, currPage + 1));
  }, [currPage, id]);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(sampleMedia, 'length', 0) == 0
    );
  }, [loading, hasMore, sampleMedia]);

  return {
    // Ignore redux list until first load
    sampleMedia: firstLoad.current ? sampleMedia : [],
    uploads,
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    loadMore,
  };
};
