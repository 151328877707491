export default [
  { value: '', text: '-- Select condition --' },
  { value: 'mori', text: 'Picked up moribund' },
  { value: 'alive', text: 'Brought in alive' },
  { value: 'fresh', text: 'Picked up fresh dead' },
  { value: 'dead', text: 'Brought in moribund' },
  { value: 'hook', text: 'Hook caught' },
  { value: 'seine', text: 'Seine caught' },
  { value: 'sick', text: 'Sick' },
  { value: 'other', text: 'Other' },
];
