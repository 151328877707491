import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';

import layout from '../../styles/layout.module.scss';

import { resetPassword } from '../../redux/account/actions';
import { GeneralErrors, PageHeader } from '../../components';

export default function PasswordReset () {
  const dispatch = useDispatch();
  const { uid, token } = useParams();
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitted } = formState;
  const pending = useSelector((s) => s.account.resetPasswordPending);
  const error = useSelector((s) => s.account.resetPasswordError);

  const submitSuccess = isSubmitted && !pending && !error;

  const onSubmit = useCallback((data) => {
    dispatch(resetPassword({
      ...data,
      uid,
      token,
    }));
  }, [uid, token]);

  return (
    <div className={layout.app}>
      <div className={layout.page}>
        <PageHeader title="Reset Password" />
        <div className={layout.pageContent}>
          <div className={layout.responsiveContainer}>
            <p className={layout.pageDesc}>
              {
                submitSuccess
                  ? 'Password has successfully been updated!'
                  : 'Please provide a new password.'
              }
            </p>
            { !submitSuccess && (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label>New Password</Label>
                  <Input
                    required
                    innerRef={register}
                    type="password"
                    name="new_password1"
                    placeholder="New Password"
                    invalid={ isSubmitted && _.has(error, 'new_password1') }
                  />
                  <FormFeedback role="alert">
                    { _.get(error, 'new_password1') }
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input
                    required
                    innerRef={register}
                    type="password"
                    name="new_password2"
                    placeholder="Confirm Password"
                    invalid={ isSubmitted && _.has(error, 'new_password2') }
                  />
                  <FormFeedback role="alert">
                    { _.get(error, 'new_password2') }
                  </FormFeedback>
                </FormGroup>
                { isSubmitted && <GeneralErrors error={error} /> }
                <Button
                  color="primary"
                  disabled={pending}
                  block
                >
                  Reset Password
                  { pending && <Spinner size="sm" /> }
                </Button>
              </Form>
            )}
            { submitSuccess && (
              <Link
                className="btn btn-primary btn-block"
                to="/login"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
