import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './Tiles.module.scss';

const BaseTile = (props) => {
  const {
    className,
    linkTo,
    transparent,
    ...rest
  } = props;

  const Tag = linkTo ? Link : 'button';

  return (
    <Tag
      className={cx(
        className,
        styles.tile,
        { [styles.tileTrans]: transparent },
      )}
      to={linkTo}
      {...rest}
    ></Tag>
  );
};

export const Tile = React.memo(BaseTile);
