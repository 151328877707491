import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { getWaterBodyFilters, getNumFilters } from '../../redux/waterbodies/selectors';
import { updateWaterBodyFilters } from '../../redux/waterbodies/actions';
import { ClientSelect } from '../inputs/ClientSelect';

import styles from './SimpleWaterBodyFilter.module.scss';

export function SimpleWaterBodyFilter () {
  const dispatch = useDispatch();
  const filters = useSelector(getWaterBodyFilters);
  const numFilters = useSelector(getNumFilters);
  const { orgSlug } = useParams();

  const updateClient = useCallback((client) => {
    dispatch(updateWaterBodyFilters({ client }));
  }, []);

  return (
    <div className={styles.filter}>
      <ClientSelect
        value={filters.client}
        onChange={updateClient}
        placeholder="Narrow by client"
        clearable
      />
      <Link
        className={cx('btn btn-primary', styles.filterBtn)}
        to={`/app/${orgSlug}/water-bodies/search`}
        data-count={numFilters > 0 ? numFilters : undefined}
      >
        <FontAwesomeIcon
          className={styles.icon}
          icon="filter"
        />
      </Link>
    </div>
  );
}
