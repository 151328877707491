import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import layout from '../styles/layout.module.scss';
import { PageHeader } from '../components';

export default function NotFound () {
  return (
    <div className={layout.page}>
      <PageHeader title="Error" />
      <h1 className={cx('text-center p-5', layout.pageContent)}>
        <FontAwesomeIcon icon="exclamation-triangle" />{' '}Page Not Found
      </h1>
    </div>
  );
}
