import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import IsolateForm from './IsolateForm';

import layout from '../../styles/layout.module.scss';

export default function CreateIsolate () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currIsolate = useSelector((s) => s.samples.currIsolate);

  const goBack = useCallback(() => {
    history.replace(`/app/${orgSlug}/samples/isolates/${_.get(currIsolate, 'id')}/edit`);
    history.goBack();
  }, [orgSlug, currIsolate]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Isolate"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <IsolateForm onSubmit={goBack} />
    </div>
  );
}
