import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import ProdCycleDetail from './ProdCycleDetail';
import EditProdCycle from './EditProdCycle';
import NotFound from '../NotFound';
import CreateActivity from '../activities/CreateActivity';
import CreateReport from '../reports/CreateReport';

export default function ProdCycleIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:id/edit`}>
        <EditProdCycle />
      </Route>
      <Route exact path={`${path}/:id/log-activity`}>
        <CreateActivity />
      </Route>
      <Route exact path={`${path}/:id/new-report`}>
        <CreateReport />
      </Route>
      <Route path={`${path}/:id`}>
        <ProdCycleDetail />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
