import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import EditSample from './EditSample';
import SampleMedia from './SampleMedia';
import Bacteriology from './Bacteriology';
import CreateIsolate from './CreateIsolate';
import EditIsolate from './EditIsolate';
import ClinicalSigns from './ClinicalSigns';
import CreateClinicalSign from './CreateClinicalSign';
import EditClinicalSign from './EditClinicalSign';
import NotFound from '../NotFound';

export default function SamplesIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:id`}>
        <EditSample />
      </Route>
      <Route path={`${path}/:id/media`}>
        <SampleMedia />
      </Route>
      <Route path={`${path}/:id/bacteriology`}>
        <Bacteriology />
      </Route>
      <Route path={`${path}/:id/create-isolate`}>
        <CreateIsolate />
      </Route>
      <Route path={`${path}/isolates/:id`}>
        <EditIsolate />
      </Route>
      <Route path={`${path}/:id/clinical-signs`}>
        <ClinicalSigns />
      </Route>
      <Route path={`${path}/:id/create-clinical-sign`}>
        <CreateClinicalSign />
      </Route>
      <Route path={`${path}/clinical-signs/:id`}>
        <EditClinicalSign />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
