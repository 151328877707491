import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_CLIENTS,
  LOAD_CLIENTS_SUCCESS,
  LOAD_CLIENTS_ERROR,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  UPDATE_CLIENT_ERROR,
  GET_EDIT_CLIENT,
  GET_EDIT_CLIENT_SUCCESS,
  GET_EDIT_CLIENT_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export function* loadClients (action) {
  const { slug, page, query } = action;

  const params = {
    org__slug: slug,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
  };

  try {
    const response = yield call(api.loadClients, params);

    yield put({ type: LOAD_CLIENTS_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_CLIENTS_ERROR, error: error.response.data });
  }
}

export function* createClient ({ formData }) {
  try {
    const client = yield minDelayCall(api.createClient, formData);

    yield put({ type: CREATE_CLIENT_SUCCESS, client });
  } catch (error) {
    yield put({ type: CREATE_CLIENT_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateClient ({ formData }) {
  try {
    const client = yield minDelayCall(api.updateClient, formData);

    yield put({ type: UPDATE_CLIENT_SUCCESS, client });
  } catch (error) {
    yield put({ type: UPDATE_CLIENT_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getEditClient ({ id }) {
  try {
    const client = yield minDelayCall(api.getEditClient, id);

    yield put({ type: GET_EDIT_CLIENT_SUCCESS, client });
  } catch (error) {
    yield put({ type: GET_EDIT_CLIENT_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteClient (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteClient, id);

    yield put({ type: DELETE_CLIENT_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_CLIENT_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_CLIENTS, loadClients),
    takeLatest(CREATE_CLIENT, createClient),
    takeLatest(GET_EDIT_CLIENT, getEditClient),
    takeLatest(UPDATE_CLIENT, updateClient),
    takeLatest(DELETE_CLIENT, deleteClient),
  ]);
}
