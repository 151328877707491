import React, { useCallback, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Label,
  DropdownItem,
} from 'reactstrap';

import { SearchDropdown } from '../inputs/SearchDropdown';
import { getUser } from '../../redux/account/selectors';
import { LOGOUT } from '../../redux/account/actions';
import { setMenuOpen } from '../../redux/nav/actions';
import { getMenuOpen } from '../../redux/nav/selectors';
import { RESET_GET_ORGS, getOrgs } from '../../redux/orgs/actions';
import { getCurrOrg, getSwitchOrgPending } from '../../redux/orgs/selectors';
import { action } from '../../redux/helpers';
import { onUserInteract } from '../../utils/helpers';
import { useMobile, useOrgSlug } from '../../utils/hooks';

import styles from './LeftNav.module.scss';
import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';

export function LeftNav () {
  const menuRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const currOrg = useSelector(getCurrOrg);
  const orgs = useSelector((s) => s.orgs.organizations);
  const currPage = useSelector((s) => s.orgs.currOrgsPage);
  const hasMore = useSelector((s) => s.orgs.hasMoreOrgs);
  const loading = useSelector((s) => s.orgs.getOrgsPending);
  const menuOpen = useSelector(getMenuOpen);
  const switchOrgPending = useSelector(getSwitchOrgPending);
  const [menuAnimatable, setMenuAnimatable] = useState(false);
  const acceptInviteMatch = useRouteMatch('/app/accept/:key');
  const mobile = useMobile();
  const location = useLocation();
  const orgSlug = useOrgSlug();

  const toggleMenu = useCallback(
    onUserInteract(() => {
      // Ignore clicks for desktop
      if (mobile) {
        setMenuAnimatable(true);
        dispatch(setMenuOpen(!menuOpen));
      }
    }, [menuOpen]),
  );

  const hideMenu = useCallback(
    onUserInteract((e) => {
      e.stopPropagation();

      if (menuOpen) {
        setMenuAnimatable(true);
        dispatch(setMenuOpen(false));
      }
    }, [menuOpen]),
  );

  const onTransitionEnd = useCallback((e) => {
    if (e.target == menuRef.current) {
      setMenuAnimatable(false);
    }
  }, [menuRef]);

  const resetOrgs = useCallback(() => {
    dispatch(action(RESET_GET_ORGS));
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(getOrgs(currPage + 1, query));
  }, [currPage]);

  const logout = useCallback(() => dispatch(action(LOGOUT)), []);

  let orgSection = null;

  if (user.is_superuser || user.default_org_slug || !!acceptInviteMatch) {
    orgSection = (
      <div>
        <Label className={text.navHeading}>
          orgs
        </Label>
        <SearchDropdown
          placeholder="Select Organization"
          displayText={_.get(currOrg, 'name')}
          firstLoad={resetOrgs}
          loadMore={loadMore}
          hasMore={hasMore}
          loading={loading}
          disabled={switchOrgPending}
        >
          { !loading && _.get(orgs, 'length', 0) == 0 && (
            <div className="text-center">
              No organizations
            </div>
          )}
          { _.map(
            orgs,
            (org) => {
              let url = `/app/${org.slug}`;
              if (
                _.endsWith(location.pathname, '/clients') ||
                _.endsWith(location.pathname, '/water-bodies')
              ) {
                url = location.pathname.replace(`/${orgSlug}`, `/${org.slug}`);
              } else if (location.pathname.indexOf('/search') >= 0) {
                url = `/app/${org.slug}/search`;
              }

              return (
                <DropdownItem
                  key={`org-${org.slug}`}
                  tag={Link}
                  to={url}
                >
                  <div className={text.ellipsize}>
                    { org.name }
                  </div>
                </DropdownItem>
              );
            },
          ) }
        </SearchDropdown>
      </div>
    );
  }

  return (
    <div className={styles.leftNav}>
      <div
        id="menu-toggle"
        role="button"
        tabIndex={0}
        className={styles.hamburger}
        aria-label={!menuOpen
          ? 'Expand menu'
          : 'Collapse menu'
        }
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
      >
        <div className={styles.hamburgerBox}>
          <div className={styles.hamburgerInner}></div>
        </div>
      </div>
      { mobile &&
        <a
          tabIndex={0}
          className={cx('btn btn-primary', layout.skipMain)}
          href="#maincontent"
          onKeyDown={hideMenu}
        >
          Skip to main content
        </a>
      }
      <div
        ref={menuRef}
        role="button"
        tabIndex={-1}
        className={cx(
          styles.menu,
          {
            [styles.menuOpen]: menuOpen,
            [styles.menuAnimatable]: menuAnimatable,
          },
        )}
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        onTransitionEnd={onTransitionEnd}
      >
        <div
          className={styles.menuInner}
          role="button"
          tabIndex={-1}
          onClick={hideMenu}
          onKeyDown={hideMenu}
        >
          <div className={styles.header}>
            <h4 className="text-center">Menu</h4>
            { orgSection }
          </div>
          <Label className={cx('ml-3 mb-3', text.navHeading)}>
            more
          </Label>
          { orgSlug && [
            <NavLink
              key="search-link"
              className={cx('btn btn-link', styles.navItem)}
              to={`/app/${orgSlug}/search`}
              exact
            >
              <FontAwesomeIcon icon="search"/>
              Search
            </NavLink>,
            <NavLink
              key="clients-link"
              className={cx('btn btn-link', styles.navItem)}
              to={`/app/${orgSlug}/clients`}
            >
              Clients
            </NavLink>,
            <NavLink
              key="waterbodies-link"
              className={cx('btn btn-link', styles.navItem)}
              to={`/app/${orgSlug}/water-bodies`}
            >
              Water Bodies
            </NavLink>,
            <NavLink
              key="users-link"
              className={cx('btn btn-link', styles.navItem)}
              to={`/app/${orgSlug}/users`}
            >
              Users
            </NavLink>,
          ] }
          <NavLink
            className={cx('btn btn-link', styles.navItem)}
            to="/app/profile"
            exact
          >
            Profile
          </NavLink>
          <Button
            className={styles.navItem}
            color="link"
            onClick={logout}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </div>
  );
}
