import React from 'react';
import PropTypes from 'prop-types';
import { Input, CustomInput } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { Measurements, UNITS } from './Measurements';

import styles from './MeasurementInput.module.scss';

// eslint-disable-next-line no-unused-vars
const BaseMeasurementInput = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    invalid,
    type,
    unitContext,
    disabled,
  } = props;

  const measurement = value || {
    value: '',
    unit: _.get(UNITS, `${type}.${unitContext}[0].value`),
  };

  return (
    <div className={cx(styles.measurementInput, {'is-invalid': invalid})}>
      <Input
        type="number"
        step="0.1"
        value={measurement.value}
        min={type != 'temperature' ? 0 : undefined}
        onChange={(e) => onChange({ ...measurement, value: e.target.value })}
        invalid={invalid}
        autoComplete="off"
        disabled={disabled}
      />
      <CustomInput
        id={`measurement-${type}`}
        type="select"
        className="form-control"
        value={measurement.unit}
        onChange={(e) => onChange({ ...measurement, unit: e.target.value })}
        disabled={disabled}
      >
        {
          _.map(
            _.get(UNITS, [type, unitContext], []),
            (u) => (
              <option
                key={`${type}-${u.value}`}
                value={u.value}
              >
                {u.text}
              </option>
            ),
          )
        }
      </CustomInput>
    </div>
  );
});
BaseMeasurementInput.displayName = 'MeasurementInput';
BaseMeasurementInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  type: PropTypes.oneOf(_.values(Measurements)).isRequired,
  unitContext: PropTypes.string,
};
BaseMeasurementInput.defaultProps = {
  unitContext: 'default',
};

export const MeasurementInput = React.memo(BaseMeasurementInput);
