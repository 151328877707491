import * as Actions from './actions';

const resetSamplesList = {
  samples: [],
  hasMoreSamples: true,
  currSamplesPage: 0,
};

const resetSampleMedia = {
  sampleMedia: [],
  hasMoreSampleMedia: true,
  currSampleMediaPage: 0,
};

const resetIsolatesList = {
  isolates: [],
  hasMoreIsolates: true,
  currIsolatesPage: 0,
};

const resetClinicalSignsList = {
  clinicalSigns: [],
  hasMoreClinicalSigns: true,
  currClinicalSignsPage: 0,
};

export const initialState = {
  ...resetSamplesList,
  hasMoreSamples: false,
  loadSamplesPending: false,
  loadSamplesError: null,
  ...resetSampleMedia,
  hasMoreSampleMedia: false,
  loadSampleMediaPending: false,
  loadSampleMediaError: null,
  uploadCtr: 1,
  sampleMediaUploads: {},
  ...resetIsolatesList,
  hasMoreIsolates: false,
  currSample: null,
  getCurrSamplePending: false,
  getCurrSampleError: null,
  changeSamplePending: false,
  changeSampleError: null,
  deleteSamplePending: false,
  deleteSampleError: null,
  deleteSampleMediaPending: false,
  deleteSampleMediaError: null,
  changeIsolatePending: false,
  changeIsolateError: null,
  deleteIsolatePending: false,
  deleteIsolateError: null,
  currIsolate: null,
  getCurrIsolatePending: false,
  getCurrIsolateError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_SAMPLES:
      return {
        ...state,
        ...resetSamplesList,
      };
    case Actions.LOAD_SAMPLES:
      return {
        ...state,
        loadSamplesPending: true,
      };
    case Actions.LOAD_SAMPLES_SUCCESS:
      return {
        ...state,
        loadSamplesPending: false,
        loadSamplesError: null,
        samples: [
          ...state.samples,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.samples, 'id'),
        ],
        hasMoreSamples: !!action.next,
        currSamplesPage: action.page,
      };
    case Actions.LOAD_SAMPLES_ERROR:
      return {
        ...state,
        loadSamplesPending: false,
        loadSamplesError: action.error,
        hasMoreSamples: false,
      };
    case Actions.CREATE_SAMPLE:
    case Actions.UPDATE_SAMPLE:
      return {
        ...state,
        changeSamplePending: true,
        changeSampleError: null,
      };
    case Actions.CREATE_SAMPLE_SUCCESS:
    case Actions.UPDATE_SAMPLE_SUCCESS:
      return {
        ...state,
        currSample: action.sample,
        changeSamplePending: false,
        changeSampleError: null,
        ...resetSamplesList,
      };
    case Actions.CREATE_SAMPLE_ERROR:
    case Actions.UPDATE_SAMPLE_ERROR:
      return {
        ...state,
        changeSamplePending: false,
        changeSampleError: action.error,
      };
    case Actions.DELETE_SAMPLE:
      return {
        ...state,
        deleteSamplePending: true,
      };
    case Actions.DELETE_SAMPLE_SUCCESS:
      return {
        ...state,
        deleteSamplePending: false,
        deleteSampleError: null,
        ...resetSamplesList,
        samples: _.filter(
          state.samples,
          (c) => c.id != action.id,
        ),
      };
    case Actions.DELETE_SAMPLE_ERROR:
      return {
        ...state,
        deleteSamplePending: false,
        deleteSampleError: action.error,
      };
    case Actions.GET_CURR_SAMPLE:
      return {
        ...state,
        getCurrSamplePending: true,
      };
    case Actions.GET_CURR_SAMPLE_SUCCESS:
      return {
        ...state,
        getCurrSamplePending: false,
        getCurrSampleError: null,
        currSample: action.sample,
      };
    case Actions.GET_CURR_SAMPLE_ERROR:
      return {
        ...state,
        getCurrSamplePending: false,
        getCurrSampleError: action.error,
      };
    case Actions.CLEAR_CURR_SAMPLE:
      return {
        ...state,
        currSample: null,
      };
    case Actions.RESET_LOAD_SAMPLE_MEDIA:
      return {
        ...state,
        ...resetSampleMedia,
      };
    case Actions.LOAD_SAMPLE_MEDIA:
      return {
        ...state,
        loadSampleMediaPending: true,
      };
    case Actions.LOAD_SAMPLE_MEDIA_SUCCESS:
      return {
        ...state,
        loadSampleMediaPending: false,
        loadSampleMediaError: null,
        sampleMedia: [
          ...state.sampleMedia,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.sampleMedia, 'id'),
        ],
        hasMoreSampleMedia: !!action.next,
        currSampleMediaPage: action.page,
      };
    case Actions.LOAD_SAMPLE_MEDIA_ERROR:
      return {
        ...state,
        loadSampleMediaPending: false,
        loadSampleMediaError: action.error,
        hasMoreSampleMedia: false,
      };
    case Actions.CREATE_SAMPLE_MEDIA:
      action.uploadId = state.uploadCtr;

      return {
        ...state,
        uploadCtr: state.uploadCtr + 1,
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          // Namespace uploads by sample
          [action.sampleId]: {
            ..._.get(state, ['sampleMediaUploads', action.sampleId], {}),
            // Key uploads by ID
            [action.uploadId]: {
              id: action.uploadId,
              progress: 0,
              name: action.file.name,
              mime_type: action.file.type,
            },
          },
        },
      };
    case Actions.CREATE_SAMPLE_MEDIA_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          num_media: state.currSample.num_media + 1,
        };
      }

      return {
        ...state,
        currSample,
        // Move upload to media list
        sampleMedia: [
          action.media,
          ...state.sampleMedia,
        ],
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          [action.sampleId]: _.omit(
            state.sampleMediaUploads[action.sampleId],
            action.uploadId,
          ),
        },
      };
    }
    case Actions.CREATE_SAMPLE_MEDIA_PROGRESS:
      return {
        ...state,
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          [action.sampleId]: {
            ..._.get(state, ['sampleMediaUploads', action.sampleId], {}),
            [action.uploadId]: {
              ..._.get(state, ['sampleMediaUploads', action.sampleId, action.uploadId]),
              progress: action.progress,
            },
          },
        },
      };
    case Actions.CREATE_SAMPLE_MEDIA_ERROR:
      return {
        ...state,
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          [action.sampleId]: {
            ...state.sampleMediaUploads[action.sampleId],
            [action.uploadId]: {
              ..._.get(state, ['sampleMediaUploads', action.sampleId, action.uploadId]),
              error: action.error,
            },
          },
        },
      };
    case Actions.CANCEL_SAMPLE_UPLOAD:
      return {
        ...state,
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          [action.sampleId]: _.omit(
            state.sampleMediaUploads[action.sampleId],
            action.uploadId,
          ),
        },
      };
    case Actions.DELETE_SAMPLE_MEDIA:
      return {
        ...state,
        deleteSampleMediaPending: true,
      };
    case Actions.DELETE_SAMPLE_MEDIA_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          num_media: state.currSample.num_media - 1,
        };
      }

      return {
        ...state,
        deleteSampleMediaPending: false,
        deleteSampleMediaError: null,
        currSample,
        sampleMedia: _.filter(
          state.sampleMedia,
          (m) => m.id != action.id,
        ),
      };
    }
    case Actions.DELETE_SAMPLE_MEDIA_ERROR:
      return {
        ...state,
        deleteSampleMediaPending: false,
        deleteSampleMediaError: action.error,
      };
    case Actions.DELETE_SAMPLE_UPLOAD:
      return {
        ...state,
        sampleMediaUploads: {
          ...state.sampleMediaUploads,
          [action.sampleId]: _.omit(
            state.sampleMediaUploads[action.sampleId],
            action.uploadId,
          ),
        },
      };
    case Actions.RESET_LOAD_ISOLATES:
      return {
        ...state,
        ...resetIsolatesList,
      };
    case Actions.LOAD_ISOLATES:
      return {
        ...state,
        loadIsolatesPending: true,
      };
    case Actions.LOAD_ISOLATES_SUCCESS:
      return {
        ...state,
        loadIsolatesPending: false,
        loadIsolatesError: null,
        isolates: [
          ...state.isolates,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.isolates, 'id'),
        ],
        hasMoreIsolates: !!action.next,
        currIsolatesPage: action.page,
      };
    case Actions.LOAD_ISOLATES_ERROR:
      return {
        ...state,
        loadIsolatesPending: false,
        loadIsolatesError: action.error,
        hasMoreIsolates: false,
      };
    case Actions.CREATE_ISOLATE:
    case Actions.UPDATE_ISOLATE:
      return {
        ...state,
        changeIsolateError: null,
        changeIsolatePending: true,
      };
    case Actions.CREATE_ISOLATE_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          num_isolates: state.currSample.num_isolates + 1,
        };
      }

      return {
        ...state,
        changeIsolatePending: false,
        currIsolate: action.isolate,
        currSample,
        ...resetIsolatesList,
      };
    }
    case Actions.UPDATE_ISOLATE_SUCCESS:
      return {
        ...state,
        changeIsolatePending: false,
        currIsolate: action.isolate,
        ...resetIsolatesList,
      };
    case Actions.CREATE_ISOLATE_ERROR:
    case Actions.UPDATE_ISOLATE_ERROR:
      return {
        ...state,
        changeIsolatePending: false,
        changeIsolateError: action.error,
      };
    case Actions.DELETE_ISOLATE:
      return {
        ...state,
        deleteIsolatePending: true,
      };
    case Actions.DELETE_ISOLATE_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          num_isolates: state.currSample.num_isolates - 1,
        };
      }

      return {
        ...state,
        deleteIsolatePending: false,
        deleteIsolateError: null,
        ...resetIsolatesList,
        isolates: _.filter(
          state.isolates,
          (c) => c.id != action.id,
        ),
        currSample,
      };
    }
    case Actions.DELETE_ISOLATE_ERROR:
      return {
        ...state,
        deleteIsolatePending: false,
        deleteIsolateError: action.error,
      };
    case Actions.GET_CURR_ISOLATE:
      return {
        ...state,
        getCurrIsolatePending: true,
      };
    case Actions.GET_CURR_ISOLATE_SUCCESS:
      return {
        ...state,
        getCurrIsolatePending: false,
        getCurrIsolateError: null,
        currIsolate: action.isolate,
      };
    case Actions.GET_CURR_ISOLATE_ERROR:
      return {
        ...state,
        getCurrIsolatePending: false,
        getCurrIsolateError: action.error,
      };
    case Actions.RESET_LOAD_CLINICAL_SIGNS:
      return {
        ...state,
        ...resetClinicalSignsList,
      };
    case Actions.LOAD_CLINICAL_SIGNS:
      return {
        ...state,
        loadClinicalSignsPending: true,
      };
    case Actions.LOAD_CLINICAL_SIGNS_SUCCESS:
      return {
        ...state,
        loadClinicalSignsPending: false,
        loadClinicalSignsError: null,
        clinicalSigns: [
          ...state.clinicalSigns,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.clinicalSigns, 'id'),
        ],
        hasMoreClinicalSigns: !!action.next,
        currClinicalSignsPage: action.page,
      };
    case Actions.LOAD_CLINICAL_SIGNS_ERROR:
      return {
        ...state,
        loadClinicalSignsPending: false,
        loadClinicalSignsError: action.error,
        hasMoreClinicalSigns: false,
      };
    case Actions.CREATE_CLINICAL_SIGN:
    case Actions.UPDATE_CLINICAL_SIGN:
      return {
        ...state,
        changeClinicalSignPending: true,
        changeClinicalSignError: null,
      };
    case Actions.CREATE_CLINICAL_SIGN_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          clinical_signs: [
            _.pick(action.clinicalSign, ['id', 'display_name']),
            ...state.currSample.clinical_signs,
          ],
        };
      }

      return {
        ...state,
        changeClinicalSignPending: false,
        changeClinicalSignError: null,
        currClinicalSign: action.clinicalSign,
        currSample,
        ...resetClinicalSignsList,
      };
    }
    case Actions.UPDATE_CLINICAL_SIGN_SUCCESS:
      return {
        ...state,
        changeClinicalSignPending: false,
        changeClinicalSignError: null,
        currClinicalSign: action.clinicalSign,
        ...resetClinicalSignsList,
      };
    case Actions.CREATE_CLINICAL_SIGN_ERROR:
    case Actions.UPDATE_CLINICAL_SIGN_ERROR:
      return {
        ...state,
        changeClinicalSignPending: false,
        changeClinicalSignError: action.error,
      };
    case Actions.DELETE_CLINICAL_SIGN:
      return {
        ...state,
        deleteClinicalSignPending: true,
      };
    case Actions.DELETE_CLINICAL_SIGN_SUCCESS: {
      let currSample;
      if (state.currSample) {
        currSample = {
          ...state.currSample,
          clinical_signs: _.filter(
            state.currSample.clinical_signs,
            (c) => c.id != action.id,
          ),
        };
      }

      return {
        ...state,
        deleteClinicalSignPending: false,
        deleteClinicalSignError: null,
        ...resetClinicalSignsList,
        clinicalSigns: _.filter(
          state.clinicalSigns,
          (c) => c.id != action.id,
        ),
        currSample,
      };
    }
    case Actions.DELETE_CLINICAL_SIGN_ERROR:
      return {
        ...state,
        deleteClinicalSignPending: false,
        deleteClinicalSignError: action.error,
      };
    case Actions.GET_CURR_CLINICAL_SIGN:
      return {
        ...state,
        getCurrClinicalSignPending: true,
      };
    case Actions.GET_CURR_CLINICAL_SIGN_SUCCESS:
      return {
        ...state,
        getCurrClinicalSignPending: false,
        getCurrClinicalSignError: null,
        currClinicalSign: action.clinicalSign,
      };
    case Actions.GET_CURR_CLINICAL_SIGN_ERROR:
      return {
        ...state,
        getCurrClinicalSignPending: false,
        getCurrClinicalSignError: action.error,
      };
    default:
      return state;
  }
};
