import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSpecies } from '../../redux/search/selectors';
import { loadSpecies, RESET_LOAD_SPECIES } from '../../redux/search/actions';
import { action } from '../../redux/helpers';

export const useSpecies = () => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const species = useSelector(getSpecies);
  const currPage = useSelector((s) => s.search.currSpeciesPage);
  const hasMore = useSelector((s) => s.search.hasMoreSpecies);
  const loading = useSelector((s) => s.search.loadSpeciesPending);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(species, 'length', 0) == 0
    );
  }, [loading, hasMore, species]);

  const reset = useCallback(() => {
    dispatch(action(RESET_LOAD_SPECIES));
    setResetCalled(true);
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadSpecies(currPage + 1, query));
  }, [currPage]);

  return {
    // Ignore redux list until first load
    species: firstLoad.current ? species : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    reset,
    loadMore,
  };
};
