import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import header from './PageHeader.module.scss';

export function BackButton () {
  const history = useHistory();

  return (
    <Button
      color="header-action"
      className={header.secondaryAction}
      onClick={history.goBack}
    >
      <FontAwesomeIcon icon="angle-left" />
    </Button>
  );
}
