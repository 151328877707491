import React from 'react';
import PropTypes from 'prop-types';
import {
  FormFeedback,
  FormGroup,
  Input,
} from 'reactstrap';
import * as _ from 'lodash';

const BaseGeneralErrors = ({ error, includeKeys, textOnly }) => {
  let formErrors = [];
  if (_.has(error, 'non_field_errors')) {
    formErrors.push(
      textOnly
        ? error.non_field_errors
        : (
          <FormGroup key="non-field-errors">
            <Input
              type="hidden"
              invalid={ true }/>
            <FormFeedback role="alert">
              { error.non_field_errors }
            </FormFeedback>
          </FormGroup>
        ),
    );
  }

  if (_.has(error, 'detail')) {
    formErrors.push(
      textOnly
        ? error.detail
        : (
          <FormGroup key="detail">
            <Input
              type="hidden"
              invalid={ true }/>
            <FormFeedback role="alert">
              { error.detail }
            </FormFeedback>
          </FormGroup>
        ),
    );
  }

  if (_.get(error, 'code', 0) == 413) {
    const uploadSize = 'File exceeds maximum size of 2G';

    formErrors.push(
      textOnly
        ? uploadSize
        : (
          <FormGroup key='upload-too-large'>
            <Input
              type="hidden"
              invalid={ true }/>
            <FormFeedback role="alert">
              { uploadSize }
            </FormFeedback>
          </FormGroup>
        ),
    );
  }

  if (_.get(error, 'code', 0) >= 500) {
    const serverError = 'Server could not complete request';
    formErrors.push(
      textOnly
        ? serverError
        : (
          <FormGroup key='server-error'>
            <Input
              type="hidden"
              invalid={ true }/>
            <FormFeedback role="alert">
              { serverError }
            </FormFeedback>
          </FormGroup>
        ),
    );
  }

  _.forEach(
    includeKeys,
    (key) => {
      if (_.has(error, key)) {
        formErrors.push(
          textOnly
            ? error[key]
            : (
              <FormGroup key={key}>
                <Input
                  type="hidden"
                  invalid={ true }/>
                <FormFeedback role="alert">
                  { error[key] }
                </FormFeedback>
              </FormGroup>
            ),
        );
      }
    },
  );

  return formErrors;
};
BaseGeneralErrors.propTypes = {
  error: PropTypes.object,
  includeKeys: PropTypes.array,
  textOnly: PropTypes.bool,
};
BaseGeneralErrors.defaultProps = {
  error: {},
  includeKeys: [],
  textOnly: false,
};

export const GeneralErrors = React.memo(BaseGeneralErrors);
