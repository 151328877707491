import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  BackButton,
  PageHeader,
  Tile,
  TileBoard,
} from '../../components';
import { useProdCycles } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { formatDate } from '../../utils/helpers';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import { subtext } from '../../styles/text.module.scss';

export default function SelectProdCycle () {
  const { orgSlug, id: bodyId } = useParams();
  const currOrg = useSelector(getCurrOrg);
  const {
    prodCycles,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useProdCycles(bodyId);

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Select Production Cycle" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader
        title="Select Production Cycle"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          className="text-center"
        >
          {
            noResults
              ? (
                <p key="zero" className="text-center">
                  You don&apos;t have any production cyles for this waterbody yet.
                </p>
              )
              :
              <TileBoard>
                {_.map(prodCycles, (cycle) =>
                  <Tile
                    key={`tile-${cycle.id}`}
                    linkTo={`/app/${orgSlug}/prod-cycles/${cycle.id}/new-report`}
                  >
                    { cycle.name }
                    <div className={subtext}>
                      {formatDate(cycle.start)}
                    </div>
                  </Tile>,
                )}
                { loading && (
                  <Tile
                    key="spinner"
                    transparent
                  >
                    <Spinner className={tables.spinner} />
                  </Tile>
                )}
              </TileBoard>

          }
        </InfiniteScroll>
      </div>
    </div>
  );
}
