import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
  DropdownItem,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackButton,
  CancelButton,
  GeneralErrors,
  PageHeader,
  KebabDropdown,
} from '../../components';
import { updateReport } from '../../redux/reports/actions';
import { useReadOnly, useConfirmAction } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import { PotentialTreatmentInput } from '../../components/inputs/PotentialTreatmentInput';

export default function PotentialTreatmentForm ({ currReport }) {
  const history = useHistory();
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm({ defaultValues: currReport });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;
  const confirmAction = useConfirmAction();

  const submit = useCallback((data) => {
    dispatch(updateReport({
      ...data,
      potential_treatment: _.get(data, 'potential_treatment.id', ''),
      id: currReport.id,
    }));
  }, [currReport]);

  const removePotentialTreatment = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'potential treatment',
      subject: 'potential treatment',
      onConfirm: () => dispatch(updateReport({
        potential_treatment: '',
        potential_treatment_desc: '',
        id: currReport.id,
      })),
      pendingPath: 'reports.changeReportPending',
      errorPath: 'reports.changeReportError',
      onSuccess: history.goBack,

    });
  }, [currReport, confirmAction]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      history.goBack();
    }
  }, [submitSuccess]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Potential Treatment"
        coverMenu
      >
        <BackButton/>
        {
          (
            !readOnly &&
            (
              _.get(currReport, 'potential_treatment') ||
              _.get(currReport, 'potential_treatment_desc')
            )
          ) && (
            <KebabDropdown right>
              <DropdownItem onClick={removePotentialTreatment}>
                <FontAwesomeIcon icon="trash" />
                Delete Potential Treatment
              </DropdownItem>
            </KebabDropdown>
          )
        }
      </PageHeader>
      <div className={layout.pageContent}>
        <div className={layout.responsiveContainer}>
          {!currReport ?
            <div className="text-center">
              <Spinner />
            </div>
            :
            <Form onSubmit={handleSubmit(submit)}>
              <FormGroup>
                <Controller
                  as={PotentialTreatmentInput}
                  name="potential_treatment"
                  control={control}
                  invalid={ isSubmitted && _.has(error, 'potential_treatment') }
                  disabled={readOnly}
                />
                <FormFeedback role="alert">
                  { _.get(error, 'potential_treatment') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  innerRef={register}
                  name="potential_treatment_desc"
                  invalid={ isSubmitted && _.has(error, 'potential_treatment_desc') }
                  autoComplete="off"
                  disabled={readOnly}
                />
                <FormFeedback role="alert">
                  { _.get(error, 'potential_treatment_desc')}
                </FormFeedback>
              </FormGroup>
              { isSubmitted && <GeneralErrors error={error} /> }
              {!readOnly && (
                <Button
                  color="primary"
                  disabled={pending}
                  block
                >
                  Submit{' '}
                  { pending && <Spinner size="sm" /> }
                </Button>
              )}
              <CancelButton />
            </Form>
          }
        </div>
      </div>
    </div>
  );
}
