import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import ClientForm from './ClientForm';

import layout from '../../styles/layout.module.scss';

export default function CreateClient () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currClient = useSelector((s) => s.clients.currClient);

  const goBack = useCallback(() => {
    history.replace(`/app/${orgSlug}/clients/${_.get(currClient, 'id')}/edit`);
    history.goBack();
  }, [orgSlug, currClient]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Client"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <ClientForm onSubmit={goBack} />
    </div>
  );
}
