import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

export function CancelButton () {
  const history = useHistory();

  return (
    <Button
      onClick={history.goBack}
      block
    >
      Cancel
    </Button>
  );
}
