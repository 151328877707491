import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropdownItem,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackButton,
  PageHeader,
  KebabDropdown,
} from '../../components';
import { getCurrClinicalSign, deleteClinicalSign } from '../../redux/samples/actions';
import ClinicalSignForm from './ClinicalSignForm';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { useConfirmAction, useRole } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';

export default function EditClinicalSign () {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const confirmAction = useConfirmAction();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currClinicalSign = useSelector((s) => s.samples.currClinicalSign);
  const pending = useSelector((s) => s.samples.getCurrClinicalSignPending);
  const error = useSelector((s) => s.samples.getCurrClinicalSignError);

  // Load currClinicalSign if not already
  useEffect(() => {
    if (currOrg && _.get(currClinicalSign, 'id') != id) {
      dispatch(getCurrClinicalSign(id));
      setStartedLoading(true);
    }
  }, [currOrg, currClinicalSign]);

  // Failed to load clinical sign. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/water-bodies`);
    }
  }, [startedLoading, error, history]);

  const removeClinicalSign = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'Clinical Sign',
      subject: 'this clinical sign',
      onConfirm: () => dispatch(deleteClinicalSign(Number(id))),
      pendingPath: 'samples.deleteClinicalSignPending',
      errorPath: 'samples.deleteClinicalSignError',
      onSuccess: history.goBack,
    });
  }, [currOrg, currClinicalSign, confirmAction]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Clinical Sign"
        coverMenu
      >
        <BackButton />
        { role != 'read' && (
          <KebabDropdown right>
            <DropdownItem onClick={removeClinicalSign}>
              <FontAwesomeIcon icon="trash" />
              Delete Clinical Sign
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      { (!currClinicalSign || pending)
        ?
        <div className={layout.pageContent}>
          <div className="text-center">
            <Spinner />
          </div>
        </div>
        :
        <ClinicalSignForm
          onSubmit={history.goBack}
          clinicalSign={currClinicalSign}
        />
      }
    </div>
  );
}

