export const Measurements = {
  AREA: 'area',
  VOLUME: 'volume',
  DISTANCE: 'distance',
  TEMPERATURE: 'temperature',
};

export const UNITS = {
  [Measurements.AREA]: {
    default: [
      { value: 'sq_ft', text: 'Sq. Feet' },
      { value: 'sq_m', text: 'Sq. Meters' },
      { value: 'acre', text: 'Acres' },
      { value: 'hectare', text: 'Hectares' },
    ],
  },
  [Measurements.VOLUME]: {
    default: [
      { value: 'us_g', text: 'Gallons' },
      { value: 'l', text: 'Liters' },
      { value: 'cubic_foot', text: 'Cubic Feet' },
      { value: 'cubic_meter', text: 'Cubic Meter' },
    ],
  },
  [Measurements.DISTANCE]: {
    default: [
      { value: 'ft', text: 'Feet' },
      { value: 'm', text: 'Meters' },
    ],
    fishLength: [
      { value: 'inch', text: 'Inches' },
      { value: 'cm', text: 'Centimeters' },
    ],
  },
  [Measurements.TEMPERATURE]: {
    default: [
      { value: 'f', text: 'Fahrenheit' },
      { value: 'c', text: 'Celsius' },
    ],
  },
};
