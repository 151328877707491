import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, DropdownItem } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { getBehaviors } from '../../redux/search/selectors';
import {
  loadBehaviors,
  RESET_LOAD_BEHAVIORS,
} from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';

import multiselect from './Multiselect.module.scss';
import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const BehaviorSelect = React.forwardRef((props, ref) => {
  const { value, onChange, invalid, disabled } = props;
  const dispatch = useDispatch();
  const behaviors = useSelector(getBehaviors);
  const currPage = useSelector((s) => s.search.currBehaviorsPage);
  const hasMore = useSelector((s) => s.search.hasMoreBehaviors);
  const loading = useSelector((s) => s.search.loadBehaviorsPending);
  const valueLookup = _.keyBy(value, (s) => s.id);
  const displayText = _.join(
    _.map(value, (s) => s.name),
    ', ',
  );

  const onRemoveBehaviors = useCallback((entry) => {
    onChange(_.filter(value, (s) => s.id != entry.id));
  }, [onChange, value]);

  const onOptionClicked = useCallback((s) => {
    if (!_.hasIn(valueLookup, s.id)) {
      onChange([ ...(value || []), s ]);
    } else {
      onRemoveBehaviors(s);
    }
  }, [onChange, value]);

  const resetBehaviors = useCallback(() => {
    dispatch(action(RESET_LOAD_BEHAVIORS));
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadBehaviors(currPage + 1, query));
  }, [currPage]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder="Select Behaviors"
      displayText={displayText}
      firstLoad={resetBehaviors}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      disabled={disabled}
    >
      { _.get(value, 'length', 0) == 0 && (
        <div className="text-center">
          No behaviors selected
        </div>
      )}
      { _.get(value, 'length', 0) > 0 &&
        _.map(
          value,
          (s) => (
            <DropdownItem
              key={`behaviors-selected-${s.id}`}
              onClick={() => onRemoveBehaviors(s)}
              className={multiselect.item}
              toggle={false}
            >
              <CustomInput
                type="checkbox"
                id={`behaviors-check-${s.id}`}
                className={multiselect.checkbox}
                checked
                readOnly
              />
              <div className={cx(multiselect.content, text.ellipsize)}>
                { s.name }
              </div>
            </DropdownItem>
          ),
        )
      }
      <DropdownItem divider/>
      { !loading && _.get(behaviors, 'length', 0) == 0 && (
        <div className="text-center">
          No behaviors
        </div>
      )}
      { _.map(
        behaviors,
        (s) => (
          <DropdownItem
            key={`behaviors-${s.id}`}
            onClick={() => onOptionClicked(s)}
            className={multiselect.item}
            toggle={false}
          >
            <CustomInput
              type="checkbox"
              id={`behaviors-check-${s.id}`}
              className={multiselect.checkbox}
              checked={_.hasIn(valueLookup, s.id)}
              readOnly
            />
            <div className={cx(multiselect.content, text.ellipsize)}>
              { s.name }
            </div>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
BehaviorSelect.displayName = 'BehaviorSelect';
