import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';

import styles from './MediaProgress.module.scss';

const BaseMediaProgress = ({ progress }) => {
  return (
    <div className={styles.progress}>
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        strokeWidth={3}
        styles={buildStyles({
          textColor: styles.darkText,
          pathColor: styles.green,
          strokeLinecap: 'butt',
          textSize: '28px',
        })}
      />
    </div>
  );
};
BaseMediaProgress.propTypes = {
  progress: PropTypes.number,
};
BaseMediaProgress.defaultProps = {
  progress: 0,
};

export const MediaProgress = React.memo(BaseMediaProgress);
