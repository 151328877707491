import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import WaterBodies from './WaterBodies';
import CreateWaterBody from './CreateWaterBody';
import EditWaterBody from './EditWaterBody';
import WaterBodySearch from './WaterBodySearch';
import WaterBodyDetails from './WaterBodyDetails';
import CreateProdCycle from '../prodcycles/CreateProdCycle';
import SelectProdCycle from '../prodcycles/SelectProdCycle';
import NotFound from '../NotFound';

export default function WaterBodiesIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <WaterBodies />
      </Route>
      <Route path={`${path}/search`}>
        <WaterBodySearch />
      </Route>
      <Route path={`${path}/create`}>
        <CreateWaterBody />
      </Route>
      <Route exact path={`${path}/:id`}>
        <WaterBodyDetails />
      </Route>
      <Route exact path={`${path}/:id/edit`}>
        <EditWaterBody />
      </Route>
      <Route path={`${path}/:id/select-cycle`}>
        <SelectProdCycle />
      </Route>
      <Route path={`${path}/:id/create-cycle`}>
        <CreateProdCycle />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
