export const RESET_LOAD_SPECIES = 'RESET_LOAD_SPECIES';
export const RESET_LOAD_PATHOGENS = 'RESET_LOAD_PATHOGENS';
export const RESET_LOAD_TREATMENTS = 'RESET_LOAD_TREATMENTS';
export const RESET_LOAD_BEHAVIORS = 'RESET_LOAD_BEHAVIORS';
export const RESET_LOAD_MISC_DETAILS = 'RESET_LOAD_MISC_DETAILS';
export const LOAD_SPECIES = 'LOAD_SPECIES';
export const LOAD_PATHOGENS = 'LOAD_PATHOGENS';
export const LOAD_TREATMENTS = 'LOAD_TREATMENTS';
export const LOAD_BEHAVIORS = 'LOAD_BEHAVIORS';
export const LOAD_MISC_DETAILS = 'LOAD_MISC_DETAILS';
export const LOAD_SPECIES_SUCCESS = 'LOAD_SPECIES_SUCCESS';
export const LOAD_PATHOGENS_SUCCESS = 'LOAD_PATHOGENS_SUCCESS';
export const LOAD_TREATMENTS_SUCCESS = 'LOAD_TREATMENTS_SUCCESS';
export const LOAD_BEHAVIORS_SUCCESS = 'LOAD_BEHAVIORS_SUCCESS';
export const LOAD_MISC_DETAILS_SUCCESS = 'LOAD_MISC_DETAILS_SUCCESS';
export const LOAD_SPECIES_ERROR = 'LOAD_SPECIES_ERROR';
export const LOAD_PATHOGENS_ERROR = 'LOAD_PATHOGENS_ERROR';
export const LOAD_TREATMENTS_ERROR = 'LOAD_TREATMENTS_ERROR';
export const LOAD_BEHAVIORS_ERROR = 'LOAD_BEHAVIORS_ERROR';
export const LOAD_MISC_DETAILS_ERROR = 'LOAD_MISC_DETAILS_ERROR';

export const loadSpecies = (page, query) => {

  return {
    type: LOAD_SPECIES,
    page,
    query,
  };
};

export const loadBehaviors = (page, query) => {

  return {
    type: LOAD_BEHAVIORS,
    page,
    query,
  };
};

export const loadPathogens = (page, query, pathogenType) => {

  return {
    type: LOAD_PATHOGENS,
    page,
    query,
    pathogenType,
  };
};

export const loadTreatments = (page, query, treatmentType) => {

  return {
    type: LOAD_TREATMENTS,
    page,
    query,
    treatmentType,
  };
};

export const loadMiscDetails = (page, query) => {

  return {
    type: LOAD_MISC_DETAILS,
    page,
    query,
  };
};

