import * as Actions from './actions';
import {
  CREATE_SAMPLE_SUCCESS,
  UPDATE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_SUCCESS,
  CREATE_CLINICAL_SIGN_SUCCESS,
  UPDATE_CLINICAL_SIGN_SUCCESS,
  DELETE_CLINICAL_SIGN_SUCCESS,
  CREATE_ISOLATE_SUCCESS,
  UPDATE_ISOLATE_SUCCESS,
  DELETE_ISOLATE_SUCCESS,
  CREATE_SAMPLE_MEDIA,
  DELETE_SAMPLE_MEDIA_SUCCESS,
} from '../samples/actions';
import {
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_SUCCESS,
} from '../activities/actions';

const resetReportsList = {
  reports: [],
  hasMoreReports: true,
  currReportsPage: 0,
};

const resetReportMedia = {
  reportMedia: [],
  hasMoreReportMedia: true,
  currReportMediaPage: 0,
};

export const initialState = {
  ...resetReportsList,
  hasMoreReports: false,
  loadReportsPending: false,
  loadReportsError: null,
  ...resetReportMedia,
  hasMoreReportMedia: false,
  loadReportMediaPending: false,
  loadReportMediaError: null,
  uploadCtr: 1,
  reportMediaUploads: {},
  getCurrReportPending: false,
  getCurrReportError: null,
  changeReportPending: false,
  changeReportError: null,
  deleteReportPending: false,
  deleteReportError: null,
  deleteReportMediaPending: false,
  deleteReportMediaError: null,
  stepTitle: null,
  searchCtx: null,
  sortDesc: true,
  reportFilters: {
    startDate: null,
    endDate: null,
    client: null,
    species: [],
    parasites: [],
    bacteria: [],
    fungi: [],
    viruses: [],
    misc_details: [],
    potential_treatment: null,
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_REPORTS:
      return {
        ...state,
        ...resetReportsList,
      };
    case Actions.LOAD_REPORTS:
      return {
        ...state,
        loadReportsError: null,
        loadReportsPending: true,
      };
    case Actions.LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        loadReportsPending: false,
        reports: [
          ...state.reports,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.reports, 'id'),
        ],
        hasMoreReports: !!action.next,
        currReportsPage: action.page,
        searchCtx: action.searchCtx,
      };
    case Actions.LOAD_REPORTS_ERROR:
      return {
        ...state,
        loadReportsPending: false,
        loadReportsError: action.error,
        hasMoreReports: false,
      };
    case Actions.CREATE_REPORT:
    case Actions.UPDATE_REPORT:
      return {
        ...state,
        changeReportError: null,
        changeReportPending: true,
      };
    case Actions.CREATE_REPORT_SUCCESS:
    case Actions.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        changeReportPending: false,
        currReport: action.report,
        ...resetReportsList,
      };
    case Actions.CREATE_REPORT_ERROR:
    case Actions.UPDATE_REPORT_ERROR:
      return {
        ...state,
        changeReportPending: false,
        changeReportError: action.error,
      };
    case Actions.DELETE_REPORT:
      return {
        ...state,
        deleteReportError: null,
        deleteReportPending: true,
      };
    case Actions.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        deleteReportPending: false,
        ...resetReportsList,
        reports: _.filter(
          state.reports,
          (c) => c.id != action.id,
        ),
      };
    case Actions.DELETE_REPORT_ERROR:
      return {
        ...state,
        deleteReportPending: false,
        deleteReportError: action.error,
      };
    case Actions.GET_CURR_REPORT:
      return {
        ...state,
        getCurrReportError: null,
        getCurrReportPending: true,
      };
    case Actions.GET_CURR_REPORT_SUCCESS:
      return {
        ...state,
        getCurrReportPending: false,
        currReport: action.report,
      };
    case Actions.GET_CURR_REPORT_ERROR:
      return {
        ...state,
        getCurrReportPending: false,
        getCurrReportError: action.error,
      };
    case Actions.SET_STEP_TITLE:
      return {
        ...state,
        stepTitle: action.title,
      };
    case Actions.CLEAR_CURR_REPORT:
      return {
        ...state,
        currReport: null,
      };
    case Actions.RESET_LOAD_REPORT_MEDIA:
      return {
        ...state,
        ...resetReportMedia,
      };
    case Actions.LOAD_REPORT_MEDIA:
      return {
        ...state,
        loadReportMediaError: null,
        loadReportMediaPending: true,
      };
    case Actions.LOAD_REPORT_MEDIA_SUCCESS:
      return {
        ...state,
        loadReportMediaPending: false,
        reportMedia: [
          ...state.reportMedia,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.reportMedia, 'id'),
        ],
        hasMoreReportMedia: !!action.next,
        currReportMediaPage: action.page,
      };
    case Actions.LOAD_REPORT_MEDIA_ERROR:
      return {
        ...state,
        loadReportMediaPending: false,
        loadReportMediaError: action.error,
        hasMoreReportMedia: false,
      };
    case Actions.CREATE_REPORT_MEDIA:
      action.uploadId = state.uploadCtr;

      return {
        ...state,
        uploadCtr: state.uploadCtr + 1,
        reportMediaUploads: {
          ...state.reportMediaUploads,
          // Namespace uploads by report
          [action.reportId]: {
            ..._.get(state, ['reportMediaUploads', action.reportId], {}),
            // Key uploads by ID
            [action.uploadId]: {
              id: action.uploadId,
              progress: 0,
              name: action.file.name,
              mime_type: action.file.type,
            },
          },
        },
      };
    case Actions.CREATE_REPORT_MEDIA_SUCCESS:
      return {
        ...state,
        currReport: {
          ...state.currReport,
          num_media: state.currReport.num_media + 1,
        },
        // Move upload to media list
        reportMedia: [
          action.media,
          ...state.reportMedia,
        ],
        reportMediaUploads: {
          ...state.reportMediaUploads,
          [action.reportId]: _.omit(
            state.reportMediaUploads[action.reportId],
            action.uploadId,
          ),
        },
      };
    case Actions.CREATE_REPORT_MEDIA_PROGRESS:
      return {
        ...state,
        reportMediaUploads: {
          ...state.reportMediaUploads,
          [action.reportId]: {
            ..._.get(state, ['reportMediaUploads', action.reportId], {}),
            [action.uploadId]: {
              ..._.get(state, ['reportMediaUploads', action.reportId, action.uploadId]),
              progress: action.progress,
            },
          },
        },
      };
    case Actions.CREATE_REPORT_MEDIA_ERROR:
      return {
        ...state,
        reportMediaUploads: {
          ...state.reportMediaUploads,
          [action.reportId]: {
            ...state.reportMediaUploads[action.reportId],
            [action.uploadId]: {
              ..._.get(state, ['reportMediaUploads', action.reportId, action.uploadId]),
              error: action.error,
            },
          },
        },
      };
    case Actions.CANCEL_REPORT_UPLOAD:
      return {
        ...state,
        reportMediaUploads: {
          ...state.reportMediaUploads,
          [action.reportId]: _.omit(
            state.reportMediaUploads[action.reportId],
            action.uploadId,
          ),
        },
      };
    case Actions.DELETE_REPORT_MEDIA:
      return {
        ...state,
        deleteReportMediaError: null,
        deleteReportMediaPending: true,
      };
    case Actions.DELETE_REPORT_MEDIA_SUCCESS:
      return {
        ...state,
        deleteReportMediaPending: false,
        reportMedia: _.filter(
          state.reportMedia,
          (m) => m.id != action.id,
        ),
        currReport: {
          ...state.currReport,
          num_media: state.currReport.num_media - 1,
        },
      };
    case Actions.DELETE_REPORT_MEDIA_ERROR:
      return {
        ...state,
        deleteReportMediaPending: false,
        deleteReportMediaError: action.error,
      };
    case Actions.DELETE_REPORT_UPLOAD:
      return {
        ...state,
        reportMediaUploads: {
          ...state.reportMediaUploads,
          [action.reportId]: _.omit(
            state.reportMediaUploads[action.reportId],
            action.uploadId,
          ),
        },
      };
    case CREATE_SAMPLE_SUCCESS:
      if (!state.currReport) {
        return state;
      }

      return {
        ...state,
        currReport: {
          ...state.currReport,
          samples: [
            ...state.currReport.samples,
            action.sample,
          ],
        },
      };
    case CREATE_CLINICAL_SIGN_SUCCESS:
    case UPDATE_CLINICAL_SIGN_SUCCESS:
    case DELETE_CLINICAL_SIGN_SUCCESS:
    case CREATE_ISOLATE_SUCCESS:
    case UPDATE_ISOLATE_SUCCESS:
    case DELETE_ISOLATE_SUCCESS:
    case CREATE_SAMPLE_MEDIA:
    case DELETE_SAMPLE_MEDIA_SUCCESS:
      // Clear out currReport to force a reload where needed
      return {
        ...state,
        currReport: null,
      };
    case UPDATE_SAMPLE_SUCCESS: {
      if (!state.currReport) {
        return state;
      }

      const newSamples =  _.cloneDeep(state.currReport.samples);
      const sampleIdx = _.findIndex(newSamples, (s) => s.id == action.sample.id);
      if (sampleIdx >= 0) {
        newSamples.splice(
          sampleIdx,
          1,
          {
            ...newSamples[sampleIdx],
            ...action.sample,
          },
        );
      }

      return {
        ...state,
        currReport: {
          ...state.currReport,
          samples: newSamples,
        },
      };
    }
    case DELETE_SAMPLE_SUCCESS:
      if (!state.currReport) {
        return state;
      }

      return {
        ...state,
        currReport: {
          ...state.currReport,
          samples: _.filter(
            state.currReport.samples,
            (s) => s.id !== action.id,
          ),
        },
      };
    case CREATE_ACTIVITY_SUCCESS:
      if (!state.currReport) {
        return state;
      }

      return {
        ...state,
        currReport: {
          ...state.currReport,
          num_activities: state.currReport.num_activities + 1,
        },
      };
    case DELETE_ACTIVITY_SUCCESS:
      if (!state.currReport) {
        return state;
      }

      return {
        ...state,
        currReport: {
          ...state.currReport,
          num_activities: state.currReport.num_activities - 1,
        },
      };
    case Actions.TOGGLE_REPORT_SORT:
      return {
        ...state,
        sortDesc: !state.sortDesc,
      };
    case Actions.CLEAR_REPORT_FILTERS:
      return {
        ...state,
        reportFilters: initialState.reportFilters,
      };
    case Actions.UPDATE_REPORT_FILTERS:
      return {
        ...state,
        reportFilters: {
          ...state.reportFilters,
          ...action.newValues,
        },
      };
    default:
      return state;
  }
};
