import * as Actions from './actions';
import { ACCEPT_INVITE_SUCCESS } from '../orgs/actions';

export const initialState = {
  user: null,
  token: null,
  userLoading: false,
  redirectLoc: null,
  signupPending: false,
  signupError: null,
  loginPending: false,
  loginError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  editUserPending: false,
  editUserError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.SIGNUP:
      return {
        ...state,
        signupPending: true,
      };
    case Actions.SIGNUP_SUCCESS:
      return {
        ...state,
        signupPending: false,
        signupError: null,
        user: action.user,
        token: action.key,
        redirectLoc: null,
      };
    case Actions.SIGNUP_ERROR:
      return {
        ...state,
        signupPending: false,
        signupError: action.error,
      };
    case Actions.LOGIN:
      return {
        ...state,
        loginError: null,
        loginPending: true,
      };
    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginPending: false,
        user: action.user,
        token: action.key,
      };
    case Actions.LOGIN_ERROR:
      return {
        ...state,
        loginPending: false,
        loginError: action.error,
      };
    case Actions.LOAD_USER:
      return {
        ...state,
        userLoading: true,
      };
    case Actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        user: action.user,
        token: action.token,
      };
    case Actions.LOAD_USER_ERROR:
      return {
        ...state,
        userLoading: false,
      };
    case Actions.EDIT_USER:
      return {
        ...state,
        editUserPending: true,
        editUserError: null,
      };
    case Actions.EDIT_USER_SUCCESS:
      return {
        ...state,
        editUserPending: false,
        user: action.user,
      };
    case Actions.EDIT_USER_ERROR:
      return {
        ...state,
        editUserPending: false,
        editUserError: action.error,
      };
    case Actions.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };
    case Actions.LOGIN_REQUIRED:
      return {
        ...state,
        redirectLoc: action.redirectLoc,
      };
    case Actions.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordPending: true,
        forgotPasswordError: null,
      };
    case Actions.FORGOT_PASSWORD_DONE:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: action.error,
      };
    case Actions.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordError: null,
      };
    case Actions.RESET_PASSWORD_DONE:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: action.error,
      };
    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          num_orgs: state.user.num_orgs + 1,
        },
      };
    default:
      return state;
  }
};
