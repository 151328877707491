import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Clients from './Clients';
import CreateClient from './CreateClient';
import EditClient from './EditClient';
import SelectWaterBody from '../waterbodies/SelectWaterBody';
import NotFound from '../NotFound';

export default function ClientsIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Clients />
      </Route>
      <Route path={`${path}/create`}>
        <CreateClient />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <EditClient />
      </Route>
      <Route path={`${path}/:id/select-body`}>
        <SelectWaterBody />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
