import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import * as _ from 'lodash';

import {
  GeneralErrors,
  ListCard,
  MediaProgress,
} from '../../components';

import text from '../../styles/text.module.scss';
import styles from './MediaCard.module.scss';
import { onUserInteract } from '../../utils/helpers';

// Can be used for both uploads / existing media
const BaseMediaCard = (props) => {
  const {
    media,
    belongsTo,
    onRemove,
    onCancel,
    onClick,
  } = props;

  const removeProps = {};
  if (onRemove) {
    removeProps.onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      onRemove(media);
    };
    removeProps.onKeyPress = onUserInteract(removeProps.onClick);
  }

  const cancelProps = {};
  if (onCancel) {
    cancelProps.onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      onCancel(media);
    };
    cancelProps.onKeyPress = onUserInteract(cancelProps.onClick);
  }

  let icon = 'file';
  if (media.mime_type.startsWith('video/')) {
    icon = 'video';
  } else if (media.mime_type.startsWith('image/')) {
    icon = 'image';
  }

  const isUpload = _.has(media, 'progress');

  return (
    <ListCard
      className={cx('p-0', {[styles.uploadError]: !!media.error})}
      tag="div"
      arrow={false}
      flex
      onClick={onClick}
      onKeyPress={onClick ? onUserInteract(onClick) : undefined}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon
          icon={media.error ? 'exclamation-triangle' : icon}
        />
      </div>
      <div className={cx('flex-grow-1', text.ellipsize)}>
        { media.error
          ? (
            <GeneralErrors
              error={media.error}
              textOnly
            />
          )
          : media.name
        }
      </div>
      {!isUpload && (
        <a
          className="btn btn-link"
          href={`/api/${belongsTo}-media/${media.id}/download/`}
          rel="noopener noreferrer"
          aria-label="Download media file"
          target="_self"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon="download" />
        </a>
      )}
      {isUpload && !media.error && (
        <React.Fragment>
          <MediaProgress progress={media.progress} />
          <Button
            color="link"
            {...cancelProps}
          >
            <FontAwesomeIcon icon="minus-circle" />
          </Button>
        </React.Fragment>
      )}
      {(!isUpload || media.error) && (
        <Button
          color="link"
          {...removeProps}
        >
          <FontAwesomeIcon icon="trash" />
        </Button>
      )}
    </ListCard>
  );
};
BaseMediaCard.propTypes = {
  belongsTo: PropTypes.oneOf(['report', 'sample']).isRequired,
};

export const MediaCard = React.memo(BaseMediaCard);
