import React from 'react';
import { Col } from 'reactstrap';

import { ReportSection } from './ReportSection';
import { ReportSubsection } from './ReportSubsection';
import { ReadOnlySampleMedia } from './ReadOnlySampleMedia';
import { display, displayArrayNames, lookupOptionValue } from '../../utils/helpers';
import captureConditions from '../../utils/data/capture_conditions';
import sampleConditions from '../../utils/data/sample_conditions';
import isolateLocations from '../../utils/data/isolate_locations';

export const ReadOnlySample = ({ sample }) => {
  return (
    <ReportSection title={`Fish Sample ${sample.fish_id}`}>
      <ReportSubsection title="Species">
        {display(sample.specie, 'common_name') }
      </ReportSubsection>
      <ReportSubsection title="Capture Condition">
        { lookupOptionValue(
          captureConditions,
          _.get(sample, 'capture_cond'),
        )}
      </ReportSubsection>
      <ReportSubsection title="Sample Condition">
        { lookupOptionValue(
          sampleConditions,
          _.get(sample, 'sample_cond'),
        )}
      </ReportSubsection>
      <ReportSubsection title="Length">
        {sample.length ?
          `${_.get(sample.length, 'value')}${_.get(sample.length, 'unit') == 'inch' ? '\"' : ' cm'}`
          : '-'
        }
      </ReportSubsection>
      {_.map(
        _.get(sample, 'clinical_signs'),
        (clinical) => (
          <ReportSubsection
            key={`clinical-sign-${clinical.id}`}
            title={`${clinical.display_name }${clinical.location ? ` (${clinical.location})` : ''} `}
          >
            {clinical.summary }
          </ReportSubsection>
        ),
      )}
      <ReadOnlySampleMedia sample={sample} />
      <Col xs="12"/>
      <ReportSubsection title="Parasites" mode="half">
        {displayArrayNames(sample.parasites) }
      </ReportSubsection>
      <ReportSubsection title="Fungi" mode="half">
        {displayArrayNames(sample.fungi) }
      </ReportSubsection>
      <ReportSubsection title="Bacteria" mode="half">
        {displayArrayNames(sample.bacteria) }
      </ReportSubsection>
      <ReportSubsection title="Virus" mode="half">
        {displayArrayNames(sample.viruses) }
      </ReportSubsection>

      {_.map(
        _.get(sample, 'isolates'),
        (isolate) => (
          <ReportSection
            key={`isolate-${isolate.id}`}
            title={`Isolate (${_.get(isolate.bacteria, 'name') })`}
            table
          >
            <ReportSubsection
              title="Location"
              mode="table"
            >
              { lookupOptionValue(
                isolateLocations,
                _.get(isolate, 'location'),
              )}
            </ReportSubsection>
            <ReportSubsection
              title="Growth Hrs"
              mode="table"
            >
              { isolate.growth_hrs == -1 ? 'Unknown' : isolate.growth_hrs }
            </ReportSubsection>
            <ReportSubsection
              title="Novobiocin (mm)"
              mode="table"
            >
              {isolate.novobicocin}
            </ReportSubsection>
            <ReportSubsection
              title="Terramycin (mm)"
              mode="table"
            >
              {isolate.terramycin}
            </ReportSubsection>
            <ReportSubsection
              title="Romet 30 (mm)"
              mode="table"
            >
              {isolate.romet30}
            </ReportSubsection>
            <ReportSubsection
              title="Aquaflor (mm)"
              mode="table"
            >
              {isolate.aquaflor}
            </ReportSubsection>
          </ReportSection>
        ),
      )}
    </ReportSection>
  );
};
