import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';

import cx from 'classnames';
import * as _ from 'lodash';

import { ListCard } from '../../components';
import { setCurrActivity } from '../../redux/activities/actions';
import { formatDate } from '../../utils/helpers';

import tables from '../../styles/tables.module.scss';
import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import cards from '../../components/lists/ListCard.module.scss';
import { useActivities } from '../../utils/hooks';

export default function ListActivities () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgSlug, id } = useParams();
  const {
    activities,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useActivities(id);

  const gotoEditActivity = useCallback((activity) => {
    dispatch(setCurrActivity(activity));
    history.push(`/app/${orgSlug}/activities/${activity.id}/edit`);
  },[history, orgSlug, id]);

  return(
    <div className={cx(layout.responsiveContainer, 'mx-auto')}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!loading && hasMore}
        useWindow={false}
        className="text-center"
      >
        {
          noResults
            ? (
              <p key="zero" className="text-center">
                You don&apos;t have any activities for this production cycle yet.
              </p>
            )
            : _.map(activities, (a) => {
              let icon;
              let desc;
              let descClass;
              if (a.activity_object.type == 'treatment') {
                icon = 'hand-holding-medical';
                desc = (
                  _.get(a, 'activity_object.treatment_type.alt_name') ||
                  _.get(a, 'activity_object.treatment_type.name')
                );
              } else if (a.activity_object.type == 'vaccination') {
                icon = 'syringe';
                desc = _.get(a, 'activity_object.pathogen.name');
              } else {
                icon = 'fish';
                const val = _.get(a, 'activity_object.value');
                desc = `${val > 0 ? '+' : ''}${val}`;
                descClass = val < 0 ? 'text-danger' : 'text-success';
              }

              return (
                <ListCard
                  key={`activity-${a.id}`}
                  onClick={() => gotoEditActivity(a)}
                  flex
                >
                  <FontAwesomeIcon
                    icon={icon}
                    className="mr-2"
                  />
                  <span className={cx('flex-grow-1', text.ellipsize)}>
                    {formatDate(a.date)} - {a.display_name}
                  </span>
                  <i className={cx(cards.detailSubtext, text.subtext, descClass)}>
                    {desc}
                  </i>
                </ListCard>
              );
            })
        }
        { loading && (
          <div
            className="text-center"
            key="spinner"
          >
            <Spinner className={tables.spinner} />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}
