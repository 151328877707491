import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import cx from 'classnames';

import { useSpecies } from '../../utils/hooks';
import { SearchDropdown } from './SearchDropdown';

import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const SpecieSelect = React.forwardRef((props, ref) => {
  const {
    value: specie,
    onChange,
    invalid,
    placeholder,
    clearable,
    disabled,
  } = props;
  const {
    species,
    hasMore,
    loading,
    noResults,
    reset,
    loadMore,
  } = useSpecies();

  const onSpeciSelected = useCallback((s) => onChange(s), [onChange]);

  const onClear = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange(null);
  }, [onChange]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder={placeholder}
      displayText={_.get(specie, 'name')}
      firstLoad={reset}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      onClear={specie && clearable ? onClear : undefined}
      disabled={disabled}
    >
      { noResults && (
        <div className="text-center">
          No species
        </div>
      )}
      { _.map(
        species,
        (s) => (
          <DropdownItem
            key={`specie-${s.id}`}
            onClick={() => onSpeciSelected(s)}
            className={cx({'selected': s.id == _.get(specie, 'id')})}
          >
            <i className={text.ellipsize}>
              { s.name }
            </i>
            { s.common_name && (
              <div className={text.ellipsize}>
                { s.common_name }
              </div>
            )}
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
SpecieSelect.displayName = 'SpecieSelect';
SpecieSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};
SpecieSelect.defaultProps = {
  placeholder: 'Select Species',
  clearable: false,
  invalid: false,
};
