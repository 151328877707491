import React, { useCallback, useMemo } from 'react';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import _ from 'lodash';

import {
  PageHeader,
  KebabDropdown,
} from '../../components';
import { useRole, useConfirmAction, useQuery } from '../../utils/hooks';
import {
  formatDate,
  formatTemp,
  lookupOptionValue,
  display,
  displayArrayNames,
} from '../../utils/helpers';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { deleteReport } from '../../redux/reports/actions';
import { ReportSection } from './ReportSection';
import { ReportSubsection } from './ReportSubsection';
import { ReadOnlySample } from './ReadOnlySample';
import { ReadOnlyReportMedia } from './ReadOnlyReportMedia';
import feedingActivities from '../../utils/data/feeding_activities';

import layout from '../../styles/layout.module.scss';
import header from '../../components/nav/PageHeader.module.scss';
import styles from './Report.module.scss';
import { resetWaterBodies } from '../../redux/waterbodies/actions';

export default function ReadOnlyReport () {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const query = useQuery();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const currReport = useSelector((s) => s.reports.currReport);
  const confirmAction = useConfirmAction();

  const removeReport = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'report',
      subject: 'this report',
      onConfirm: () => dispatch(deleteReport(id)),
      pendingPath: 'reports.deleteReportPending',
      errorPath: 'reports.deleteReportError',
      onSuccess: () => {
        history.replace(`/app/${orgSlug}/prod-cycles/${currReport.prod_cycle}`);
      },
    });
  }, [currOrg, currReport, confirmAction]);

  const canEdit = useMemo(() => {
    switch (role) {
      case 'write':
        return !_.get(currReport, 'complete', false);
      case 'read':
        return false;
      default:
        return true;
    }
  }, [currReport, role]);

  const gotoClientWaterbody = useCallback(() => {
    dispatch(resetWaterBodies(currReport.client));
    history.push(`/app/${currOrg.slug}/water-bodies/`);
  }, [currOrg, history, currReport]);

  return (
    <div className={layout.page}>
      <PageHeader
        title={`Report${!_.get(currReport, 'complete') ? ' (Incomplete)' : ''}`}
        coverMenu
      >
        <Link
          className={cx('btn btn-header-action', header.secondaryAction)}
          to={query.has('from_search')
            ? `/app/${orgSlug}/search`
            : `/app/${orgSlug}/prod-cycles/${_.get(currReport, 'prod_cycle')}/reports`
          }
        >
          <FontAwesomeIcon icon="angle-left" />
        </Link>
        { canEdit && (
          <>
            <KebabDropdown right>
              <DropdownItem
                tag={Link}
                to={`/app/${orgSlug}/reports/${id}/edit/info`}
              >
                <FontAwesomeIcon icon="cog" />
                Edit Report
              </DropdownItem>
              <DropdownItem onClick={removeReport}>
                <FontAwesomeIcon icon="trash" />
                Delete Report
              </DropdownItem>
            </KebabDropdown>
          </>
        )}
      </PageHeader>
      <div className={cx(layout.pageContent)}>
        <div className={cx(styles.mainReport)}>
          <ReportSection>
            <ReportSubsection
              title="Client"
              id="client"
            >
              <Button
                color="link"
                onClick={gotoClientWaterbody}
              >
                { display(currReport, 'client.name') }
              </Button>
            </ReportSubsection>
            <ReportSubsection
              title="Water Body"
              id="waterbody"
            >
              <Link to={`/app/${orgSlug}/water-bodies/${_.get(currReport, 'waterbody.id')}`}>
                { display(currReport, 'waterbody.name') }
              </Link>
            </ReportSubsection>
            <ReportSubsection
              title="Production Cycle"
              id="prodcycle"
            >
              <Link to={`/app/${orgSlug}/prod-cycles/${currReport.prod_cycle}/reports`}>
                { display(currReport, 'prodcycle_name') }
              </Link>
            </ReportSubsection>
          </ReportSection>
          <ReportSection
            title="Report Info"
            id="reportInfo"
          >
            <ReportSubsection title="Date">
              {formatDate(currReport.date)}
            </ReportSubsection>
            <ReportSubsection title="Case Number">
              { display(currReport, 'case_no') }
            </ReportSubsection>
            <ReportSubsection title="Extension Agent">
              { display(currReport, 'ext_agent') }
            </ReportSubsection>
            <ReportSubsection title="Phone Number">
              { display(currReport, 'phone_number') }
            </ReportSubsection>
            <ReportSubsection title="Diagnostician">
              { display(currReport, 'diagnostician') }
            </ReportSubsection>
          </ReportSection>
          <ReportSection title="Fish Info">
            <ReportSubsection title="Problem Description">
              { display(currReport, 'prob_desc') }
            </ReportSubsection>
            <ReportSubsection title="Problem First Noticed">
              {formatDate(currReport.prob_first_noticied)}
            </ReportSubsection>
            <ReportSubsection title="Feeding Activity">
              { lookupOptionValue(
                feedingActivities,
                _.get(currReport, 'feeding_activity'),
              )}
            </ReportSubsection>
            <ReportSubsection title="Fish Behavior Prior to Death">
              { displayArrayNames(currReport.fish_behavior) }
            </ReportSubsection>
            <ReportSubsection title="Number of Activities">
              { display(currReport, 'num_activities') }
            </ReportSubsection>
          </ReportSection>
          <ReportSection title="Water Quality">
            <ReportSubsection title="Location of Sample">
              { display(currReport, 'water_sample_loc') }
            </ReportSubsection>
            <ReportSubsection title="Temperature">
              {formatTemp(currReport.water_temp)}
            </ReportSubsection>
            <ReportSubsection title="DO (ppm)">
              { display(currReport, 'water_do') }
            </ReportSubsection>
            <ReportSubsection title="Alkalinity (ppm)">
              { display(currReport, 'water_alkalinity') }
            </ReportSubsection>
            <ReportSubsection title="Hardness (ppm)">
              { display(currReport, 'water_hardness') }
            </ReportSubsection>
            <ReportSubsection title="pH">
              { display(currReport, 'water_ph') }
            </ReportSubsection>
            <ReportSubsection title="Total Ammonia (ppm)">
              { display(currReport, 'water_total_amonia') }
            </ReportSubsection>
            <ReportSubsection title="Un-ionized (Toxic) Ammonia (ppm)">
              { display(currReport, 'water_toxic_amonia') }
            </ReportSubsection>
            <ReportSubsection title="Nitrite (ppm)">
              { display(currReport, 'water_nitrite') }
            </ReportSubsection>
            <ReportSubsection title="Chloride (ppm)">
              { display(currReport, 'water_chloride') }
            </ReportSubsection>
            <ReportSubsection title="Nitrate (ppm)">
              { display(currReport, 'water_nitrate') }
            </ReportSubsection>
            <ReportSubsection title="Other">
              { display(currReport, 'water_other') }
            </ReportSubsection>
          </ReportSection>
          {_.map(
            _.get(currReport, 'samples'),
            (sample) => (
              <ReadOnlySample
                key={`sample-${sample.fish_id}`}
                sample={sample}
              />
            ),
          )}

          <ReportSection title="Final Diagnosis">
            <ReportSubsection title="Notes">
              { display(currReport, 'notes') }
            </ReportSubsection>
            <ReportSubsection title="Potential Treatment Type">
              { display(currReport, 'potential_treatment.category_name') }
            </ReportSubsection>
            <ReportSubsection title="Potential Treatment">
              { display(currReport, 'potential_treatment.name') }
            </ReportSubsection>
            <ReportSubsection title="Potential Treatment Description">
              { display(currReport, 'potential_treatment_desc') }
            </ReportSubsection>
            <ReportSubsection title="Misc. Diagnosis Details">
              { displayArrayNames(currReport.misc_details) }
            </ReportSubsection>
            <ReadOnlyReportMedia />
            <Col xs="12" />
            <ReportSubsection
              title="Parasites"
              mode="half"
            >
              { displayArrayNames(currReport.parasites) }
            </ReportSubsection>
            <ReportSubsection
              title="Bacteria"
              mode="half"
            >
              { displayArrayNames(currReport.bacteria) }
            </ReportSubsection>
            <ReportSubsection
              title="Viruses"
              mode="half"
            >
              { displayArrayNames(currReport.viruses) }
            </ReportSubsection>
            <ReportSubsection
              title="Fungi"
              mode="half"
            >
              { displayArrayNames(currReport.fungi) }
            </ReportSubsection>
          </ReportSection>
        </div>
      </div>
    </div>
  );
}
