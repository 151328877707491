import React from 'react';
import { CustomInput } from 'reactstrap';

// eslint-disable-next-line no-unused-vars
const BaseCheckbox = React.forwardRef((props, ref) => {
  const { name, label, value = false, onChange, disabled } = props;

  return (
    <CustomInput
      type="checkbox"
      id={name}
      name={name}
      label={label}
      checked={value}
      onChange={() => onChange(!value)}
      disabled={disabled}
    />
  );
});
BaseCheckbox.displayName = 'Checkbox';

export const Checkbox = React.memo(BaseCheckbox);
