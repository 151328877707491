import React, { createContext, useContext, useCallback, useState } from 'react';

import { useToggle } from './useToggle';
import { ConfirmActionModal } from '../../modals';

const ConfirmActionContext = createContext(null);

const ConfirmActionProvider = ({children}) => {
  const confirmActionModal = useToggle();
  const [options, setOptions] = useState({});

  const confirm = useCallback((opts) => {
    setOptions(opts);
    confirmActionModal.toggle();
  }, [confirmActionModal]);

  return (
    <ConfirmActionContext.Provider
      value={confirm}
    >
      {children}
      <ConfirmActionModal
        {...options}
        {...confirmActionModal}
      />
    </ConfirmActionContext.Provider>
  );
};

const useConfirmAction = () => {
  const context = useContext(ConfirmActionContext);

  if(context === null) {
    throw new Error('useConfirmAction must be used within ConfirmActionProvider');
  }

  return context;
};

export {useConfirmAction, ConfirmActionProvider};
