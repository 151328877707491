import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import NotFound from '../NotFound';
import { getCurrOrg } from '../../redux/orgs/selectors';
import EditReport from './EditReport';
import BacteriaForm from './BacteriaForm';
import PotentialTreatmentForm from './PotentialTreatmentForm';
import NotesForm from './NotesForm';
import ReportMedia from './ReportMedia';
import VirusesForm from './VirusesForm';
import FungiForm from './FungiForm';
import ParasitesForm from './ParasitesForm';
import MiscDetailsForm from './MiscDetailsForm';
import CreateSample from '../samples/CreateSample';
import { getCurrReport } from '../../redux/reports/actions';
import ReadOnlyReport from './ReadOnlyReport';

import layout from '../../styles/layout.module.scss';

export default function Report () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currReport = useSelector((s) => s.reports.currReport);
  const pending = useSelector((s) => s.reports.getCurrReportPending);
  const error = useSelector((s) => s.reports.getCurrReportError);

  // Load currReport if not already
  useEffect(() => {
    if (currOrg && _.get(currReport, 'id') != id) {
      dispatch(getCurrReport(id));
      setStartedLoading(true);
    }
  }, [currOrg, currReport]);

  // Failed to load report. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/`);
    }
  }, [startedLoading, error, history]);

  if (!currReport || pending) {
    return (
      <div className={layout.page}>
        <div className={layout.pageContent}>
          <div className="text-center">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={path}>
        <ReadOnlyReport />
      </Route>
      <Route path={`${path}/create-sample`}>
        <CreateSample />
      </Route>
      <Route path={`${path}/edit`}>
        <EditReport />
      </Route>
      <Route path={`${path}/potential-treatment`}>
        <PotentialTreatmentForm currReport={currReport} />
      </Route>
      <Route path={`${path}/notes`}>
        <NotesForm currReport={currReport} />
      </Route>
      <Route path={`${path}/media`}>
        <ReportMedia />
      </Route>
      <Route path={`${path}/bacteria`}>
        <BacteriaForm currReport={currReport} />
      </Route>
      <Route path={`${path}/viruses`}>
        <VirusesForm currReport={currReport} />
      </Route>
      <Route path={`${path}/fungi`}>
        <FungiForm currReport={currReport} />
      </Route>
      <Route path={`${path}/parasites`}>
        <ParasitesForm currReport={currReport} />
      </Route>
      <Route path={`${path}/misc-details`}>
        <MiscDetailsForm currReport={currReport} />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
