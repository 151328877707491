import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Spinner, DropdownItem } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import cx from 'classnames';

import {
  CreateDrawer,
  CreateDrawerItem,
  PageHeader,
  TileBoard,
  Tile,
  KebabDropdown,
} from '../../components';
import {
  useRole,
  useConfirmAction,
  useProdCycles,
} from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { setCurrProdCycle } from '../../redux/prodcycles/actions';
import { getCurrWaterBody, deleteWaterBody } from '../../redux/waterbodies/actions';
import { formatDate } from '../../utils/helpers';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import { subtext } from '../../styles/text.module.scss';

export default function WaterBodyDetails () {
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const dispatch = useDispatch();
  const { orgSlug, id: bodyId } = useParams();
  const role = useRole();
  const {
    prodCycles,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useProdCycles(bodyId);
  const currWaterBody = useSelector((s) => s.waterBodies.currWaterBody);
  const confirmAction = useConfirmAction();

  // Fetch currWaterBody if we don't have it
  useEffect(() => {
    if (_.get(currWaterBody, 'id') != bodyId) {
      dispatch(getCurrWaterBody(bodyId));
    }
  }, [bodyId, currWaterBody]);

  const gotoCreateProdCycle = useCallback(
    () => history.push(`/app/${orgSlug}/water-bodies/${bodyId}/create-cycle`),
    [history, orgSlug, bodyId],
  );

  const gotoProdCycle = useCallback((cycle) => {
    dispatch(setCurrProdCycle(cycle));
    history.push(`/app/${orgSlug}/prod-cycles/${cycle.id}`);
  },[history, orgSlug, bodyId]);

  const removeWaterBody = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'waterbody',
      subject: 'this waterbody',
      onConfirm: () => dispatch(deleteWaterBody(
        bodyId,
      )),
      pendingPath: 'waterBodies.deleteWaterBodyPending',
      errorPath: 'waterBodies.deleteWaterBodyError',
      onSuccess: history.goBack,
    });
  }, [currOrg, confirmAction]);

  return (
    <div className={layout.page}>
      <PageHeader
        title={currWaterBody ? currWaterBody.name :'Water Body'}
      >
        { role != 'read' && (
          <KebabDropdown>
            <DropdownItem
              tag={Link}
              to={`/app/${orgSlug}/water-bodies/${bodyId}/edit`}
            >
              <FontAwesomeIcon icon="cog" />
              Edit Water Body
            </DropdownItem>
            <DropdownItem onClick={removeWaterBody}>
              <FontAwesomeIcon icon="trash" />
              Delete Water Body
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      { !_.includes(['read'], role) && (
        <CreateDrawer>
          <CreateDrawerItem
            label="Prod Cycle"
            icon="tint"
            onClick={gotoCreateProdCycle}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, layout.pageContentDrawer)}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          className="text-center"
        >
          {
            noResults
              ? (
                <p key="zero" className="text-center">
                  You don&apos;t have any production cyles for this waterbody yet.
                </p>
              )
              :
              <TileBoard>
                {_.map(prodCycles, (cycle) =>
                  <Tile
                    key={`tile-${cycle.id}`}
                    onClick={() => gotoProdCycle(cycle)}
                  >
                    { cycle.name }
                    <div className={subtext}>
                      {formatDate(cycle.start)}
                    </div>
                  </Tile>,
                )}
                { loading && (
                  <Tile
                    key="spinner"
                    transparent
                  >
                    <Spinner className={tables.spinner} />
                  </Tile>
                )}
              </TileBoard>

          }
        </InfiniteScroll>
      </div>
    </div>
  );
}
