import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { getPathogens } from '../../redux/search/selectors';
import {
  loadPathogens,
  RESET_LOAD_PATHOGENS,
} from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';

// eslint-disable-next-line no-unused-vars
export const PathogenSelect = React.forwardRef((props, ref) => {
  const {
    value: pathogen,
    onChange,
    invalid,
    filter,
    placeholder,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const pathogens = useSelector(getPathogens);
  const currPage = useSelector((s) => s.search.currPathogensPage);
  const hasMore = useSelector((s) => s.search.hasMorePathogens);
  const loading = useSelector((s) => s.search.loadPathogensPending);

  const onPathogenSelected = useCallback((c) => onChange(c), [onChange]);

  const resetPathogens = useCallback(() => {
    dispatch(action(RESET_LOAD_PATHOGENS));
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadPathogens(currPage + 1, query, filter));
  }, [currPage]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder={placeholder}
      displayText={_.get(pathogen, 'name')}
      firstLoad={resetPathogens}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      onOpen={resetPathogens}
      disabled={disabled}
    >
      { !loading && _.get(pathogens, 'length', 0) == 0 && (
        <div className="text-center">
          No pathogens
        </div>
      )}
      { _.map(
        pathogens,
        (p) => (
          <DropdownItem
            key={`pathogen-${p.id}`}
            className={cx({'selected': p.id == _.get(pathogen, 'id')})}
            onClick={() => onPathogenSelected(p)}
          >
            <div>
              <i>{ p.name }</i>
              { p.common_name && (
                <div>{ p.common_name }</div>
              )}
            </div>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
PathogenSelect.displayName = 'PathogenSelect';
PathogenSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
PathogenSelect.defaultProps = {
  placeholder: 'Select Pathogen',
};
