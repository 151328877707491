import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';

import {
  updateWaterBodyFilters,
  CLEAR_WATERBODY_FILTERS,
} from '../../redux/waterbodies/actions';
import {
  getWaterBodyFilters,
  getWaterBodySearchCtx,
  getLocalWaterBodySearchCtx,
} from '../../redux/waterbodies/selectors';
import {
  BackButton,
  CancelButton,
  ClientSelect,
  MeasurementRangeInput,
  PageHeader,
} from '../../components';
import { Measurements } from '../../components/inputs/Measurements';
import { action } from '../../redux/helpers';

import layout from '../../styles/layout.module.scss';

export default function WaterBodySearch () {
  const filters = useSelector(getWaterBodyFilters);
  const searchCtx = useSelector(getWaterBodySearchCtx);
  const localSearchCtx = useSelector(getLocalWaterBodySearchCtx);
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilter = useCallback((key, value) => {
    dispatch(updateWaterBodyFilters({ [key]: value }));
  }, []);

  const clearFilters = useCallback(() => {
    dispatch(action(CLEAR_WATERBODY_FILTERS));
  }, []);

  const submit = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Filter Waterbodies"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <Form
          className={layout.responsiveContainer}
          onSubmit={submit}
        >
          <Button
            color="link"
            className="float-right"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
          <div className="clearfix"></div>
          <FormGroup>
            <Label>Client</Label>
            <ClientSelect
              value={filters.client}
              onChange={(c) => updateFilter('client', c)}
              clearable
            />
          </FormGroup>
          <FormGroup>
            <Label>Type</Label>
            <CustomInput
              id="waterbody_type"
              type="select"
              name="waterbody_type"
              className="form-control"
              value={filters.waterbody_type}
              onChange={(e) => updateFilter('waterbody_type', e.target.value)}
            >
              <option value="">-- Select water body type --</option>
              <option value="tank">Tank</option>
              <option value="pond">Pond</option>
              <option value="raceway">Raceway</option>
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Material</Label>
            <CustomInput
              id="material"
              type="select"
              name="material"
              className="form-control"
              value={filters.material}
              onChange={(e) => updateFilter('material', e.target.value)}
            >
              <option value="">-- Select water body material --</option>
              <option value="earthen">Earthen</option>
              <option value="concrete">Concrete</option>
              <option value="plastic">Plastic</option>
              <option value="metal">Metal</option>
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Location</Label>
            <CustomInput
              id="location"
              type="select"
              name="location"
              className="form-control"
              value={filters.location}
              onChange={(e) => updateFilter('location', e.target.value)}
            >
              <option value="">-- Select water body location --</option>
              <option value="indoor">Indoor</option>
              <option value="outdoor">Outdoor</option>
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Surface Area</Label>
            <MeasurementRangeInput
              type={Measurements.AREA}
              value={filters.surface_area}
              onChange={(val) => updateFilter('surface_area', val)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Volume</Label>
            <MeasurementRangeInput
              type={Measurements.VOLUME}
              value={filters.volume}
              onChange={(val) => updateFilter('volume', val)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Average Depth</Label>
            <MeasurementRangeInput
              type={Measurements.DISTANCE}
              value={filters.average_depth}
              onChange={(val) => updateFilter('average_depth', val)}
            />
          </FormGroup>
          <Button
            color="primary"
            disabled={searchCtx == localSearchCtx}
            block
          >
            Apply Filters
          </Button>
          <CancelButton />
        </Form>
      </div>
    </div>
  );
}
