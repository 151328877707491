import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import cx from 'classnames';

import { createIsolate, updateIsolate } from '../../redux/samples/actions';
import {
  CancelButton,
  GeneralErrors,
  PathogenSelect,
  Radio,
  RadioGroup,
} from '../../components';
import { useReadOnly } from '../../utils/hooks';
import isolateLocations from '../../utils/data/isolate_locations';

import layout from '../../styles/layout.module.scss';
import styles from './IsolateForm.module.scss';

export default function IsolateForm ({isolate, onSubmit}) {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm({ defaultValues: isolate || {} });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.samples.changeIsolatePending);
  const error = useSelector((s) => s.samples.changeIsolateError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    const newValues = {
      ...data,
      bacteria: _.get(data, 'bacteria.id'),
    };

    if (isolate) {
      dispatch(updateIsolate({
        ...newValues,
        id: isolate.id,
      }));
    } else {
      dispatch(createIsolate({
        ...newValues,
        location: newValues.location || undefined,
        growth_hrs: newValues.growth_hrs || undefined,
        sample: Number(id),
      }));
    }
  }, [isolate, id]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <h4>Isolate Details</h4>
        <FormGroup>
          <Label>Location</Label>
          <Controller
            as={
              <RadioGroup>
                {_.map(
                  isolateLocations,
                  (loc) => (
                    <Radio
                      key={`iso-loc-${loc.value}`}
                      value={loc.value}
                      label={loc.text}
                    />
                  ),
                )}
              </RadioGroup>
            }
            name="location"
            control={control}
            invalid={ isSubmitted && _.has(error, 'location') }
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'location') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Growth in hours after initial culture</Label>
          <Controller
            as={
              <RadioGroup>
                <Radio
                  value={-1}
                  label="Unknown"
                />
                <Radio
                  value={24}
                  label="24 hours"
                />
                <Radio
                  value={48}
                  label="48 hours"
                />
                <Radio
                  value={72}
                  label="72 hours"
                />
                <Radio
                  value={96}
                  label="96 hours"
                />
              </RadioGroup>
            }
            name="growth_hrs"
            control={control}
            invalid={ isSubmitted && _.has(error, 'growth_hrs') }
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'growth_hrs') }
          </FormFeedback>
        </FormGroup>
        <h4>Antibiotic Susceptibility</h4>
        <FormGroup>
          <Label>Bacteria</Label>
          <Controller
            as={PathogenSelect}
            name="bacteria"
            placeholder="Select Bacteria"
            control={control}
            invalid={ isSubmitted && _.has(error, 'bacteria') }
            filter="bac"
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'bacteria') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Bacteria No-Growth Zone (mm)</Label>
          <div className={styles.zone}>
            <div className={cx(
              styles.zoneInner,
              { 'is-invalid': _.has(error, 'novobicocin') },
            )}>
              <Label className={styles.zoneLabel}>
                Novobicocin
              </Label>
              <Input
                innerRef={register}
                type="text"
                name="novobicocin"
                className={styles.inputText}
                defaultValue="0"
                invalid={_.has(error, 'novobicocin')}
                disabled={readOnly}
              />
            </div>
            <FormFeedback role="alert">
              { _.get(error, 'novobicocin') }
            </FormFeedback>
          </div>
          <div className={styles.zone}>
            <div className={cx(
              styles.zoneInner,
              { 'is-invalid': _.has(error, 'terramycin') },
            )}>
              <Label className={styles.zoneLabel}>
                Terramycin
              </Label>
              <Input
                innerRef={register}
                type="text"
                name="terramycin"
                className={styles.inputText}
                defaultValue="0"
                invalid={_.has(error, 'terramycin')}
                disabled={readOnly}
              />
            </div>
            <FormFeedback role="alert">
              { _.get(error, 'terramycin') }
            </FormFeedback>
          </div>
          <div className={styles.zone}>
            <div className={cx(
              styles.zoneInner,
              { 'is-invalid': _.has(error, 'romet30') },
            )}>
              <Label className={styles.zoneLabel}>
                Romet 30
              </Label>
              <Input
                innerRef={register}
                type="text"
                name="romet30"
                className={styles.inputText}
                defaultValue="0"
                invalid={_.has(error, 'romet30')}
                disabled={readOnly}
              />
            </div>
            <FormFeedback role="alert">
              { _.get(error, 'romet30') }
            </FormFeedback>
          </div>
          <div className={styles.zone}>
            <div className={cx(
              styles.zoneInner,
              { 'is-invalid': _.has(error, 'aquaflor') },
            )}>
              <Label className={styles.zoneLabel}>
                Aquaflor
              </Label>
              <Input
                innerRef={register}
                type="text"
                name="aquaflor"
                className={styles.inputText}
                defaultValue="0"
                invalid={_.has(error, 'aquaflor')}
                disabled={readOnly}
              />
            </div>
            <FormFeedback role="alert">
              { _.get(error, 'aquaflor') }
            </FormFeedback>
          </div>
        </FormGroup>
        { isSubmitted && <GeneralErrors error={error} /> }
        {!readOnly && (
          <Button
            color="primary"
            disabled={pending}
            block
          >
            Submit
            { pending && <Spinner size="sm" /> }
          </Button>
        )}
        <CancelButton />
      </Form>
    </div>
  );
}
IsolateForm.propTypes = {
  isolate: PropTypes.object,
  onSubmit: PropTypes.func,
};
