import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  CreateDrawer,
  CreateDrawerItem,
  PageHeader,
  SearchInput,
} from '../../components';
import Client from './Client';
import { useClients, useRole } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';

export default function Clients () {
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const { orgSlug } = useParams();
  const role = useRole();
  const clientQuery = useRef('');
  const {
    clients,
    hasMore,
    loading,
    noResults,
    loadMore,
    reset,
  } = useClients();

  // Reset list anytime currOrg changes
  useEffect(() => {
    if (currOrg) {
      reset();
    }
  }, [currOrg]);

  const myLoadMore = useCallback(() => {
    loadMore({ query: clientQuery.current });
  }, [loadMore]);

  const onQueryChange = useCallback((q) => {
    clientQuery.current = q;
    reset();
  }, [reset]);

  const gotoCreateClient = useCallback(
    () => history.push(`/app/${orgSlug}/clients/create`),
    [history, orgSlug],
  );

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Clients" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader title="Clients" />
      { !_.includes(['read'], role) && (
        <CreateDrawer>
          <CreateDrawerItem
            key="new-client"
            label="Client"
            icon="users"
            onClick={gotoCreateClient}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, 'p-0')}>
        <SearchInput onChange={onQueryChange} />
        <InfiniteScroll
          loadMore={myLoadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <table className={tables.table}>
            <thead className={tables.header}>
              <tr>
                <th>Name</th>
                <th className={tables.actions}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                noResults
                  ? (
                    <tr key="zero">
                      <td className={tables.zero} colSpan={2}>
                        You don&apos;t have any clients for this organization yet.
                      </td>
                    </tr>
                  )
                  : _.map( clients, (client) =>
                    <Client
                      key={`client-${client.id}`}
                      client={client}
                    />,
                  )
              }
              { loading && (
                <tr
                  className="text-center"
                  key="spinner"
                >
                  <td colSpan={2}>
                    <Spinner className={tables.spinner}  />
                  </td>
                </tr>
              )}
              { !loading && clients.length > 0 &&
                <tr>
                  <td
                    className={tables.end}
                    colSpan={2}
                  >
                    You&apos;ve reached the end of the client list!
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
}
