export default [
  {
    condPaths: ['num_media'],
    text: 'Media',
    subtext: (report) => `${report.num_media} file${report.num_media > 1 ? 's' : ''}`,
    urlSuffix: 'media',
  },
  {
    condPaths: ['notes'],
    text: 'Notes',
    urlSuffix: 'notes',
  },
  {
    condPaths: ['potential_treatment', 'potential_treatment_desc'],
    text: 'Potential Treatment',
    urlSuffix: 'potential-treatment',
  },
  {
    condPaths: ['parasites.length'],
    text: 'Parasites',
    urlSuffix: 'parasites',
    subtext: (report) => _.join(_.map(report.parasites, (p) => p.name), ','),
  },
  {
    condPaths: ['bacteria.length'],
    text: 'Bacteria',
    urlSuffix: 'bacteria',
    subtext: (report) => _.join(_.map(report.bacteria, (p) => p.name), ','),
  },
  {
    condPaths: ['viruses.length'],
    text: 'Viruses',
    urlSuffix: 'viruses',
    subtext: (report) => _.join(_.map(report.viruses, (p) => p.name), ','),
  },
  {
    condPaths: ['fungi.length'],
    text: 'Fungi',
    urlSuffix: 'fungi',
    subtext: (report) => _.join(_.map(report.fungi, (p) => p.name), ','),
  },
  {
    condPaths: ['misc_details.length'],
    text: 'Misc. Diagnosis Details',
    urlSuffix: 'misc-details',
    subtext: (report) => _.join(_.map(report.misc_details, (d) => d.name), ','),
  },
];
