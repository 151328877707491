import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_WATERBODIES,
  LOAD_WATERBODIES_SUCCESS,
  LOAD_WATERBODIES_ERROR,
  CREATE_WATERBODY,
  UPDATE_WATERBODY,
  CREATE_WATERBODY_SUCCESS,
  UPDATE_WATERBODY_SUCCESS,
  CREATE_WATERBODY_ERROR,
  UPDATE_WATERBODY_ERROR,
  GET_CURR_WATERBODY,
  GET_CURR_WATERBODY_SUCCESS,
  GET_CURR_WATERBODY_ERROR,
  DELETE_WATERBODY,
  DELETE_WATERBODY_SUCCESS,
  DELETE_WATERBODY_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export function* loadWaterBodies (action) {
  const { slug, page, searchCtx } = action;

  const params = {
    client__org__slug: slug,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    s: searchCtx,
  };

  try {
    const response = yield minDelayCall(api.loadWaterBodies, params);

    yield put({ type: LOAD_WATERBODIES_SUCCESS, ...response, page, searchCtx });
  } catch (error) {
    yield put({ type: LOAD_WATERBODIES_ERROR, error: error.response.data });
  }
}

export function* createWaterBody ({ formData }) {
  try {
    const waterBody = yield minDelayCall(api.createWaterBody, formData);

    yield put({ type: CREATE_WATERBODY_SUCCESS, waterBody });
  } catch (error) {
    yield put({ type: CREATE_WATERBODY_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateWaterBody ({ formData }) {
  try {
    const waterBody = yield minDelayCall(api.updateWaterBody, formData);

    yield put({ type: UPDATE_WATERBODY_SUCCESS, waterBody });
  } catch (error) {
    yield put({ type: UPDATE_WATERBODY_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrWaterBody ({ id }) {
  try {
    const waterBody = yield minDelayCall(api.getWaterBody, id);

    yield put({ type: GET_CURR_WATERBODY_SUCCESS, waterBody });
  } catch (error) {
    yield put({ type: GET_CURR_WATERBODY_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteWaterBody (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteWaterBody, id);

    yield put({ type: DELETE_WATERBODY_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_WATERBODY_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_WATERBODIES, loadWaterBodies),
    takeLatest(CREATE_WATERBODY, createWaterBody),
    takeLatest(GET_CURR_WATERBODY, getCurrWaterBody),
    takeLatest(UPDATE_WATERBODY, updateWaterBody),
    takeLatest(DELETE_WATERBODY, deleteWaterBody),
  ]);
}
