import * as Actions from './actions';

const resetActivitiesList = {
  activities: [],
  hasMoreActivities: true,
  currActivitiesPage: 0,
};

export const initialState = {
  ...resetActivitiesList,
  hasMoreActivities: false,
  loadActivitiesPending: false,
  loadActivitiesError: null,
  getCurrActivityPending: false,
  getCurrActivityError: null,
  changeActivityPending: false,
  changeActivityError: null,
  deleteActivityPending: false,
  deleteActivityError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_ACTIVITIES:
      return {
        ...state,
        ...resetActivitiesList,
      };
    case Actions.LOAD_ACTIVITIES:
      return {
        ...state,
        loadActivitiesError: null,
        loadActivitiesPending: true,
      };
    case Actions.LOAD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loadActivitiesPending: false,
        activities: [
          ...state.activities,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.activities, 'id'),
        ],
        hasMoreActivities: !!action.next,
        currActivitiesPage: action.page,
      };
    case Actions.LOAD_ACTIVITIES_ERROR:
      return {
        ...state,
        loadActivitiesPending: false,
        loadActivitiesError: action.error,
        hasMoreActivities: false,
      };
    case Actions.CREATE_ACTIVITY:
    case Actions.UPDATE_ACTIVITY:
      return {
        ...state,
        changeActivityError: null,
        changeActivityPending: true,
      };
    case Actions.CREATE_ACTIVITY_SUCCESS:
    case Actions.UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        currActivity: action.activity,
        changeActivityPending: false,
        ...resetActivitiesList,
      };
    case Actions.CREATE_ACTIVITY_ERROR:
    case Actions.UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        changeActivityPending: false,
        changeActivityError: action.error,
      };
    case Actions.DELETE_ACTIVITY:
      return {
        ...state,
        deleteActivityError: null,
        deleteActivityPending: true,
      };
    case Actions.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteActivityPending: false,
        ...resetActivitiesList,
        activities: _.filter(
          state.activities,
          (a) => a.id != action.id,
        ),
      };
    case Actions.DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        deleteActivityPending: false,
        deleteActivityError: action.error,
      };
    case Actions.SET_CURR_ACTIVITY:
      return {
        ...state,
        currActivity: action.activity,
      };
    case Actions.GET_CURR_ACTIVITY:
      return {
        ...state,
        getCurrActivityPending: true,
      };
    case Actions.GET_CURR_ACTIVITY_SUCCESS:
      return {
        ...state,
        getCurrActivityPending: false,
        getCurrActivityError: null,
        currActivity: action.activity,
      };
    case Actions.GET_CURR_ACTIVITY_ERROR:
      return {
        ...state,
        getCurrActivityPending: false,
        getCurrActivityError: action.error,
      };
    default:
      return state;
  }
};
