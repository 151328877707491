import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';

import {
  VALIDATE_INVITE,
  VALIDATE_INVITE_SUCCESS,
  VALIDATE_INVITE_ERROR,
  ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_ERROR,
  GET_ORGS,
  GET_ORGS_SUCCESS,
  GET_ORGS_ERROR,
  SWITCH_ORG,
  SWITCH_ORG_SUCCESS,
  SWITCH_ORG_ERROR,
  GET_ORG_DETAILS,
  GET_ORG_DETAILS_SUCCESS,
  GET_ORG_DETAILS_ERROR,
  CREATE_ORG,
  CREATE_ORG_SUCCESS,
  CREATE_ORG_ERROR,
  UPDATE_ORG,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_ERROR,
  DELETE_ORG,
  DELETE_ORG_SUCCESS,
  DELETE_ORG_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export const LAST_ORG_COOKIE = '_ksu_last_org';

export function* validateInvite (action) {
  const { key } = action;
  try {
    const org = yield minDelayCall(api.validateInvite, key);

    yield put({ type: VALIDATE_INVITE_SUCCESS, org, key });
  } catch (error) {
    yield put({ type: VALIDATE_INVITE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* acceptInvite (action) {
  const { key, onSuccess } = action;

  try {
    const org = yield minDelayCall(api.acceptInvite, key);

    yield put({ type: ACCEPT_INVITE_SUCCESS, org, key });

    onSuccess(org);
  } catch (error) {
    yield put({ type: ACCEPT_INVITE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* getOrgs (action) {
  const { page, query } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
  };

  try {
    const response = yield minDelayCall(api.getOrgs, params);

    yield put({ type: GET_ORGS_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: GET_ORGS_ERROR, error: error.response.data });
  }
}

export function* getOrg (action) {
  const { slug } = action;

  try {
    const org = yield minDelayCall(api.getOrg, slug);

    yield put({ type: SWITCH_ORG_SUCCESS, org });
    new Cookies().set(LAST_ORG_COOKIE, slug, { path: '/' });
  } catch (error) {
    yield put({ type: SWITCH_ORG_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* getOrgDetails (action) {
  const { slug } = action;

  try {
    const org = yield minDelayCall(api.getOrgDetails, slug);

    yield put({ type: GET_ORG_DETAILS_SUCCESS, org });
  } catch (error) {
    yield put({ type: GET_ORG_DETAILS_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* createOrg (action) {
  const { formData } = action;

  try {
    const org = yield minDelayCall(api.createOrg, formData);

    yield put({ type: CREATE_ORG_SUCCESS, org });
  } catch (error) {
    yield put({ type: CREATE_ORG_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateOrg (action) {
  const { slug, formData } = action;

  try {
    const org = yield minDelayCall(api.updateOrg, slug, formData);

    yield put({ type: UPDATE_ORG_SUCCESS, org });
  } catch (error) {
    yield put({ type: UPDATE_ORG_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* deleteOrg (action) {
  const { slug } = action;

  try {
    yield minDelayCall(api.deleteOrg, slug);

    new Cookies().remove(LAST_ORG_COOKIE, { path: '/' });
    yield put({ type: DELETE_ORG_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_ORG_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(VALIDATE_INVITE, validateInvite),
    takeLatest(ACCEPT_INVITE, acceptInvite),
    takeLatest(GET_ORGS, getOrgs),
    takeLatest(SWITCH_ORG, getOrg),
    takeLatest(GET_ORG_DETAILS, getOrgDetails),
    takeLatest(CREATE_ORG, createOrg),
    takeLatest(UPDATE_ORG, updateOrg),
    takeLatest(DELETE_ORG, deleteOrg),
  ]);
}
