import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';

import {
  BackButton,
  CreateDrawer,
  CreateDrawerItem,
  ListCard,
  PageHeader,
} from '../../components';
import { useIsolates, useReadOnly } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import tables from '../../styles/tables.module.scss';

export default function Bacteriology () {
  const history = useHistory();
  const readOnly = useReadOnly();
  const { orgSlug, id } = useParams();
  const {
    isolates,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useIsolates(id);

  const gotoCreateIsolate = useCallback(() => {
    history.push(`/app/${orgSlug}/samples/${id}/create-isolate`);
  }, [history, orgSlug, id]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Bacteriology"
        coverMenu
      >
        <BackButton/>
      </PageHeader>
      {!readOnly && (
        <CreateDrawer>
          <CreateDrawerItem
            label="Add Isolate"
            icon="bacteria"
            onClick={gotoCreateIsolate}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, layout.pageContentDrawer)}>
        <div className={cx(layout.responsiveContainer, 'text-center')}>
          {noResults
            ? <p>This sample has no isolates yet.</p>
            : (
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={!loading && hasMore}
                useWindow={false}
                style={{minWidth: 0}}
              >
                {_.map(isolates, (isolate) => (
                  <ListCard
                    key={`isolate-${isolate.id}`}
                    linkTo={`/app/${orgSlug}/samples/isolates/${isolate.id}`}
                  >
                    <div className={text.ellipsize}>
                      [{_.capitalize(isolate.location)}] {isolate.bacteria.name}
                    </div>
                  </ListCard>
                ))}
                { loading && (
                  <div
                    className="text-center"
                    key="spinner"
                  >
                    <Spinner className={cx(tables.spinner, 'mt-3')}  />
                  </div>
                )}
              </InfiniteScroll>
            )
          }
          <Button
            className="mt-3"
            color="secondary"
            onClick={history.goBack}
            block
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
