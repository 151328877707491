import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import ProdCycleForm from './ProdCycleForm';

import layout from '../../styles/layout.module.scss';

export default function CreateProdCycle () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currProdCycle = useSelector((s) => s.prodCycles.currProdCycle);

  const gotoProdCyle = useCallback(() => {
    history.replace(`/app/${orgSlug}/prod-cycles/${_.get(currProdCycle, 'id')}/activities`);
  }, [orgSlug, currProdCycle]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Production Cycle"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <ProdCycleForm onSubmit={gotoProdCyle} />
    </div>
  );
}
