import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { createOrg, updateOrg } from '../../redux/orgs/actions';
import { CancelButton, GeneralErrors } from '../../components';

import layout from '../../styles/layout.module.scss';

export default function OrgForm (props) {
  const { org } = props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({ defaultValues: org || {} });
  const { isSubmitted, dirtyFields } = formState;

  const pending = useSelector((s) => s.orgs.changeOrgPending);
  const error = useSelector((s) => s.orgs.changeOrgError);
  const submitSuccess = isSubmitted && !pending && !error;

  const { name, slug } = watch();

  // When name changes
  useEffect(() => {
    if (!org) {
      if (!_.has(dirtyFields, 'slug')) {
        // Auto-generate slug based on name
        setValue('slug', _.kebabCase(name));
      } else if (!getValues('slug')) {
        // Reset dirty flag if slug was manually cleared
        reset({
          name,
          slug: _.kebabCase(name),
        });
      }
    }
  }, [name, org]);

  const onSubmit = useCallback((data) => {
    if (org) {
      dispatch(updateOrg(org.slug, data));
    } else {
      dispatch(createOrg(data));
    }
  }, [org]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      _.invoke(props, 'onSubmit', slug);
    }
  }, [submitSuccess, slug]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <Label>Name</Label>
          <Input
            required
            innerRef={register}
            type="text"
            name="name"
            placeholder="Name"
            invalid={ isSubmitted && _.has(error, 'name') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'name') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Slug</Label>
          <Input
            required
            innerRef={register}
            type="text"
            name="slug"
            placeholder="Slug"
            autoComplete="off"
            invalid={ isSubmitted && _.has(error, 'slug') }
            disabled={!!org}
          />
          <FormFeedback role="alert">
            { _.get(error, 'slug') }
          </FormFeedback>
        </FormGroup>
        { isSubmitted && <GeneralErrors error={error} /> }
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Submit
          { pending && <Spinner size="sm" /> }
        </Button>
        <CancelButton />
      </Form>
    </div>
  );
}
OrgForm.propTypes = {
  org: PropTypes.object,
  onSubmit: PropTypes.func,
};
