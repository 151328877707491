import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { createClinicalSign, updateClinicalSign } from '../../redux/samples/actions';
import {
  CancelButton,
  Checkbox,
  GeneralErrors,
} from '../../components';
import { useReadOnly } from '../../utils/hooks';
import clinicalSigns from '../../utils/data/clinical_signs';
import lesionLocations from '../../utils/data/lesion_locations';

import layout from '../../styles/layout.module.scss';

export default function ClinicalSignForm ({clinicalSign, onSubmit}) {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
  } = useForm({ defaultValues: clinicalSign || {} });
  const formData = watch();
  const selectedType = _.get(formData, 'sign_object.type');

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.samples.changeClinicalSignPending);
  const error = useSelector((s) => s.samples.changeClinicalSignError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    if (clinicalSign) {
      // Add back disabled type field
      data.sign_object.type = _.get(clinicalSign, 'sign_object.type');

      dispatch(updateClinicalSign({
        ...data,
        id: clinicalSign.id,
      }));
    } else {
      dispatch(createClinicalSign({
        ...data,
        sign_object: {
          ...data.sign_object,
          location: data.sign_object.location || undefined,
        },
        sample: Number(id),
      }));
    }
  }, [clinicalSign, id]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Type</Label>
          <CustomInput
            id="select"
            innerRef={register}
            type="select"
            name="sign_object.type"
            className="form-control"
            invalid={ isSubmitted && _.has(error, 'sign_object.type') }
            disabled={!!clinicalSign || readOnly}
          >
            <option
              key="no-sign"
              value=""
            >
              -- Select Type --
            </option>
            {_.map(
              _.keys(clinicalSigns),
              (signType) => (
                <option
                  key={`sign-type-${signType}`}
                  value={signType}
                >
                  {clinicalSigns[signType].display_name}
                </option>
              ),
            )}
          </CustomInput>
          <FormFeedback role="alert">
            { _.get(error, 'sign_object.type') }
          </FormFeedback>
        </FormGroup>
        {selectedType == 'lesion' && (
          <FormGroup>
            <Label>Lesion Location</Label>
            <CustomInput
              id="select"
              innerRef={register}
              type="select"
              name="sign_object.location"
              className="form-control"
              invalid={ isSubmitted && _.has(error, 'sign_object.location') }
              disabled={readOnly}
            >
              {_.map(
                lesionLocations,
                (loc) => (
                  <option
                    key={`lesion-loc-${loc.value}`}
                    value={loc.value}
                  >
                    {loc.text}
                  </option>
                ),
              )}
            </CustomInput>
            <FormFeedback role="alert">
              { _.get(error, 'sign_object.location') }
            </FormFeedback>
          </FormGroup>
        )}
        {_.map(
          _.get(clinicalSigns, [selectedType, 'options'], []),
          (option) => (
            <FormGroup key={`${selectedType}-${option.key}`}>
              <Controller
                as={Checkbox}
                name={`sign_object.${option.key}`}
                control={control}
                label={option.label}
                disabled={readOnly}
              />
            </FormGroup>
          ),
        )}
        { isSubmitted && <GeneralErrors error={error} /> }
        { selectedType && (
          <>
            {!readOnly && (
              <Button
                color="primary"
                disabled={pending}
                block
              >
                Submit
                { pending && <Spinner size="sm" /> }
              </Button>
            )}
            <CancelButton />
          </>
        )}
      </Form>
    </div>
  );
}
ClinicalSignForm.propTypes = {
  clinicalSign: PropTypes.object,
  onSubmit: PropTypes.func,
};
