import * as Actions from './actions';

const resetClientsList = {
  clients: [],
  hasMoreClients: true,
  currClientsPage: 0,
};

export const initialState = {
  ...resetClientsList,
  hasMoreClients: false,
  editClient: null,
  loadClientsPending: false,
  loadClientsError: null,
  changeClientPending: false,
  changeClientError: null,
  deleteClientPending: false,
  deleteClientError: null,
  getEditClientPending: false,
  getEditClientError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_CLIENTS:
      return {
        ...state,
        ...resetClientsList,
      };
    case Actions.LOAD_CLIENTS:
      return {
        ...state,
        loadClientsPending: true,
      };
    case Actions.LOAD_CLIENTS_SUCCESS:
      return {
        ...state,
        loadClientsPending: false,
        loadClientsError: null,
        clients: [
          ...state.clients,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.clients, 'id'),
        ],
        hasMoreClients: !!action.next,
        currClientsPage: action.page,
      };
    case Actions.LOAD_CLIENTS_ERROR:
      return {
        ...state,
        loadClientsPending: false,
        loadClientsError: action.error,
        hasMoreClients: false,
      };
    case Actions.CREATE_CLIENT:
    case Actions.UPDATE_CLIENT:
      return {
        ...state,
        changeClientPending: true,
      };
    case Actions.CREATE_CLIENT_SUCCESS:
    case Actions.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        currClient: action.client,
        changeClientPending: false,
        changeClientError: null,
        ...resetClientsList,
      };
    case Actions.CREATE_CLIENT_ERROR:
    case Actions.UPDATE_CLIENT_ERROR:
      return {
        ...state,
        changeClientPending: false,
        changeClientError: action.error,
      };
    case Actions.DELETE_CLIENT:
      return {
        ...state,
        deleteClientPending: true,
      };
    case Actions.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        deleteClientPending: false,
        deleteClientError: null,
        clients: _.filter(
          state.clients,
          (c) => c.id != action.id,
        ),
      };
    case Actions.DELETE_CLIENT_ERROR:
      return {
        ...state,
        deleteClientPending: false,
        deleteClientError: action.error,
      };
    case Actions.SET_EDIT_CLIENT:
      return {
        ...state,
        editClient: action.client,
      };
    case Actions.GET_EDIT_CLIENT:
      return {
        ...state,
        getEditClientPending: true,
      };
    case Actions.GET_EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        getEditClientPending: false,
        getEditClientError: null,
        editClient: action.client,
      };
    case Actions.GET_EDIT_CLIENT_ERROR:
      return {
        ...state,
        getEditClientPending: false,
        getEditClientError: action.error,
      };
    default:
      return state;
  }
};
