import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DropdownItem,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';

import {
  BackButton,
  CancelButton,
  GeneralErrors,
  KebabDropdown,
  PageHeader,
} from '../../components';
import { updateReport } from '../../redux/reports/actions';
import { useConfirmAction, useReadOnly } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import { PathogenMultiselect } from '../../components/inputs/PathogenMultiselect';

export default function BacteriaForm ({ currReport }) {
  const history = useHistory();
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const confirmAction = useConfirmAction();
  const {
    handleSubmit,
    formState,
    control,
  } = useForm({ defaultValues: currReport });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    dispatch(updateReport({
      ...data,
      bacteria: _.map(_.get(data, 'bacteria', []), 'id'),
      id: currReport.id,
    }));
  }, [currReport]);

  const removeBacteria = useCallback(() => {
    confirmAction({
      action: 'remove',
      subjectType: 'bacteria',
      subject: 'this detail',
      onConfirm: () => dispatch(updateReport({
        bacteria: [],
        id: currReport.id,
      })),
      pendingPath: 'reports.changeReportPending',
      errorPath: 'reports.changeReportError',
      onSuccess: history.goBack,

    });
  }, [currReport, confirmAction]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      history.goBack();
    }
  }, [submitSuccess]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Bacteria"
        coverMenu
      >
        <BackButton/>
        {
          (
            !readOnly &&
            _.get(currReport, 'bacteria.length', 0) > 0
          ) && (
            <KebabDropdown right>
              <DropdownItem onClick={removeBacteria}>
                <FontAwesomeIcon icon="trash" />
                Remove Bacteria
              </DropdownItem>
            </KebabDropdown>
          )
        }
      </PageHeader>
      <div className={layout.pageContent}>
        <div className={layout.responsiveContainer}>
          {!currReport ?
            <div className="text-center">
              <Spinner />
            </div>
            :
            <Form
              className={layout.responsiveContainer}
              onSubmit={handleSubmit(submit)}
            >
              <FormGroup>
                <Label>Bacteria</Label>
                <Controller
                  as={PathogenMultiselect}
                  name="bacteria"
                  placeholder="Select Bacteria"
                  control={control}
                  invalid={ isSubmitted && _.has(error, 'bacteria') }
                  filter="bac"
                />
                <FormFeedback role="alert">
                  { _.get(error, 'bacteria') }
                </FormFeedback>
              </FormGroup>
              { isSubmitted && <GeneralErrors error={error} /> }
              {!readOnly && (
                <Button
                  color="primary"
                  disabled={pending}
                  block
                >
                  Save{' '}
                  { pending && <Spinner size="sm" /> }
                </Button>
              )}
              <CancelButton />
            </Form>
          }
        </div>
      </div>
    </div>
  );
}
