import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
  CustomInput,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { createClient, updateClient } from '../../redux/clients/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { CancelButton, GeneralErrors } from '../../components';
import states from '../../utils/data/states';

import layout from '../../styles/layout.module.scss';

export default function ClientForm ({client, onSubmit}) {
  const currOrg = useSelector(getCurrOrg);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
  } = useForm({
    defaultValues: client || {},
  });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.clients.changeClientPending);
  const error = useSelector((s) => s.clients.changeClientError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    if (client) {
      dispatch(updateClient({ ...data, id: client.id }));
    } else {
      dispatch(createClient({ ...data, org: currOrg.slug }));
    }
  }, [client, currOrg]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Client Name *</Label>
          <Input
            required
            type="text"
            innerRef={register}
            name="name"
            invalid={ isSubmitted && _.has(error, 'name') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'name') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Owner</Label>
          <Input
            type="text"
            innerRef={register}
            name="owner"
            autoComplete="off"
            invalid={ isSubmitted && _.has(error, 'owner') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'owner') }
          </FormFeedback>
        </FormGroup>
        <h4 className="my-3">Address</h4>
        <FormGroup>
          <Label>Address Line 1</Label>
          <Input
            type="text"
            innerRef={register}
            name="address_line_1"
            invalid={ isSubmitted && _.has(error, 'address_line_1') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'address_line_1') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Address Line 2</Label>
          <Input
            type="text"
            innerRef={register}
            name="address_line_2"
            invalid={ isSubmitted && _.has(error, 'address_line_2') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'address_line_2') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>City</Label>
          <Input
            type="text"
            innerRef={register}
            name="address_city"
            invalid={ isSubmitted && _.has(error, 'address_city') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'address_city')}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="select">State</Label>
          <CustomInput
            id="select"
            innerRef={register}
            type="select"
            name="address_state"
            className="form-control"
          >
            {_.map(
              states,
              (state) => (
                <option
                  key={`state-${state.value}`}
                  value={state.value}
                >
                  {state.text}
                </option>
              ),
            )}
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label>Zip Code</Label>
          <Input
            type="text"
            innerRef={register}
            name="address_zip_code"
            invalid={ isSubmitted && _.has(error, 'address_zip_code') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'address_zip_code') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <Input
            type="text"
            innerRef={register}
            name="phone_number"
            invalid={ isSubmitted && _.has(error, 'phone_number') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'phone_number') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            innerRef={register}
            name="email"
            invalid={ isSubmitted && _.has(error, 'email') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'email') }
          </FormFeedback>
        </FormGroup>
        { isSubmitted && <GeneralErrors error={error} /> }
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Submit{' '}
          { pending && <Spinner size="sm" /> }
        </Button>
        <CancelButton />
      </Form>
    </div>
  );
}
ClientForm.propTypes = {
  client: PropTypes.object,
  onSubmit: PropTypes.func,
};
