import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getCurrProdCycle } from '../../redux/prodcycles/actions';
import ProdCycleForm from './ProdCycleForm';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';

export default function EditProdCycle () {
  const dispatch = useDispatch();
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currProdCycle = useSelector((s) => s.prodCycles.currProdCycle);
  const pending = useSelector((s) => s.prodCycles.getCurrProdCyclePending);
  const error = useSelector((s) => s.prodCycles.getCurrProdCycleError);

  // Load currProdCycle if not already
  useEffect(() => {
    if (currOrg && _.get(currProdCycle, 'id') != id) {
      dispatch(getCurrProdCycle(id));
      setStartedLoading(true);
    }
  }, [currOrg, currProdCycle]);

  // Failed to load prodCycle. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/water-bodies`);
    }
  }, [startedLoading, error, history]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Production Cycle"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { (!currProdCycle || pending)
        ?
        <div className="text-center">
          <Spinner />
        </div>
        :
        <ProdCycleForm
          onSubmit={history.goBack}
          prodCycle={currProdCycle}
        />
      }
    </div>
  );
}

