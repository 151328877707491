import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './TopNav.module.scss';
import layout from '../../styles/layout.module.scss';

const BaseTopNav = () => {
  return (
    <div className={styles.topNav}>
      <Link
        id="home-link"
        to="/app"
        className={styles.logoLink}
      >
        <img
          className={styles.logo}
          src="/ksu-logo.png"
          alt="Home"
        />
      </Link>
      <a
        tabIndex={0}
        className={cx('btn btn-primary', layout.skipMain)}
        style={{marginTop: '.6rem'}}
        href="#maincontent"
      >
        Skip to main content
      </a>
    </div>
  );
};

export const TopNav = React.memo(BaseTopNav);
