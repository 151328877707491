export const RESET_LOAD_REPORTS = 'RESET_LOAD_REPORTS';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export const LOAD_REPORTS_ERROR = 'LOAD_REPORTS_ERROR';
export const GET_CURR_REPORT = 'GET_CURR_REPORT';
export const GET_CURR_REPORT_SUCCESS = 'GET_CURR_REPORT_SUCCESS';
export const GET_CURR_REPORT_ERROR = 'GET_CURR_REPORT_ERROR';
export const CLEAR_CURR_REPORT = 'CLEAR_CURR_REPORT';
export const SET_STEP_TITLE = 'SET_STEP_TITLE';
export const CREATE_REPORT = 'CREATE_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const UPDATE_REPORT_ERROR = 'UPDATE_REPORT_ERROR';
export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR = 'DELETE_REPORT_ERROR';
export const RESET_LOAD_REPORT_MEDIA = 'RESET_LOAD_REPORT_MEDIA';
export const LOAD_REPORT_MEDIA = 'LOAD_REPORT_MEDIA';
export const LOAD_REPORT_MEDIA_SUCCESS = 'LOAD_REPORT_MEDIA_SUCCESS';
export const LOAD_REPORT_MEDIA_ERROR = 'LOAD_REPORT_MEDIA_ERROR';
export const CREATE_REPORT_MEDIA = 'CREATE_REPORT_MEDIA';
export const CREATE_REPORT_MEDIA_PROGRESS = 'CREATE_REPORT_MEDIA_PROGRESS';
export const CREATE_REPORT_MEDIA_SUCCESS = 'CREATE_REPORT_MEDIA_SUCCESS';
export const CREATE_REPORT_MEDIA_ERROR = 'CREATE_REPORT_MEDIA_ERROR';
export const DELETE_REPORT_MEDIA = 'DELETE_REPORT_MEDIA';
export const DELETE_REPORT_MEDIA_SUCCESS = 'DELETE_REPORT_MEDIA_SUCCESS';
export const DELETE_REPORT_MEDIA_ERROR = 'DELETE_REPORT_MEDIA_ERROR';
export const DELETE_REPORT_UPLOAD = 'DELETE_REPORT_UPLOAD';
export const CANCEL_REPORT_UPLOAD = 'CANCEL_REPORT_UPLOAD';
export const TOGGLE_REPORT_SORT = 'TOGGLE_REPORT_SORT';
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS';
export const UPDATE_REPORT_FILTERS = 'UPDATE_REPORT_FILTERS';

export const loadReports = ({ cycleId, slug, page, searchCtx }) => {
  return {
    type: LOAD_REPORTS,
    cycleId,
    slug,
    page,
    searchCtx,
  };
};

export const createReport = (formData) => {
  return {
    type: CREATE_REPORT,
    formData,
  };
};

export const updateReport = (formData) => {
  return {
    type: UPDATE_REPORT,
    formData,
  };
};

export const deleteReport = (id) => {
  return {
    type: DELETE_REPORT,
    id,
  };
};

export const getCurrReport = (id) => {
  return {
    type: GET_CURR_REPORT,
    id,
  };
};

export const setStepTitle = (title) => {
  return {
    type: SET_STEP_TITLE,
    title,
  };
};

export const loadReportMedia = (reportId, page) => {
  return {
    type: LOAD_REPORT_MEDIA,
    reportId,
    page,
  };
};

export const createReportMedia = (reportId, file) => {
  return {
    type: CREATE_REPORT_MEDIA,
    reportId,
    file,
  };
};

export const deleteReportMedia = (id) => {
  return {
    type: DELETE_REPORT_MEDIA,
    id,
  };
};

export const deleteReportUpload = (reportId, uploadId) => {
  return {
    type: DELETE_REPORT_UPLOAD,
    reportId,
    uploadId,
  };
};

export const cancelReportUpload = (reportId, uploadId) => {
  return {
    type: CANCEL_REPORT_UPLOAD,
    reportId,
    uploadId,
  };
};

export const updateReportFilters = (newValues) => {
  return {
    type: UPDATE_REPORT_FILTERS,
    newValues,
  };
};
