export default [
  { value: '', text: '-- Select location --' },
  { value: 'fins', text: 'Fins' },
  { value: 'head', text: 'Head' },
  { value: 'mouth', text: 'Mouth' },
  { value: 'cranial', text: 'Cranial Foramen' },
  { value: 'eyes', text: 'Eyes' },
  { value: 'peduncle', text: 'Peduncle' },
  { value: 'ventral', text: 'Ventral' },
  { value: 'dorsal', text: 'Dorsal' },
  { value: 'lateral', text: 'Lateral' },
  { value: 'anus', text: 'Anus' },
];
