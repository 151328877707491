import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BackButton, PageHeader } from '../../components';
import ClinicalSignForm from './ClinicalSignForm';

import layout from '../../styles/layout.module.scss';

export default function CreateClinicalSign () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currClinicalSign = useSelector((s) => s.samples.currClinicalSign);

  const gotoClinicalSigns = useCallback(() => {
    history.replace(`/app/${orgSlug}/samples/clinical-signs/${_.get(currClinicalSign, 'id')}`);
    history.goBack();
  }, [orgSlug, currClinicalSign]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Clinical Sign"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <ClinicalSignForm onSubmit={gotoClinicalSigns} />
    </div>
  );
}
