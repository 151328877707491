import React, { useContext } from 'react';
import { CustomInput } from 'reactstrap';

import { RadioGroupContext } from './RadioGroup';

export function Radio ({ value, label }) {
  const ctx = useContext(RadioGroupContext);

  return (
    <CustomInput
      id={`${ctx.name}-${value}`}
      type="radio"
      checked={value == ctx.value}
      value={value}
      label={label}
      onChange={ctx.onChange}
      disabled={ctx.disabled}
      inline
    />
  );
}
