import { useSelector } from 'react-redux';

import { getUser } from '../../redux/account/selectors';
import { getCurrOrg } from '../../redux/orgs/selectors';

export const useRole = () => {
  const user = useSelector(getUser);
  const currOrg = useSelector(getCurrOrg);

  if (user.is_superuser) {
    return 'super';
  }

  return _.get(currOrg, 'role', 'read');
};
