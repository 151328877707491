import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { onUserInteract } from '../../utils/helpers';

import styles from './ListCard.module.scss';

const BaseListCard = (props) => {
  const {
    className,
    linkTo,
    arrow,
    children,
    flex,
    tag,
    onClick,
    ...rest
  } = props;

  const interaction = {};
  if (onClick) {
    interaction.onClick = onClick;
    interaction.onKeyPress = onUserInteract(onClick);
  }

  const Tag = linkTo ? Link : (tag || 'button');

  return (
    <Tag
      className={cx(styles.listCard, className)}
      role="button"
      tabIndex={0}
      to={linkTo}
      {...interaction}
      {...rest}
    >
      <div className={cx(
        styles.content,
        {
          'd-flex': flex,
          'pr-3': arrow,
        },
      )}>
        {children}
      </div>
      { arrow && (
        <div className={styles.arrow}>
          <FontAwesomeIcon icon="angle-right" />
        </div>
      ) }
    </Tag>
  );
};
BaseListCard.propTypes = {
  linkTo: PropTypes.string,
  arrow: PropTypes.bool,
  flex: PropTypes.bool,
  tag: PropTypes.string,
};
BaseListCard.defaultProps = {
  arrow: true,
  flex: false,
};

export const ListCard = React.memo(BaseListCard);
