import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { setStepTitle } from '../../redux/reports/actions';
import { useReadOnly, useToggle } from '../../utils/hooks';
import { SubmitReportModal } from '../../modals';
import {
  CreateDrawer,
  CreateDrawerItem,
  ListCard,
} from '../../components';
import diagnosisDetails from '../../utils/data/final_diagnosis_items';

import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import cards from '../../components/lists/ListCard.module.scss';
import steps from './ReportSteps.module.scss';
import styles from './Report.module.scss';

export default function FinalDiagnosis () {
  const { orgSlug, id } = useParams();
  const submitReportModal = useToggle();
  const readOnly = useReadOnly();
  const history = useHistory();
  const dispatch = useDispatch();
  const currReport = useSelector((s) => s.reports.currReport);

  useEffect(() => {
    dispatch(setStepTitle('Final Diagnosis'));
  }, []);

  const exit = useCallback(() => {
    history.push(`/app/${orgSlug}/prod-cycles/${_.get(currReport, 'prod_cycle')}/reports`);
  }, [orgSlug, currReport]);

  const gotoItem = useCallback(
    (path) => history.push(`/app/${orgSlug}/reports/${id}/${path}`),
    [history, orgSlug, id],
  );

  const details = useMemo(() => _.filter(
    diagnosisDetails,
    // Include any detail that we find at least one truthy path for
    (d) => _.some(
      d.condPaths,
      (path) => _.get(currReport, path),
    ),
  ), [currReport]);

  return (
    <div className={cx(
      layout.responsiveContainer,
      layout.responsiveContainerButtonsBottom,
      layout.responsiveContainerMargin,
    )}>
      { !readOnly && (
        <CreateDrawer
          className={cx(
            steps.drawerSteps,
            styles.drawerMovedUp,
          )}
          drawerLabel="New Detail"
        >
          <CreateDrawerItem
            key="add-media"
            label="Add Media"
            icon="photo-video"
            onClick={() => gotoItem('media')}
          />
          <CreateDrawerItem
            key="add-notes"
            label="Add Note"
            icon="pencil-alt"
            onClick={() => gotoItem('notes')}
          />
          <CreateDrawerItem
            key="add-treatment"
            label="Add Potential Treatment"
            icon="file-medical"
            onClick={() => gotoItem('potential-treatment')}
          />
          <CreateDrawerItem
            key="add-parasite"
            label="Add Parasite"
            icon="bacterium"
            onClick={() => gotoItem('parasites')}
          />
          <CreateDrawerItem
            key="add-bacteria"
            label="Add Bacteria"
            icon="bacteria"
            onClick={() => gotoItem('bacteria')}
          />
          <CreateDrawerItem
            key="add-virus"
            label="Add Virus"
            icon="viruses"
            onClick={() => gotoItem('viruses')}
          />
          <CreateDrawerItem
            key="add-fungi"
            label="Add Fungi"
            icon="disease"
            onClick={() => gotoItem('fungi')}
          />
          <CreateDrawerItem
            key="add-detail"
            label="Add Misc. Detail"
            icon="ellipsis-h"
            onClick={() => gotoItem('misc-details')}
          />
        </CreateDrawer>
      )}
      <div className="mt-3">
        {details.length == 0
          ? (
            <p className="text-center my-3">
              Add diagnosis details, media, and notes below, then <br/> click Save to save the report.
            </p>
          )
          : (
            _.map(
              details,
              (d) => (
                <ListCard
                  key={`detail-${d.urlSuffix}`}
                  linkTo={`/app/${orgSlug}/reports/${id}/${d.urlSuffix}`}
                  flex
                >
                  <span className="flex-grow-1">
                    {d.text}
                  </span>
                  {d.subtext && (
                    <i className={cx(cards.detailSubtext, text.subtext, text.ellipsize)}>
                      {d.subtext(currReport)}
                    </i>
                  )}
                </ListCard>
              ),
            )
          )
        }
      </div>
      <div className={styles.buttonsBottom}>
        { !readOnly && !_.get(currReport, 'complete') && (
          <Button
            color="primary"
            onClick={_.get(currReport, 'complete') ? exit : submitReportModal.toggle}
            block
          >
            Mark as Complete
          </Button>
        )}
        <Link
          className="btn btn-secondary btn-block"
          to={`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`}
        >
          Exit
        </Link>
        <SubmitReportModal {...submitReportModal} />
      </div>
    </div>
  );
}
