import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'reactstrap';
import cx from 'classnames';
import _ from 'lodash';

import { useDebounce } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import styles from './SearchInput.module.scss';

export function SearchInput (props) {
  const {
    placeholder,
    onChange,
    className,
  } = props;

  const ignoreNext = useRef(true);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(_.trim(query), 500);

  useEffect(() => {
    if (ignoreNext.current) {
      ignoreNext.current = false;

      return;
    }

    // Sync query in case of preceding whitespace
    setQuery(debouncedQuery);

    if (onChange) {
      onChange(debouncedQuery);
    }
  }, [debouncedQuery, onChange]);

  const onClear = useCallback(() => {
    onChange(query);

    ignoreNext.current = true;
    setQuery('');
  }, [onChange]);

  return (
    <div className={cx(styles.search, layout.responsiveContainer, className)}>
      <Input
        className={styles.searchInput}
        placeholder={placeholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      { query && (
        <Button
          color="link"
          className={styles.clearBtn}
          onClick={onClear}
        >
          <FontAwesomeIcon icon="times" />
        </Button>
      )}
    </div>
  );

}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
};
SearchInput.defaultProps = {
  placeholder: 'Search',
};
