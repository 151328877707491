import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';

import { useRole, useConfirmAction } from '../../utils/hooks';
import { getUser } from '../../redux/account/selectors';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { removeUser, setEditUser } from '../../redux/users/actions';

import text from '../../styles/text.module.scss';
import tables from '../../styles/tables.module.scss';

export default function User ({ user }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const currOrg = useSelector(getCurrOrg);
  const isLoggedInUser = user.id == useSelector(getUser).id;
  const confirmAction = useConfirmAction();

  const onEditUser = useCallback(() => {
    dispatch(setEditUser(user));
    history.push(`/app/${currOrg.slug}/users/${user.id}/edit`);
  }, [currOrg, user, history]);

  const onRemoveUser = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'user',
      subject: user.name,
      onConfirm: () => dispatch(removeUser(
        currOrg.slug,
        user.id,
      )),
      pendingPath: 'users.removeUserPending',
      errorPath: 'users.removeUserError',
      onSuccess: () => {
        if (isLoggedInUser) {
          // No longer a member
          window.location.href = '/app';
        }
      },
    });
  }, [user, currOrg, confirmAction]);

  return (
    <tr>
      <td className={text.ellipsize}>
        { user.name }{ isLoggedInUser && ' (You)'}
      </td>
      <td className="text-center">
        { _.upperFirst(user.role) }
      </td>
      <td className={tables.actions}>
        { _.includes(['admin', 'super'], role) && (
          <React.Fragment>
            <Button
              color="link"
              title={`Edit role for ${user.name}`}
              onClick={onEditUser}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </Button>
            <Button
              color="link"
              title={`Remove ${user.name}`}
              onClick={onRemoveUser}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </React.Fragment>
        )}
      </td>
    </tr>
  );
}
