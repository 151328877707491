import React, { useCallback, useEffect, useRef } from 'react';
import videojs from 'video.js';
import cx from 'classnames';

import { NO_SWIPING } from '../lists/MediaCarousel';

import 'video.js/dist/video-js.css';
import styles from './VideoPlayer.module.scss';

export function VideoPlayer ({ mediaUrl, active }) {
  const playerContainerRef = useRef();
  const playerRef = useRef();

  const query = useCallback((selector) => {
    return playerContainerRef.current.querySelector(selector);
  }, []);

  useEffect(() => {
    if (!mediaUrl) {
      return;
    }

    const player = videojs(
      playerRef.current,
      {
        controls: true,
        fill: true,
      },
      () => {
        // Load media
        player.src(mediaUrl);

        // Disable swiper on slider controls
        player.ready(() => {
          const progressBar = query('.vjs-progress-control');
          if (progressBar) {
            progressBar.classList.add(NO_SWIPING);
          }
          const volume = query('.vjs-volume-level');
          if (volume) {
            volume.classList.add(NO_SWIPING);
          }
        });

        // Disable swiper entirely during fullscreen
        player.on('fullscreenchange', () => {
          if (player.isFullscreen()) {
            playerContainerRef.current.classList.add(NO_SWIPING);
          } else {
            playerContainerRef.current.classList.remove(NO_SWIPING);
          }
        });
      },
    );

    // Dispose player on dismount
    return () => player.dispose();
  }, [mediaUrl]);

  // Pause playback when not active
  useEffect(() => {
    if (playerRef.current) {
      const player = videojs(playerRef.current);

      if (active) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [active]);

  return (
    <div
      ref={playerContainerRef}
      data-vjs-player
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={playerRef}
        className={cx(styles.player, 'video-js vjs-default-skin')}
        playsInline
      />
    </div>
  );
}
