import React, { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import * as _ from 'lodash';

import styles from './ReportSteps.module.scss';

const STEP_URLS = [
  ['info', 'new-report'],
  ['fish-info'],
  ['water-quality'],
  ['samples'],
  ['final-diagnosis'],
];

export default function ReportSteps () {
  const location = useLocation();
  const { orgSlug, id } = useParams();
  const currStepIndex = useMemo(() => {
    return _.findIndex(
      STEP_URLS,
      (stepUrls) => _.some(
        stepUrls,
        (path) => _.endsWith(location.pathname, `/${path}`),
      ),
    );
  }, [location]);
  const maxStepIndex = useSelector((s) => {
    return _.get(s, 'reports.currReport.max_step_index', -1);
  });

  return (
    <div className={styles.steps}>
      {_.map(
        STEP_URLS,
        (urls, idx) => (
          <div
            key={`step-${idx + 1}`}
            className={styles.step}
          >
            <div className={styles.line} />
            <Link
              className={cx(
                styles.link,
                {
                  [styles.linkSelected]: idx == currStepIndex,
                  [styles.linkHidden]: idx > (maxStepIndex + 1),
                },
              )}
              to={`/app/${orgSlug}/reports/${id}/edit/${urls[0]}`}
            >
              <span>
                {idx + 1}
              </span>
            </Link>
          </div>
        ),
      )}
    </div>
  );
}
