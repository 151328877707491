import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  BackButton,
  ListCard,
  PageHeader,
} from '../../components';
import { useQuery, useWaterBodies } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import { subtext } from '../../styles/text.module.scss';

export default function SelectWaterBody () {
  const currOrg = useSelector(getCurrOrg);
  const { orgSlug, id: clientId } = useParams();
  const query = useQuery();
  const {
    waterBodies,
    hasMore,
    loading,
    noResults,
    loadMore,
    clearFilters,
  } = useWaterBodies(clientId);

  const getUrl = useCallback((body) => {
    if (body.num_prod_cycles == 0) {
      return undefined;
    } else if (query.get('create') == 'cycle') {
      return `/app/${orgSlug}/water-bodies/${body.id}/create-cycle`;
    } else {
      return `/app/${orgSlug}/water-bodies/${body.id}/select-cycle`;
    }
  }, [orgSlug, query]);

  useEffect(() => {
    // Clear filters on unmount
    return () => clearFilters();
  }, []);

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Select Water Body" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader
        title="Select Water Body"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <div className={layout.responsiveContainer}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!loading && hasMore}
            useWindow={false}
            className="d-flex flex-column"
          >
            {
              noResults
                ? (
                  <p key="zero" className="text-center mt-3">
                    You don&apos;t have any water bodies for this organization yet.
                  </p>
                )
                : _.map(waterBodies, (body) =>
                  <ListCard
                    key={`body-${body.id}`}
                    linkTo={getUrl(body)}
                    className={cx({ disabled: body.num_prod_cycles == 0 })}
                  >
                    { body.name }
                    <div className={subtext}>
                      <b>{body.num_prod_cycles}</b> production cycle{body.num_prod_cycles != 1 && 's'}
                    </div>
                  </ListCard>,
                )
            }
            { loading && (
              <div
                className="text-center"
                key="spinner"
              >
                <Spinner className={cx(tables.spinner, 'mt-3')}  />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
