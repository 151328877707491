import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

// import OrgDashboard from './OrgDashboard';
import EditOrg from './EditOrg';
import SearchIndex from '../search/SearchIndex';
import UsersIndex from '../users/UsersIndex';
import ClientsIndex from '../clients/ClientsIndex';
import WaterBodiesIndex from '../waterbodies/WaterBodiesIndex';
import ProdCyclesIndex from '../prodcycles/ProdCyclesIndex';
import ActivitiesIndex from '../activities/ActivitiesIndex';
import ReportsIndex from '../reports/ReportsIndex';
import SamplesIndex from '../samples/SamplesIndex';
import SelectClient from '../clients/SelectClient';
import NotFound from '../NotFound';

export default function OrgIndex () {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Switch>
      <Route exact path={path}>
        {/* <OrgDashboard /> */}
        <Redirect to={`${location.pathname}/clients`} />
      </Route>
      <Route path={`${path}/edit`}>
        <EditOrg />
      </Route>
      <Route path={`${path}/prod-cycles`}>
        <ProdCyclesIndex />
      </Route>
      <Route path={`${path}/activities`}>
        <ActivitiesIndex />
      </Route>
      <Route path={`${path}/reports`}>
        <ReportsIndex />
      </Route>
      <Route path={`${path}/samples`}>
        <SamplesIndex />
      </Route>
      <Route path={`${path}/clients`}>
        <ClientsIndex />
      </Route>
      <Route path={`${path}/select-client`}>
        <SelectClient />
      </Route>
      <Route path={`${path}/water-bodies`}>
        <WaterBodiesIndex />
      </Route>
      <Route path={`${path}/users`}>
        <UsersIndex />
      </Route>
      <Route path={`${path}/search`}>
        <SearchIndex />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
