import {
  all,
  call,
  put,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  LOGOUT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_DONE,
  RESET_PASSWORD,
  RESET_PASSWORD_DONE,
} from './actions';
import api from '../../utils/api';
import { getUser } from '../account/selectors';
import { minDelayCall } from '../helpers';

export const TOKEN_COOKIE = '_ksu_token';

export function* signup (action) {
  const { formData } = action;

  try {
    const response = yield minDelayCall(api.signup, formData);

    // Persist token for future page loads
    new Cookies().set(TOKEN_COOKIE, response.key, { path: '/' });

    yield put({ type: SIGNUP_SUCCESS, ...response });
  } catch (error) {
    yield put({ type: SIGNUP_ERROR, error: _.get(error, 'response.data')});
  }
}

export function* login (action) {
  const { formData } = action;

  try {
    const response = yield minDelayCall(api.login, formData);

    // Persist token for future page loads
    new Cookies().set(TOKEN_COOKIE, response.key, { path: '/' });
    yield put({ type: LOGIN_SUCCESS, ...response });
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error: _.get(error, 'response.data')});
  }
}

function* loadUser (token) {
  // Set user loading
  yield put({ type: LOAD_USER });

  try {
    const user = yield minDelayCall(api.loadUser, token);

    yield put({ type: LOAD_USER_SUCCESS, user, token});
  } catch (error) {
    if (_.has(error, 'response')) {
      // Token is bogus. Remove it.
      new Cookies().remove(TOKEN_COOKIE, { path: '/' });
    }

    yield put({ type: LOAD_USER_ERROR });
  }
}

function* editUser ({ formData }) {
  try {
    const user = yield minDelayCall(api.editUser, formData);

    yield put({ type: EDIT_USER_SUCCESS, user });
  } catch (error) {
    yield put({ type: EDIT_USER_ERROR, error: _.get(error, 'response.data') });
  }
}

// Finite state machine for user session
export function* loginFlow () {
  while(true) {
    // Check for existing token
    const token = new Cookies().get(TOKEN_COOKIE);

    if (token) {
      // Load the user if we found a token
      yield call(loadUser, token);
    }

    if (!(yield select(getUser))) {
      // Wait for login / signup if we don't have a user
      const action = yield take([LOGIN, SIGNUP]);
      if (action.type === LOGIN) {
        yield call(login, action);
      } else {
        yield call(signup, action);
      }
    }

    if (yield select(getUser)) {
      // Once we have a user, configure axios to always use token
      const newToken = yield select((store) => store.account.token);
      axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;

      // Wait for logout
      yield take(LOGOUT);
      axios.defaults.headers.common = {};
      new Cookies().remove(TOKEN_COOKIE, { path: '/' });
    }
  }
}

export function* forgotPassword (action) {
  const { email } = action;

  let error = null;

  try {
    yield minDelayCall(api.forgotPassword, email);
  } catch (e) {
    error = _.get(e, 'response.data');
  } finally {
    yield put({ type: FORGOT_PASSWORD_DONE, error });
  }
}

export function* resetPassword (action) {
  const { data } = action;

  let error = null;

  try {
    yield minDelayCall(api.resetPassword, data);
  } catch (e) {
    error = _.get(e, 'response.data');
  } finally {
    yield put({ type: RESET_PASSWORD_DONE, error });
  }
}

export default function* rootSaga () {
  yield all([
    loginFlow(),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(EDIT_USER, editUser),
  ]);
}
