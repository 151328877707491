import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import cx from 'classnames';

import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import { PageHeader } from '../../components';
import { action } from '../../redux/helpers';
import { getUser } from '../../redux/account/selectors';
import { LOGOUT } from '../../redux/account/actions';
import { acceptInvite } from '../../redux/orgs/actions';
import { getInvite } from '../../redux/orgs/selectors';
import { validateInvite } from '../../redux/orgs/actions';

export default function AcceptInvite () {
  const dispatch = useDispatch();
  const { key } = useParams();
  const user = useSelector(getUser);
  const invite = useSelector(getInvite);
  const invitePending = useSelector((s) => s.orgs.invitePending);
  const inviteError = useSelector((s) => s.orgs.inviteError);

  // Only validate on mount
  useEffect(() => {
    dispatch(validateInvite(key));
  }, []);

  const accept = useCallback(() => {
    dispatch(acceptInvite(
      key,
      (org) => window.location.href = `/app/${org.slug}`,
    ));
  }, [dispatch]);

  const logout = useCallback(() => dispatch(action(LOGOUT)), []);

  return (
    <div className={layout.page}>
      <PageHeader title="Accept Invite" />
      <div className={layout.pageContent}>
        <div className={cx('text-center', layout.responsiveContainer)}>
          <p>
            You&apos;re logged in as <b>{user.email}</b>.
            <Button
              color="link"
              className={text.inlineLink}
              onClick={logout}
            >
              Not you?
            </Button>
          </p>
          { !invite && invitePending && <Spinner />}
          {
            inviteError &&
            <p className="text-danger">
              {inviteError.invite}
            </p>
          }
          {
            invite &&
            <Button
              color="primary"
              onClick={accept}
              disabled={user.is_superuser}
            >
              Join {invite.org.name + ' '}
              { invitePending && <Spinner size="sm" /> }
            </Button>
          }
          {
            user.is_superuser &&
            <p className="text-danger mt-3">
              Superusers don&apos;t need to join organizations.
            </p>
          }
        </div>
      </div>
    </div>
  );
}
