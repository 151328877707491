import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import cx from 'classnames';

import { createSample, updateSample } from '../../redux/samples/actions';
import {
  CancelButton,
  Checkbox,
  GeneralErrors,
  ListCard,
  MeasurementInput,
  PathogenMultiselect,
  SpecieSelect,
} from '../../components';
import { useReadOnly } from '../../utils/hooks';
import { Measurements } from '../../components/inputs/Measurements';
import captureConditions from '../../utils/data/capture_conditions';
import sampleConditions from '../../utils/data/sample_conditions';

import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import cards from '../../components/lists/ListCard.module.scss';
import { usePrevious } from '../../utils/hooks';

export default function SampleForm ({sample, onSubmit}) {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const { orgSlug, id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    control,
    getValues,
    setValue,
    watch,
  } = useForm({ defaultValues: sample || {} });
  const prevSample = usePrevious(sample);

  const {
    bacteria_sample_taken,
    virus_sample_taken,
  } = watch(['bacteria_sample_taken', 'virus_sample_taken']);

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.samples.changeSamplePending);
  const error = useSelector((s) => s.samples.changeSampleError);
  const submitSuccess = isSubmitted && !pending && !error;

  useEffect(() => {
    if (!bacteria_sample_taken) {
      setValue('bacteria', []);
    }
  }, [bacteria_sample_taken]);

  useEffect(() => {
    if (!virus_sample_taken) {
      setValue('viruses', []);
    }
  }, [virus_sample_taken]);

  const submit = useCallback((data) => {
    const newValues = {
      ...data,
      specie: _.get(data, 'specie.id'),
      parasites: _.map(_.get(data, 'parasites', []), 'id'),
      fungi: _.map(_.get(data, 'fungi', []), 'id'),
      bacteria: _.map(_.get(data, 'bacteria', []), 'id'),
      viruses: _.map(_.get(data, 'viruses', []), 'id'),
    };

    if (sample) {
      dispatch(updateSample({
        ...newValues,
        id: sample.id,
      }));
    } else {
      dispatch(createSample({
        ...newValues,
        report: Number(id),
      }));
    }
  }, [sample, id]);

  const saveWork = useCallback(() => {
    submit(getValues());
  }, [submit, getValues]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();

      // Create success. Scroll to bottom
      if (!prevSample) {
        setTimeout(() => {
          const content = document.querySelector(`.${layout.pageContent}`);
          content.scrollTo({
            top: content.scrollHeight,
            behavior: 'smooth',
          });
        });
      }
    }
  }, [submitSuccess, onSubmit, prevSample]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Fish ID *</Label>
          <Input
            type="text"
            innerRef={register}
            name="fish_id"
            invalid={ isSubmitted && _.has(error, 'fish_id') }
            autoComplete="off"
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'fish_id') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Species</Label>
          <Controller
            as={SpecieSelect}
            name="specie"
            control={control}
            invalid={ isSubmitted && _.has(error, 'specie') }
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'specie') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="capture_cond">Capture Condition</Label>
          <CustomInput
            id="capture_cond"
            innerRef={register}
            type="select"
            name="capture_cond"
            className="form-control"
            disabled={readOnly}
          >
            {_.map(
              captureConditions,
              (cond) => (
                <option
                  key={`capture-cond-${cond.value}`}
                  value={cond.value}
                >
                  {cond.text}
                </option>
              ),
            )}
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="sample_cond">Sample Condition</Label>
          <CustomInput
            id="sample_cond"
            innerRef={register}
            type="select"
            name="sample_cond"
            className="form-control"
            disabled={readOnly}
          >
            {_.map(
              sampleConditions,
              (cond) => (
                <option
                  key={`sample-cond-${cond.value}`}
                  value={cond.value}
                >
                  {cond.text}
                </option>
              ),
            )}
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label>Length</Label>
          <Controller
            as={MeasurementInput}
            type={Measurements.DISTANCE}
            unitContext="fishLength"
            name="length"
            control={control}
            invalid={ isSubmitted && _.has(error, 'length') }
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'length') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Parasites</Label>
          <Controller
            as={PathogenMultiselect}
            name="parasites"
            placeholder="Select Parasites"
            control={control}
            invalid={ isSubmitted && _.has(error, 'parasites') }
            disabled={readOnly}
            filter={
              [
                'apicomp', 'acanth', 'cestodes', 'crust',
                'micro', 'monodig', 'myxo', 'nema', 'pro', 'other',
              ]
            }
          />
          <FormFeedback role="alert">
            { _.get(error, 'parasites') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Fungi</Label>
          <Controller
            as={PathogenMultiselect}
            name="fungi"
            placeholder="Select Fungi"
            control={control}
            invalid={ isSubmitted && _.has(error, 'fungi') }
            filter="fungi"
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'fungi') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Controller
            as={Checkbox}
            name="bacteria_sample_taken"
            control={control}
            label="Bacteria sample taken"
            disabled={readOnly}
          />
        </FormGroup>
        <FormGroup style={{display: bacteria_sample_taken ? 'block': 'none'}}>
          <Label>Bacteria</Label>
          <Controller
            as={PathogenMultiselect}
            name="bacteria"
            placeholder="Select Bacteria"
            control={control}
            invalid={ isSubmitted && _.has(error, 'bacteria') }
            filter="bac"
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'bacteria') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Controller
            as={Checkbox}
            name="virus_sample_taken"
            control={control}
            label="Virus sample taken"
            disabled={readOnly}
          />
        </FormGroup>
        <FormGroup style={{display: virus_sample_taken ? 'block': 'none'}}>
          <Label>Viruses</Label>
          <Controller
            as={PathogenMultiselect}
            name="viruses"
            placeholder="Select Virus"
            control={control}
            invalid={ isSubmitted && _.has(error, 'viruses') }
            filter="virus"
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'viruses') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Notes</Label>
          <Input
            innerRef={register}
            type="textarea"
            name="notes"
            invalid={ isSubmitted && _.has(error, 'notes') }
            disabled={readOnly}
          />
          <FormFeedback role="alert">
            { _.get(error, 'notes') }
          </FormFeedback>
        </FormGroup>
        {sample && (
          <React.Fragment>
            <h4>More Details</h4>
            <ListCard
              linkTo={`/app/${orgSlug}/samples/${id}/media`}
              onClick={saveWork}
              flex
            >
              <span className="flex-grow-1">
                Media
              </span>
              <i className={cx(cards.detailSubtext, text.subtext, text.ellipsize)}>
                {sample.num_media} file{sample.num_media != 1 && 's'}
              </i>
            </ListCard>
            <ListCard
              linkTo={`/app/${orgSlug}/samples/${id}/bacteriology`}
              onClick={saveWork}
              flex
            >
              <span className="flex-grow-1">
                Bacteriology
              </span>
              <i className={cx(cards.detailSubtext, text.subtext, text.ellipsize)}>
                {sample.num_isolates} isolate{sample.num_isolates != 1 && 's'}
              </i>
            </ListCard>
            <ListCard
              linkTo={`/app/${orgSlug}/samples/${id}/clinical-signs`}
              onClick={saveWork}
              flex
            >
              <span className="flex-grow-1">
                Clinical Signs
              </span>
              <i className={cx(cards.detailSubtext, text.subtext, text.ellipsize)}>
                {_.join(
                  _.map(sample.clinical_signs, 'display_name'),
                  ', ',
                )}
              </i>
            </ListCard>
          </React.Fragment>
        )}
        { isSubmitted && <GeneralErrors error={error} /> }
        {!readOnly && (
          <Button
            color="primary"
            disabled={pending}
            block
          >
            {sample ? 'Save and Exit' : 'Save and Continue'}
            { pending && <Spinner size="sm" /> }
          </Button>
        )}
        <CancelButton />
      </Form>
    </div>
  );
}
SampleForm.propTypes = {
  sample: PropTypes.object,
  onSubmit: PropTypes.func,
};
