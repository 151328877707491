import { useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCurrOrg } from '../../redux/orgs/selectors';

export const useOrgSlug = () => {
  const location = useLocation();
  const { params } = matchPath(location.pathname, '/app/:orgSlug?');
  const currOrg = useSelector(getCurrOrg);

  let orgSlug = _.get(params, 'orgSlug');
  orgSlug = !_.includes(['profile', 'accept', 'create-org'], orgSlug) && orgSlug;

  return _.get(currOrg, 'slug') || orgSlug;
};
