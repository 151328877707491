import * as _ from 'lodash';

import * as Actions from './actions';

const resetWaterBodiesList = {
  waterBodies: [],
  hasMoreWaterBodies: true,
  currWaterBodiesPage: 0,
};

export const initialState = {
  ...resetWaterBodiesList,
  hasMoreWaterBodies: false,
  searchCtx: null,
  waterBodyFilters: {
    client: null,
    waterbody_type: '',
    material: '',
    location: '',
  },
  currWaterBody: null,
  getCurrWaterBodyPending: false,
  getCurrWaterBodyError: null,
  loadWaterBodiesPending: false,
  loadWaterBodiesError: null,
  changeWaterBodyPending: false,
  changeWaterBodyError: null,
  deleteWaterBodyPending: false,
  deleteWaterBodyError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_WATERBODIES: {
      let waterBodyFilters = state.waterBodyFilters;
      if (action.client) {
        waterBodyFilters = {
          ...initialState.waterBodyFilters,
          client: action.client,
        };
      }

      return {
        ...state,
        ...resetWaterBodiesList,
        waterBodyFilters,
      };
    }
    case Actions.LOAD_WATERBODIES:
      return {
        ...state,
        loadWaterBodiesPending: true,
      };
    case Actions.LOAD_WATERBODIES_SUCCESS:
      return {
        ...state,
        loadWaterBodiesPending: false,
        loadWaterBodiesError: null,
        waterBodies: [
          ...state.waterBodies,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.waterBodies, 'id'),
        ],
        hasMoreWaterBodies: !!action.next,
        currWaterBodiesPage: action.page,
        searchCtx: action.searchCtx,
      };
    case Actions.LOAD_WATERBODIES_ERROR:
      return {
        ...state,
        loadWaterBodiesPending: false,
        loadWaterBodiesError: action.error,
        hasMoreWaterBodies: false,
      };
    case Actions.CREATE_WATERBODY:
    case Actions.UPDATE_WATERBODY:
      return {
        ...state,
        changeWaterBodyPending: true,
      };
    case Actions.CREATE_WATERBODY_SUCCESS:
    case Actions.UPDATE_WATERBODY_SUCCESS:
      return {
        ...state,
        currWaterBody: action.waterBody,
        changeWaterBodyPending: false,
        changeWaterBodyError: null,
        ...resetWaterBodiesList,
      };
    case Actions.CREATE_WATERBODY_ERROR:
    case Actions.UPDATE_WATERBODY_ERROR:
      return {
        ...state,
        changeWaterBodyPending: false,
        changeWaterBodyError: action.error,
      };
    case Actions.DELETE_WATERBODY:
      return {
        ...state,
        deleteWaterBodyPending: true,
      };
    case Actions.DELETE_WATERBODY_SUCCESS:
      return {
        ...state,
        deleteWaterBodyPending: false,
        deleteWaterBodyError: null,
        ...resetWaterBodiesList,
        waterBodies: _.filter(
          state.waterBodies,
          (w) => w.id != action.id,
        ),
      };
    case Actions.DELETE_WATERBODY_ERROR:
      return {
        ...state,
        deleteWaterBodyPending: false,
        deleteWaterBodyError: action.error,
      };
    case Actions.SET_CURR_WATERBODY:
      return {
        ...state,
        currWaterBody: action.waterBody,
      };
    case Actions.GET_CURR_WATERBODY:
      return {
        ...state,
        getCurrWaterBodyPending: true,
      };
    case Actions.GET_CURR_WATERBODY_SUCCESS:
      return {
        ...state,
        getCurrWaterBodyPending: false,
        getCurrWaterBodyError: null,
        currWaterBody: action.waterBody,
      };
    case Actions.GET_CURR_WATERBODY_ERROR:
      return {
        ...state,
        c: false,
        getCurrWaterBodyError: action.error,
      };
    case Actions.CLEAR_WATERBODY_FILTERS:
      return {
        ...state,
        waterBodyFilters: initialState.waterBodyFilters,
      };
    case Actions.UPDATE_WATERBODY_FILTERS:
      return {
        ...state,
        waterBodyFilters: {
          ...state.waterBodyFilters,
          ...action.newValues,
        },
      };
    default:
      return state;
  }
};
