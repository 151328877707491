import * as Actions from './actions';

const resetUsersList = {
  users: [],
  invites: [],
  hasMoreUsers: true,
  currUsersPage: 0,
};

export const initialState = {
  ...resetUsersList,
  hasMoreUsers: false,
  loadUsersPending: false,
  loadUsersError: null,
  changeUserPending: false,
  changeUserError: null,
  inviteStatus: {},
  deleteInvitePending: false,
  deleteInviteError: null,
  removeUserPending: false,
  removeUserError: null,
  getEditUserPending: false,
  getEditUserError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_USERS:
      return {
        ...state,
        ...resetUsersList,
      };
    case Actions.LOAD_USERS:
      return {
        ...state,
        loadUsersPending: true,
      };
    case Actions.LOAD_USERS_SUCCESS: {
      const { users, invites } = action;

      return {
        ...state,
        loadUsersPending: false,
        loadUsersError: null,
        hasMoreUsers: !!users.next,
        currUsersPage: users.page,
        users: [
          ...state.users,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(users.results, state.users, 'id'),
        ],
        invites: invites !== null ? invites : state.invites,
      };
    }
    case Actions.LOAD_USERS_ERROR:
      return {
        ...state,
        loadUsersPending: false,
        loadUsersError: action.error,
        hasMoreUsers: false,
      };
    case Actions.SET_USER_ROLE:
    case Actions.INVITE_USER:
      return {
        ...state,
        changeUserPending: true,
        changeUserError: null,
      };
    case Actions.SET_USER_ROLE_SUCCESS:
    case Actions.INVITE_USER_SUCCESS:
      return {
        ...state,
        changeUserPending: false,
      };
    case Actions.SET_USER_ROLE_ERROR:
    case Actions.INVITE_USER_ERROR:
      return {
        ...state,
        changeUserPending: false,
        changeUserError: action.error,
      };
    case Actions.RESEND_INVITE:
      return {
        ...state,
        inviteStatus: {
          ...state.inviteStatus,
          [action.id]: { pending: true },
        },
      };
    case Actions.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        inviteStatus: {
          ...state.inviteStatus,
          [action.id]: { pending: false },
        },
      };
    case Actions.RESEND_INVITE_ERROR:
      return {
        ...state,
        inviteStatus: {
          ...state.inviteStatus,
          [action.id]: {
            pending: false,
            error: action.error,
          },
        },
      };
    case Actions.DELETE_INVITE:
      return {
        ...state,
        deleteInvitePending: true,
      };
    case Actions.DELETE_INVITE_SUCCESS:
      return {
        ...state,
        deleteInvitePending: false,
        deleteInviteError: null,
        invites: _.filter(
          state.invites,
          (i) => i.id != action.id,
        ),
      };
    case Actions.DELETE_INVITE_ERROR:
      return {
        ...state,
        deleteInvitePending: false,
        deleteInviteError: action.error,
      };
    case Actions.REMOVE_USER:
      return {
        ...state,
        removeUserPending: true,
      };
    case Actions.REMOVE_USER_SUCCESS:
      return {
        ...state,
        removeUserPending: false,
        removeUserError: null,
        users: _.filter(
          state.users,
          (u) => u.id != action.id,
        ),
      };
    case Actions.REMOVE_USER_ERROR:
      return {
        ...state,
        removeUserPending: false,
        removeUserError: action.error,
      };
    case Actions.SET_EDIT_USER:
      return {
        ...state,
        editUser: action.user,
      };
    case Actions.GET_EDIT_USER:
      return {
        ...state,
        getEditUserPending: true,
      };
    case Actions.GET_EDIT_USER_SUCCESS:
      return {
        ...state,
        getEditUserPending: false,
        getEditUserError: null,
        editUser: action.user,
      };
    case Actions.GET_EDIT_USER_ERROR:
      return {
        ...state,
        getEditUserPending: false,
        getEditUserError: action.error,
      };
    default:
      return state;
  }
};
