export const RESET_LOAD_SAMPLES = 'RESET_LOAD_SAMPLES';
export const LOAD_SAMPLES = 'LOAD_SAMPLES';
export const LOAD_SAMPLES_SUCCESS = 'LOAD_SAMPLES_SUCCESS';
export const LOAD_SAMPLES_ERROR = 'LOAD_SAMPLES_ERROR';
export const GET_CURR_SAMPLE = 'GET_CURR_SAMPLE';
export const GET_CURR_SAMPLE_SUCCESS = 'GET_CURR_SAMPLE_SUCCESS';
export const GET_CURR_SAMPLE_ERROR = 'GET_CURR_SAMPLE_ERROR';
export const CLEAR_CURR_SAMPLE = 'CLEAR_CURR_SAMPLE';
export const CREATE_SAMPLE = 'CREATE_SAMPLE';
export const UPDATE_SAMPLE = 'UPDATE_SAMPLE';
export const CREATE_SAMPLE_SUCCESS = 'CREATE_SAMPLE_SUCCESS';
export const UPDATE_SAMPLE_SUCCESS = 'UPDATE_SAMPLE_SUCCESS';
export const CREATE_SAMPLE_ERROR = 'CREATE_SAMPLE_ERROR';
export const UPDATE_SAMPLE_ERROR = 'UPDATE_SAMPLE_ERROR';
export const DELETE_SAMPLE = 'DELETE_SAMPLE';
export const DELETE_SAMPLE_SUCCESS = 'DELETE_SAMPLE_SUCCESS';
export const DELETE_SAMPLE_ERROR = 'DELETE_SAMPLE_ERROR';
export const RESET_LOAD_SAMPLE_MEDIA = 'RESET_LOAD_SAMPLE_MEDIA';
export const LOAD_SAMPLE_MEDIA = 'LOAD_SAMPLE_MEDIA';
export const LOAD_SAMPLE_MEDIA_SUCCESS = 'LOAD_SAMPLE_MEDIA_SUCCESS';
export const LOAD_SAMPLE_MEDIA_ERROR = 'LOAD_SAMPLE_MEDIA_ERROR';
export const CREATE_SAMPLE_MEDIA = 'CREATE_SAMPLE_MEDIA';
export const CREATE_SAMPLE_MEDIA_PROGRESS = 'CREATE_SAMPLE_MEDIA_PROGRESS';
export const CREATE_SAMPLE_MEDIA_SUCCESS = 'CREATE_SAMPLE_MEDIA_SUCCESS';
export const CREATE_SAMPLE_MEDIA_ERROR = 'CREATE_SAMPLE_MEDIA_ERROR';
export const DELETE_SAMPLE_MEDIA = 'DELETE_SAMPLE_MEDIA';
export const DELETE_SAMPLE_MEDIA_SUCCESS = 'DELETE_SAMPLE_MEDIA_SUCCESS';
export const DELETE_SAMPLE_MEDIA_ERROR = 'DELETE_SAMPLE_MEDIA_ERROR';
export const DELETE_SAMPLE_UPLOAD = 'DELETE_SAMPLE_UPLOAD';
export const CANCEL_SAMPLE_UPLOAD = 'CANCEL_SAMPLE_UPLOAD';
export const RESET_LOAD_ISOLATES = 'RESET_LOAD_ISOLATES';
export const LOAD_ISOLATES = 'LOAD_ISOLATES';
export const LOAD_ISOLATES_SUCCESS = 'LOAD_ISOLATES_SUCCESS';
export const LOAD_ISOLATES_ERROR = 'LOAD_ISOLATES_ERROR';
export const CREATE_ISOLATE = 'CREATE_ISOLATE';
export const UPDATE_ISOLATE = 'UPDATE_ISOLATE';
export const DELETE_ISOLATE = 'DELETE_ISOLATE';
export const CREATE_ISOLATE_SUCCESS = 'CREATE_ISOLATE_SUCCESS';
export const UPDATE_ISOLATE_SUCCESS = 'UPDATE_ISOLATE_SUCCESS';
export const DELETE_ISOLATE_SUCCESS = 'DELETE_ISOLATE_SUCCESS';
export const CREATE_ISOLATE_ERROR = 'CREATE_ISOLATE_ERROR';
export const UPDATE_ISOLATE_ERROR = 'UPDATE_ISOLATE_ERROR';
export const DELETE_ISOLATE_ERROR = 'DELETE_ISOLATE_ERROR';
export const GET_CURR_ISOLATE = 'GET_CURR_ISOLATE';
export const GET_CURR_ISOLATE_SUCCESS = 'GET_CURR_ISOLATE_SUCCESS';
export const GET_CURR_ISOLATE_ERROR = 'GET_CURR_ISOLATE_ERROR';
export const RESET_LOAD_CLINICAL_SIGNS = 'RESET_LOAD_CLINICAL_SIGNS';
export const LOAD_CLINICAL_SIGNS = 'LOAD_CLINICAL_SIGNS';
export const LOAD_CLINICAL_SIGNS_SUCCESS = 'LOAD_CLINICAL_SIGNS_SUCCESS';
export const LOAD_CLINICAL_SIGNS_ERROR = 'LOAD_CLINICAL_SIGNS_ERROR';
export const CREATE_CLINICAL_SIGN = 'CREATE_CLINICAL_SIGN';
export const UPDATE_CLINICAL_SIGN = 'UPDATE_CLINICAL_SIGN';
export const DELETE_CLINICAL_SIGN = 'DELETE_CLINICAL_SIGN';
export const CREATE_CLINICAL_SIGN_SUCCESS = 'CREATE_CLINICAL_SIGN_SUCCESS';
export const UPDATE_CLINICAL_SIGN_SUCCESS = 'UPDATE_CLINICAL_SIGN_SUCCESS';
export const DELETE_CLINICAL_SIGN_SUCCESS = 'DELETE_CLINICAL_SIGN_SUCCESS';
export const CREATE_CLINICAL_SIGN_ERROR = 'CREATE_CLINICAL_SIGN_ERROR';
export const UPDATE_CLINICAL_SIGN_ERROR = 'UPDATE_CLINICAL_SIGN_ERROR';
export const DELETE_CLINICAL_SIGN_ERROR = 'DELETE_CLINICAL_SIGN_ERROR';
export const GET_CURR_CLINICAL_SIGN = 'GET_CURR_CLINICAL_SIGN';
export const GET_CURR_CLINICAL_SIGN_SUCCESS = 'GET_CURR_CLINICAL_SIGN_SUCCESS';
export const GET_CURR_CLINICAL_SIGN_ERROR = 'GET_CURR_CLINICAL_SIGN_ERROR';

export const loadSamples = (id, page) => {
  return {
    type: LOAD_SAMPLES,
    id,
    page,
  };
};

export const createSample = (formData) => {
  return {
    type: CREATE_SAMPLE,
    formData,
  };
};

export const updateSample = (formData) => {
  return {
    type: UPDATE_SAMPLE,
    formData,
  };
};

export const deleteSample = (id) => {
  return {
    type: DELETE_SAMPLE,
    id,
  };
};

export const getCurrSample = (id) => {
  return {
    type: GET_CURR_SAMPLE,
    id,
  };
};

export const loadSampleMedia = (sampleId, page) => {
  return {
    type: LOAD_SAMPLE_MEDIA,
    sampleId,
    page,
  };
};

export const createSampleMedia = (sampleId, file) => {
  return {
    type: CREATE_SAMPLE_MEDIA,
    sampleId,
    file,
  };
};

export const deleteSampleMedia = (id) => {
  return {
    type: DELETE_SAMPLE_MEDIA,
    id,
  };
};

export const deleteSampleUpload = (sampleId, uploadId) => {
  return {
    type: DELETE_SAMPLE_UPLOAD,
    sampleId,
    uploadId,
  };
};

export const cancelSampleUpload = (sampleId, uploadId) => {
  return {
    type: CANCEL_SAMPLE_UPLOAD,
    sampleId,
    uploadId,
  };
};

export const loadIsolates = (id, page) => {
  return {
    type: LOAD_ISOLATES,
    id,
    page,
  };
};

export const createIsolate = (formData) => {
  return {
    type: CREATE_ISOLATE,
    formData,
  };
};

export const updateIsolate = (formData) => {
  return {
    type: UPDATE_ISOLATE,
    formData,
  };
};

export const deleteIsolate = (id) => {
  return {
    type: DELETE_ISOLATE,
    id,
  };
};

export const getCurrIsolate = (id) => {
  return {
    type: GET_CURR_ISOLATE,
    id,
  };
};

export const loadClinicalSigns = (id, page) => {
  return {
    type: LOAD_CLINICAL_SIGNS,
    id,
    page,
  };
};

export const createClinicalSign = (formData) => {
  return {
    type: CREATE_CLINICAL_SIGN,
    formData,
  };
};

export const updateClinicalSign = (formData) => {
  return {
    type: UPDATE_CLINICAL_SIGN,
    formData,
  };
};

export const deleteClinicalSign = (id) => {
  return {
    type: DELETE_CLINICAL_SIGN,
    id,
  };
};

export const getCurrClinicalSign = (id) => {
  return {
    type: GET_CURR_CLINICAL_SIGN,
    id,
  };
};
