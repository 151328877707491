export const RESET_LOAD_WATERBODIES = 'RESET_LOAD_WATERBODIES';
export const LOAD_WATERBODIES = 'LOAD_WATERBODIES';
export const LOAD_WATERBODIES_SUCCESS = 'LOAD_WATERBODIES_SUCCESS';
export const LOAD_WATERBODIES_ERROR = 'LOAD_WATERBODIES_ERROR';
export const SET_CURR_WATERBODY = 'SET_CURR_WATERBODY';
export const GET_CURR_WATERBODY = 'GET_CURR_WATERBODY';
export const GET_CURR_WATERBODY_SUCCESS = 'GET_CURR_WATERBODY_SUCCESS';
export const GET_CURR_WATERBODY_ERROR = 'GET_CURR_WATERBODY_ERROR';
export const CREATE_WATERBODY = 'CREATE_WATERBODY';
export const UPDATE_WATERBODY = 'UPDATE_WATERBODY';
export const CREATE_WATERBODY_SUCCESS = 'CREATE_WATERBODY_SUCCESS';
export const UPDATE_WATERBODY_SUCCESS = 'UPDATE_WATERBODY_SUCCESS';
export const CREATE_WATERBODY_ERROR = 'CREATE_WATERBODY_ERROR';
export const UPDATE_WATERBODY_ERROR = 'UPDATE_WATERBODY_ERROR';
export const DELETE_WATERBODY = 'DELETE_WATERBODY';
export const DELETE_WATERBODY_SUCCESS = 'DELETE_WATERBODY_SUCCESS';
export const DELETE_WATERBODY_ERROR = 'DELETE_WATERBODY_ERROR';
export const CLEAR_WATERBODY_FILTERS = 'CLEAR_WATERBODY_FILTERS';
export const UPDATE_WATERBODY_FILTERS = 'UPDATE_WATERBODY_FILTERS';

export const loadWaterBodies = (slug, page, searchCtx) => {
  return {
    type: LOAD_WATERBODIES,
    slug,
    page,
    searchCtx,
  };
};

export const resetWaterBodies = (client) => {
  return {
    type: RESET_LOAD_WATERBODIES,
    client,
  };
};

export const createWaterBody = (formData) => {
  return {
    type: CREATE_WATERBODY,
    formData,
  };
};

export const updateWaterBody = (formData) => {
  return {
    type: UPDATE_WATERBODY,
    formData,
  };
};

export const deleteWaterBody = (id) => {
  return {
    type: DELETE_WATERBODY,
    id,
  };
};

export const setCurrWaterBody = (waterBody) => {
  return {
    type: SET_CURR_WATERBODY,
    waterBody,
  };
};

export const getCurrWaterBody = (id) => {
  return {
    type: GET_CURR_WATERBODY,
    id,
  };
};

export const updateWaterBodyFilters = (newValues) => {
  return {
    type: UPDATE_WATERBODY_FILTERS,
    newValues,
  };
};
