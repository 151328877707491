import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import WaterBodyForm from './WaterBodyForm';

import layout from '../../styles/layout.module.scss';

export default function CreateWaterBody () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currWaterBody = useSelector((s) => s.waterBodies.currWaterBody);

  const goBack = useCallback(() => {
    history.replace(`/app/${orgSlug}/water-bodies/${_.get(currWaterBody, 'id')}`);
  }, [orgSlug, currWaterBody]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Water Body"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <WaterBodyForm onSubmit={goBack} />
    </div>
  );
}
