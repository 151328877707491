import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  CreateDrawer,
  CreateDrawerItem,
  PageHeader,
} from '../../components';
import { useRole } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { getUsers } from '../../redux/users/selectors';
import { loadUsers, RESET_LOAD_USERS } from '../../redux/users/actions';
import { action } from '../../redux/helpers';
import User from './User';
import Invite from './Invite';

import styles from './Users.module.scss';
import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';

export default function Users () {
  const history = useHistory();
  const dispatch = useDispatch();
  const currOrg = useSelector(getCurrOrg);
  const role = useRole();
  const users = useSelector(getUsers);
  const currPage = useSelector((s) => s.users.currUsersPage);
  const hasMore = useSelector((s) => s.users.hasMoreUsers);
  const loading = useSelector((s) => s.users.loadUsersPending);

  // Reset list anytime currOrg changes
  useEffect(() => {
    if (currOrg) {
      dispatch(action(RESET_LOAD_USERS));
    }
  }, [currOrg]);

  const loadMore = useCallback(() => {
    dispatch(loadUsers(currOrg.slug, currPage + 1));
  }, [currPage, currOrg]);

  const gotoCreateOrg = useCallback(
    () => history.push('/app/create-org'),
    [history],
  );

  const gotoCreateUser = useCallback(
    () => history.push(`/app/${currOrg.slug}/users/invite`),
    [history, currOrg],
  );

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Users" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader title="Users" />
      { _.includes(['admin', 'super'], role) && (
        <CreateDrawer>
          { role == 'super' && (
            <CreateDrawerItem
              key="new-org"
              label="New Org"
              icon="users"
              onClick={gotoCreateOrg}
            />
          )}
          <CreateDrawerItem
            key="new-user"
            label="New User"
            icon="user"
            onClick={gotoCreateUser}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, 'p-0')}>
        <h4 className="text-center m-3">
          {_.get(currOrg, 'name')}
        </h4>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <table className={tables.table}>
            <thead className={tables.header}>
              <tr>
                <th>Name</th>
                <th className={styles.access}>Access</th>
                <th className={tables.actions}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                !loading && users.length == 0
                  ? (
                    <tr key="zero">
                      <td className={tables.zero} colSpan={3}>
                        You don&apos;t have any users for this organization yet.
                      </td>
                    </tr>
                  )
                  : _.map(users, (entry) => entry.name
                    ? (
                      <User
                        key={`user-${entry.id}`}
                        user={entry}
                      />
                    ) : (
                      <Invite
                        key={`invite-${entry.id}`}
                        invite={entry}
                      />
                    ),
                  )
              }
              { loading && (
                <tr
                  className="text-center"
                  key="spinner"
                >
                  <td colSpan={3}>
                    <Spinner className={tables.spinner}  />
                  </td>
                </tr>
              )}
              { !loading && users.length > 0 &&
                <tr>
                  <td
                    className={tables.end}
                    colSpan={3}
                  >
                    You&apos;ve reached the end of the user list!
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
}
