import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Button,
} from 'reactstrap';

import { useDebounce, useToggle } from '../../utils/hooks';
import { key, keyCodes } from '../../utils/helpers';

import text from '../../styles/text.module.scss';
import styles from './SearchDropdown.module.scss';

export function SearchDropdown (props) {
  const {
    displayText,
    placeholder,
    loading,
    firstLoad,
    loadMore,
    hasMore,
    onClear,
    onOpen,
    className,
    disabled,
    children,
  } = props;

  /************ State ************/
  const inputRef = useRef();
  const { open, toggle, setOpen } = useToggle();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  /************ Callbacks ************/
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  const myLoadMore = useCallback((page) => {
    loadMore({ page, query });
  }, [loadMore, query]);

  const handleReverseMenuFocus = useCallback((e) => {
    // Intercept previous focus to target search input
    if (
      key(e) == keyCodes.tab && e.shiftKey &&
      e.target.getAttribute('role') === 'menuitem'
    ) {
      e.preventDefault();
      e.stopPropagation();
      inputRef.current.focus();
    }
  }, []);

  const handleReverseInputFocus = useCallback((e) => {
    // Intercept previous focus to close dropdown
    if (key(e) == keyCodes.tab && e.shiftKey) {
      // delay to skip over toggle with tabIndex=-1
      setTimeout(() => setOpen(false));
    }
  }, []);

  /************ Effects ************/
  useEffect(() => {
    // Load first page of data when debounced query changes
    firstLoad();
  }, [debouncedQuery, firstLoad]);

  useEffect(() => {
    if (open) {
      // Delay to override reactstrap focusing menu
      setTimeout(() => inputRef.current.focus());
      onOpen && onOpen();
    } else {
      // Clear query for next open. Does nothing if it's already clear
      setQuery('');
    }
  }, [open, onOpen]);

  /************ Render ************/

  const toggleContent = open
    ? (
      <input
        className={styles.searchInput}
        ref={inputRef}
        type="text"
        value={query || ''}
        placeholder={placeholder}
        onKeyDown={handleReverseInputFocus}
        onClick={stopPropagation}
        onChange={(e) => setQuery(e.target.value)}
      />
    )
    : (
      <div className={cx(
        styles.displayText,
        text.ellipsize,
        { [styles.placeholder ]: !displayText },
      )}>
        { displayText || placeholder }
      </div>
    );

  return (
    <Dropdown
      isOpen={open}
      toggle={toggle}
      className={className}
      disabled={disabled}
    >
      <DropdownToggle
        className={cx(
          'form-control',
          styles.searchToggle,
          {disabled},
          className,
        )}
        tag="div"
        role="button"
        tabIndex={open ? -1 : 0}
        onClick={stopPropagation}
        caret
      >
        <FontAwesomeIcon icon="search" />
        <div className={cx(styles.toggleText)}>
          { toggleContent }
        </div>
        { onClear && (
          <Button
            color="link"
            className={styles.clearBtn}
            onClick={onClear}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
      </DropdownToggle>
      <DropdownMenu
        className={styles.menu}
        onKeyDown={handleReverseMenuFocus}
      >
        <InfiniteScroll
          loadMore={myLoadMore}
          hasMore={open && !loading && hasMore}
          useWindow={false}
        >
          { children }
          { loading && (
            <div className="text-center">
              <Spinner
                className={styles.spinner}
                key="spinner" />
            </div>
          )}
        </InfiniteScroll>
      </DropdownMenu>
    </Dropdown>
  );
}
SearchDropdown.propTypes = {
  placeholder: PropTypes.string,
  displayText: PropTypes.string,
  onClear: PropTypes.func,
  onOpen: PropTypes.func,
  firstLoad: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
};
SearchDropdown.defaultProps = {
  placeholder: 'Search',
  displayText: '',
};
