import React, { useCallback, useEffect, useRef, useReducer } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
  CustomInput,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { DateTimeInput } from '../../components/inputs/DateTimeInput';
import { createActivity, updateActivity } from '../../redux/activities/actions';
import { CancelButton, GeneralErrors } from '../../components';
import { useReadOnly } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import { FishCountInput } from '../../components/inputs/FishCountInput';
import { VaccinationInput } from '../../components/inputs/VaccinationInput';
import { TreatmentInput } from '../../components/inputs/TreatmentInput';

export default function ActivityForm ({ activity, onSubmit }) {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const [, forceUpdate] = useReducer((x) => (x + 1, 0));
  const { id: cycleId } = useParams();
  const typeSelect = useRef();
  const {
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
  } = useForm({ defaultValues: activity || {
    activity_object: {},
  } });
  const formData = watch();

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.activities.changeActivityPending);
  const error = useSelector((s) => s.activities.changeActivityError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    const newData = {
      id: _.get(activity, 'id'),
      ...data,
      activity_object: {
        ...get(data, 'activity_object', {}),
        pathogen: _.get(data, 'activity_object.pathogen.id'),
        treatment_type: _.get(data, 'activity_object.treatment_type.id'),
      },
    };
    if (activity) {
      dispatch(updateActivity(newData));
    } else {
      dispatch(createActivity({
        ...newData,
        prod_cycle: Number(cycleId),
      }));
    }
  }, [activity, cycleId]);

  let ActivityInput = null;

  switch(_.get(formData, 'activity_object.type')) {
    case 'treatment':
      ActivityInput = TreatmentInput;
      break;
    case 'vaccination':
      ActivityInput = VaccinationInput;
      break;
    case 'fishcount':
      ActivityInput = FishCountInput;
      break;
  }

  // force update for typeSelect value
  useEffect(() => {
    forceUpdate();
  }, []);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Activity</Label>
          <CustomInput
            id="activity-type"
            innerRef={typeSelect}
            type="select"
            className="form-control"
            defaultValue={
              _.get(formData, 'activity_object.reason') ||
              _.get(formData, 'activity_object.type')
            }
            onChange={(e) => {
              const option = e.target.options[e.target.selectedIndex];
              setValue('activity_object', { ...option.dataset });
            }}
            disabled={!!activity}
          >
            <option value="">
              Select Activity Type
            </option>
            <option
              value="treatment"
              data-type="treatment"
            >
              Treatment
            </option>
            <option
              value="vaccination"
              data-type="vaccination"
            >
              Vaccination
            </option>
            <option
              value="stock"
              data-reason="stock"
              data-type="fishcount"
            >
              Stocking
            </option>
            <option
              value="mort"
              data-reason="mort"
              data-type="fishcount"
            >
              Mortality
            </option>
            <option
              value="sein_harv"
              data-reason="sein_harv"
              data-type="fishcount"
            >
              Seining/Harvesting
            </option>
            <option
              value="trans"
              data-reason="trans"
              data-type="fishcount"
            >
              Transfering
            </option>
          </CustomInput>
        </FormGroup>
        {_.get(formData, 'activity_object.type') && (
          <FormGroup>
            <Label>
              Date of {_.get(typeSelect.current, `options[${_.get(typeSelect.current, 'selectedIndex')}].text`)} *
            </Label>
            <Controller
              required
              as={DateTimeInput}
              name="date"
              control={control}
              invalid={ isSubmitted && _.has(error, 'date') }
              disabled={readOnly}
            />
            <FormFeedback role="alert">
              { _.get(error, 'date') }
            </FormFeedback>
          </FormGroup>
        )}
        <Controller
          as={ActivityInput}
          name="activity_object"
          control={control}
          error={isSubmitted && _.get(error, 'activity_object')}
          className='mb-3'
          disabled={readOnly}
        />
        { isSubmitted && <GeneralErrors error={error} /> }
        { _.get(formData, 'activity_object.type') && (
          <>
            {!readOnly && (
              <Button
                color="primary"
                disabled={pending}
                block
              >
                Submit{' '}
                { pending && <Spinner size="sm" /> }
              </Button>
            )}
            <CancelButton />
          </>
        )}
      </Form>
    </div>
  );
}
ActivityForm.propTypes = {
  activity: PropTypes.object,
  onSubmit: PropTypes.func,
};
