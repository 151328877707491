import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import OrgForm from './OrgForm';

import layout from '../../styles/layout.module.scss';

export default function CreateOrg () {
  const history = useHistory();

  const gotoOrg = useCallback((slug) => {
    history.replace(`/app/${slug}`);
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Org"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <OrgForm onSubmit={gotoOrg} />
    </div>
  );
}
