import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { Spinner, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'classnames';
import * as _ from 'lodash';

import {
  CreateDrawer,
  CreateDrawerItem,
  PageHeader,
  KebabDropdown,
} from '../../components';
import ListReports from './ListReports';
import NotFound from '../NotFound';
import ListActivities from './ListActivities';
import { useRole, useConfirmAction } from '../../utils/hooks';
import { getCurrProdCycle, deleteProdCycle } from '../../redux/prodcycles/actions';
import { formatDateShort } from '../../utils/helpers';

import layout from '../../styles/layout.module.scss';
import styles from './ProdCycleDetail.module.scss';

export default function ProdCycleDetail () {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { orgSlug, id } = useParams();
  const role = useRole();
  const currProd = useSelector((s) => s.prodCycles.currProdCycle);
  const confirmAction = useConfirmAction();

  // Fetch currProdCycle if we don't have it
  useEffect(() => {
    if (_.get(currProd, 'id') != id) {
      dispatch(getCurrProdCycle(id));
    }
  }, [id, currProd]);

  const gotoCreateReport = useCallback(
    () => history.push(`/app/${orgSlug}/prod-cycles/${id}/new-report`),
    [history],
  );

  const gotoCreateAcitivty = useCallback(
    () => history.push(`/app/${orgSlug}/prod-cycles/${id}/log-activity`),
    [history],
  );

  const removeProdCycle = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'prodcycle',
      subject: 'this prodcycle',
      onConfirm: () => dispatch(deleteProdCycle(
        id,
      )),
      pendingPath: 'prodCycles.deleteProdCyclePending',
      errorPath: 'prodCycles.deleteProdCycleError',
      onSuccess: history.goBack,
    });
  }, [currProd, confirmAction]);

  if (!currProd) {
    return (
      <div className={layout.page}>
        <PageHeader title="Production Cycle" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader title="Production Cycle">
        { !_.includes(['read'], role) && (
          <KebabDropdown>
            <DropdownItem
              tag={Link}
              to={`/app/${orgSlug}/prod-cycles/${id}/edit`}
            >
              <FontAwesomeIcon icon="cog" />
              Edit Production Cycle
            </DropdownItem>
            <DropdownItem onClick={removeProdCycle}>
              <FontAwesomeIcon icon="trash" />
              Delete Production Cycle
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      { !_.includes(['read'], role) && (
        <CreateDrawer>
          <CreateDrawerItem
            key="new-report"
            label="New Report"
            icon={['fab', 'wpforms']}
            onClick={gotoCreateReport}
          />
          <CreateDrawerItem
            key="new-activity"
            label="Log an Activity"
            icon="pencil-alt"
            onClick={gotoCreateAcitivty}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, 'p-0')}>
        <h4 className={styles.header}>
          {_.get(currProd, 'name')}
        </h4>
        <div className={styles.datesContainer}>
          <div className={styles.dateBox}>
            <div>
              Start Date
            </div>
            <div className={styles.value}>
              {formatDateShort(currProd.start)}
            </div>
          </div>
          <div className={styles.dateBox}>
            <div>
              Total Reports
            </div>
            <div className={styles.value}>
              {_.get(currProd, 'num_reports', 0)}
            </div>
          </div>
          <div className={styles.dateBox}>
            <div>
              Number of Fish
            </div>
            <div className={styles.value}>
              { currProd.current_inventory }
            </div>
          </div>
        </div>
        <div className={layout.tabList}>
          <NavLink
            to={`/app/${orgSlug}/prod-cycles/${id}/reports`}
            className={layout.tab}
            exact
          >
            Reports
          </NavLink>
          <NavLink
            to={`/app/${orgSlug}/prod-cycles/${id}/activities`}
            className={layout.tab}
            exact
          >
            Activities
          </NavLink>
        </div>
        <div className="p-3">
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${location.pathname}/reports`} />
            </Route>
            <Route exact path={`${path}/reports`}>
              <ListReports />
            </Route>
            <Route exact path={`${path}/activities`}>
              <ListActivities />
            </Route>
            <Route path={`${path}/*`}>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
