export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_DONE = 'FORGOT_PASSWORD_DONE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE';

export const login = (formData) => {
  return {
    type: LOGIN,
    formData,
  };
};

export const signup = (formData) => {
  return {
    type: SIGNUP,
    formData,
  };
};

export const editUser = (formData) => {
  return {
    type: EDIT_USER,
    formData,
  };
};

export const loginRequired = (redirectLoc) => {
  return {
    type: LOGIN_REQUIRED,
    redirectLoc,
  };
};

export const forgotPassword = (email) => {
  return {
    type: FORGOT_PASSWORD,
    email,
  };
};

export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    data,
  };
};
