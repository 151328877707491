import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './KebabDropdown.module.scss';
import header from '../../components/nav/PageHeader.module.scss';

const BaseKebabDropdown = (props) => {
  const {
    right,
    children,
    className,
    ...rest
  } = props;

  return (
    <UncontrolledDropdown
      className={cx(
        header.secondaryAction,
        { [header.secondaryActionRight]: right },
        className,
      )}
      {...rest}
    >
      <DropdownToggle
        aria-label="Code section options"
        className="btn-header-action"
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu
        className={styles.kebabMenu}
        right={right}
      >
        { children }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
BaseKebabDropdown.propTypes = {
  right: PropTypes.bool,
};
BaseKebabDropdown.defaultProps = {
  right: false,
};

export const KebabDropdown = React.memo(BaseKebabDropdown);
