import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import 'swiper/css/swiper.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-circular-progressbar/dist/styles.css';
import '../styles/app.scss';
import layout from '../styles/layout.module.scss';

import LoggedInRoute from '../routes/LoggedInRoute';
import AnonymousRoute from '../routes/AnonymousRoute';
import { TopNav } from '../components';
import App from './App';
import Signup from './auth/Signup';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import PasswordReset from './auth/PasswordReset';
import NotFound from './NotFound';
import { MobileProvider } from '../utils/hooks/useMobile';
import { WATERBODY_FORM_DATA } from './waterbodies/WaterBodyForm';
import { FISHINFO_FORM_DATA } from './reports/FishInfoForm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fab);

function Root () {
  const didMount = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (didMount.current) {
      // Route changed. Focus top of page
      let top = document.querySelector('#menu-toggle');

      if (!top || top.getBoundingClientRect().width == 0) {
        // Mobile hamburger isn't on the screen
        top = document.querySelector('#home-link');
      }

      if (top) {
        top.focus();
        // This may not be compliant
        top.blur();
      }
    } else {
      didMount.current = true;
    }

    // Clear cached form data for unaffected routes
    if (
      _.every(
        [
          /\/water-bodies\/\d+\/edit$/,
          /\/water-bodies\/create$/,
          /\/clients\/create$/,
          /\/clients\/\d+\/edit$/,
        ],
        (exp) => !exp.test(location.pathname),
      )
    ) {
      new Cookies().remove(WATERBODY_FORM_DATA, { path: '/' });
    }
    if (
      _.every(
        [
          /\/edit\/fish-info$/,
          /\/log-activity$/,
          /\/activities$/,
          /\/activities\/\d+\/edit$/,
        ],
        (exp) => !exp.test(location.pathname),
      )
    ) {
      new Cookies().remove(FISHINFO_FORM_DATA, { path: '/' });
    }
  }, [location.pathname]);

  return (
    <MobileProvider>
      <div role="main">
        <TopNav />
        <Switch>
          {/* All authenticated routes namespaced under /app */}
          <Route exact path="/">
            <Redirect to="/app" />
          </Route>
          <LoggedInRoute path="/app">
            <App />
          </LoggedInRoute>
          <AnonymousRoute path="/signup/:key">
            <Signup />
          </AnonymousRoute>
          <AnonymousRoute path="/login">
            <Login />
          </AnonymousRoute>
          <AnonymousRoute path="/forgot-password">
            <ForgotPassword />
          </AnonymousRoute>
          <Route path="/password-reset/:uid/:token">
            <PasswordReset />
          </Route>
          <Route path="*">
            <div className={layout.app}>
              <NotFound />
            </div>
          </Route>
        </Switch>
      </div>
    </MobileProvider>
  );
}

export default Root;
