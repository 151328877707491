import React, { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import layout from '../../styles/layout.module.scss';

const MobileContext = createContext(null);

const MobileProvider = ({children}) => {
  const mobile = useMediaQuery({ query: `(max-width: ${layout.mobileWidth})` });

  return (
    <MobileContext.Provider value={mobile}>
      {children}
    </MobileContext.Provider>
  );
};

const useMobile = () => {
  const context = useContext(MobileContext);

  if(context === null) {
    throw new Error('useMobile must be used within MobileProvider');
  }

  return context;
};

export {useMobile, MobileProvider};
