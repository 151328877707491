import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportMedia } from '../../redux/reports/selectors';
import { loadReportMedia, RESET_LOAD_REPORT_MEDIA } from '../../redux/reports/actions';
import { action } from '../../redux/helpers';

export const useReportMedia = (id) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const reportMedia = useSelector(getReportMedia);
  const currPage = useSelector((s) => s.reports.currReportMediaPage);
  const hasMore = useSelector((s) => s.reports.hasMoreReportMedia);
  const loading = useSelector((s) => s.reports.loadReportMediaPending);
  const reportMediaUploads = useSelector((s) => s.reports.reportMediaUploads);
  const uploads = useMemo(() => {
    return _.values(_.get(reportMediaUploads, id, {}));
  }, [reportMediaUploads, id]);

  useEffect(() => {
    dispatch(action(RESET_LOAD_REPORT_MEDIA));
    setResetCalled(true);
  }, []);

  const loadMore = useCallback(() => {
    dispatch(loadReportMedia(id, currPage + 1));
  }, [currPage, id]);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(reportMedia, 'length', 0) == 0
    );
  }, [loading, hasMore, reportMedia]);

  return {
    // Ignore redux list until first load
    reportMedia: firstLoad.current ? reportMedia : [],
    uploads,
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    loadMore,
  };
};
