import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';

import { ListCard, PageHeader } from '../../components';
import { TOGGLE_REPORT_SORT } from '../../redux/reports/actions';
import { action } from '../../redux/helpers';
import { formatDate } from '../../utils/helpers';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import text from '../../styles/text.module.scss';
import cards from '../../components/lists/ListCard.module.scss';
import styles from './Search.module.scss';
import { useReports } from '../../utils/hooks';
import { getLocalReportSearchCtx, getNumFilters } from '../../redux/reports/selectors';

export default function SearchResults () {
  const dispatch = useDispatch();
  const sortDesc = useSelector(({ reports }) => reports.sortDesc);
  const numFilters = useSelector(getNumFilters);
  const localSearchCtx = useSelector(getLocalReportSearchCtx);
  const { orgSlug } = useParams();
  const {
    reports,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useReports();

  const toggleSort = useCallback(() => {
    dispatch(action(TOGGLE_REPORT_SORT));
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader title="Search Reports" />
      <div className={layout.pageContent}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          className={cx(layout.responsiveContainer, 'text-center')}
        >
          <div className={styles.controls}>
            <Button
              color="secondary"
              onClick={toggleSort}
            >
              <FontAwesomeIcon icon={sortDesc
                ? 'arrow-down-wide-short'
                : 'arrow-up-short-wide'
              } />
              Sort Results
            </Button>
            <Link
              to={`/app/${orgSlug}/search/filter`}
              className="btn btn-primary"
            >
              <span
                className={styles.filterIcon}
                data-count={numFilters > 0 ? numFilters : undefined}
              >
                <FontAwesomeIcon icon="filter" />
              </span>
              Filter Results
            </Link>
          </div>
          {
            noResults
              ? (
                <p key="zero" className="text-center">
                  {localSearchCtx ?
                    'No reports match your current filters'
                    :
                    'You don\'t have any reports for this organization yet.'
                  }
                </p>
              )
              : _.map(reports, (r) => (
                <ListCard
                  key={`report-${r.id}`}
                  linkTo={`/app/${orgSlug}/reports/${r.id}?from_search=1`}
                  flex
                >
                  <FontAwesomeIcon
                    className={styles.statusIcon}
                    icon={r.complete ? 'check-circle' : ['far', 'hourglass']}
                  />
                  <span className={text.ellipsize}>
                    {formatDate(r.date)}
                  </span>
                  <i className={cx(cards.detailSubtext, text.subtext)}>
                    {r.case_no}
                  </i>
                </ListCard>
              ))
          }
          { loading && (
            <div
              className="text-center"
              key="spinner"
            >
              <Spinner className={tables.spinner} />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}
