import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
  CustomInput,
} from 'reactstrap';
import Cookies from 'universal-cookie';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { createWaterBody, updateWaterBody } from '../../redux/waterbodies/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import {
  Checkbox,
  MeasurementInput,
  ClientSelect,
  GeneralErrors,
  CancelButton,
} from '../../components';
import { Measurements } from '../../components/inputs/Measurements';
import states from '../../utils/data/states';

import layout from '../../styles/layout.module.scss';

export const WATERBODY_FORM_DATA = '_ksu_wfd';

export default function WaterBodyForm ({waterBody, onSubmit}) {
  const currOrg = useSelector(getCurrOrg);
  const { orgSlug } = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState,
    control,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...(waterBody || { address_same_as_client: true }),
      ...(new Cookies().get(WATERBODY_FORM_DATA) || { }),
    },
  });
  new Cookies().remove(WATERBODY_FORM_DATA, { path: '/' });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.waterBodies.changeWaterBodyPending);
  const error = useSelector((s) => s.waterBodies.changeWaterBodyError);
  const submitSuccess = isSubmitted && !pending && !error;
  const sameAsClient = watch('address_same_as_client');

  const submit = useCallback((data) => {
    if (data.address_same_as_client) {
      data.address_line_1 = '';
      data.address_line_2 = '';
      data.address_city = '';
      data.address_state = '';
      data.address_zip_code = '';
    }

    if (waterBody) {
      dispatch(updateWaterBody({
        ...data,
        client: _.get(data, 'client.id'),
        id: waterBody.id,
      }));
    } else {
      dispatch(createWaterBody({
        ...data,
        client: _.get(data, 'client.id'),
      }));
    }
  }, [waterBody, currOrg]);

  const saveFormState = useCallback(() => {
    new Cookies().set(WATERBODY_FORM_DATA, getValues(), { path: '/' });
  }, [getValues]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Name *</Label>
          <Input
            required
            type="text"
            innerRef={register}
            name="name"
            invalid={ isSubmitted && _.has(error, 'name') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'name') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Client *</Label>
          <Link
            to={`/app/${orgSlug}/clients/create`}
            className="float-right"
            onClick={saveFormState}
          >
            <FontAwesomeIcon icon="external-link-alt" /> Add Client
          </Link>
          <Controller
            as={ClientSelect}
            name="client"
            control={control}
            invalid={ isSubmitted && _.has(error, 'client') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'client') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Type *</Label>
          <CustomInput
            innerRef={register}
            id="waterbody_type"
            type="select"
            name="waterbody_type"
            className="form-control"
            required
          >
            <option value="">-- Select water body type --</option>
            <option value="tank">Tank</option>
            <option value="pond">Pond</option>
            <option value="raceway">Raceway</option>
          </CustomInput>
          <FormFeedback role="alert">
            { _.get(error, 'waterbody_type') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Material *</Label>
          <CustomInput
            innerRef={register}
            id="material"
            type="select"
            name="material"
            className="form-control"
            required
          >
            <option value="">-- Select water body material --</option>
            <option value="earthen">Earthen</option>
            <option value="concrete">Concrete</option>
            <option value="plastic">Plastic</option>
            <option value="metal">Metal</option>
          </CustomInput>
          <FormFeedback role="alert">
            { _.get(error, 'material') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Location *</Label>
          <CustomInput
            innerRef={register}
            id="location"
            type="select"
            name="location"
            className="form-control"
            required
          >
            <option value="">-- Select water body location --</option>
            <option value="indoor">Indoor</option>
            <option value="outdoor">Outdoor</option>
          </CustomInput>
          <FormFeedback role="alert">
            { _.get(error, 'location') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Surface Area</Label>
          <Controller
            as={MeasurementInput}
            type={Measurements.AREA}
            name="surface_area"
            control={control}
            invalid={ isSubmitted && _.has(error, 'surface_area') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'surface_area') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Volume</Label>
          <Controller
            as={MeasurementInput}
            type={Measurements.VOLUME}
            name="volume"
            control={control}
            invalid={ isSubmitted && _.has(error, 'volume') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'volume') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Average Depth</Label>
          <Controller
            as={MeasurementInput}
            type={Measurements.DISTANCE}
            name="average_depth"
            control={control}
            invalid={ isSubmitted && _.has(error, 'average_depth') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'average_depth') }
          </FormFeedback>
        </FormGroup>
        <h4 className="my-3">Address</h4>
        <FormGroup>
          <Controller
            as={Checkbox}
            name="address_same_as_client"
            control={control}
            label="Same as Client"
          />
        </FormGroup>
        {
          !sameAsClient && (
            <>
              <FormGroup>
                <Label>Address Line 1</Label>
                <Input
                  type="text"
                  innerRef={register}
                  name="address_line_1"
                  invalid={ isSubmitted && _.has(error, 'address_line_1') }
                  autoComplete="off"
                />
                <FormFeedback role="alert">
                  { _.get(error, 'address_line_1') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Address Line 2</Label>
                <Input
                  type="text"
                  innerRef={register}
                  name="address_line_2"
                  invalid={ isSubmitted && _.has(error, 'address_line_2') }
                  autoComplete="off"
                />
                <FormFeedback role="alert">
                  { _.get(error, 'address_line_2') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type="text"
                  innerRef={register}
                  name="address_city"
                  invalid={ isSubmitted && _.has(error, 'address_city') }
                  autoComplete="off"
                />
                <FormFeedback role="alert">
                  { _.get(error, 'address_city')}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="select">State</Label>
                <CustomInput
                  id="select"
                  type="select"
                  name="address_state"
                  className="form-control"
                >
                  {_.map(states, (state) => (
                    <option
                      key={`state-${state.value}`}
                      value={state.value}
                    >
                      {state.text}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label>Zip Code</Label>
                <Input
                  type="text"
                  innerRef={register}
                  name="address_zip_code"
                  invalid={ isSubmitted && _.has(error, 'address_zip_code') }
                  autoComplete="off"
                />
                <FormFeedback role="alert">
                  { _.get(error, 'address_zip_code') }
                </FormFeedback>
              </FormGroup>
            </>
          )
        }
        { isSubmitted && <GeneralErrors error={error} /> }
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Submit{' '}
          { pending && <Spinner size="sm" /> }
        </Button>
        <CancelButton />
      </Form>
    </div>
  );
}
WaterBodyForm.propTypes = {
  waterBody: PropTypes.object,
  onSubmit: PropTypes.func,
};
