import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import cx from 'classnames';

import styles from './Report.module.scss';
import text from '../../styles/text.module.scss';

export function ReportSubsection (props) {
  const {
    title,
    mode,
    children,
  } = props;

  const colProps = useMemo(() => {
    switch (mode) {
      case 'default':
        return { md: '4', xs: '6'};
      case 'table':
        return { xl: true, xs:'4' };
      case 'half':
        return { xs: '6'};
    }
  }, [mode]);

  return (
    <Col
      className={styles.subsection}
      {...colProps}
    >
      <span className={cx(
        styles.subsectionTitle,
        text.ellipsize,
      )}>
        { title }
      </span>
      <div className={styles.subsectionChildren}>
        { children }
      </div>
    </Col>
  );
}
ReportSubsection.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(['default', 'table', 'half']),
};
ReportSubsection.defaultProps = {
  mode: 'default',
};
