import {
  buffers,
  eventChannel,
  END,
} from 'redux-saga';
import {
  all,
  call,
} from 'redux-saga/effects';
import { CancelToken } from 'axios';

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// Take at least 500ms.
export function* minDelayCall (...callArgs) {
  const [ result ] = yield all([
    yield call(...callArgs),
    yield call(delay, 500),
  ]);

  return result;
}

export const action = (type) => ({ type });

// Create saga channel to monitor axios upload
export const createUploadChannel = (apiMethod, formData) => {
  return eventChannel(
    (emitter) => {
      let cancel = () => {};

      apiMethod(
        formData,
        // Config for axios
        {
          cancelToken: new CancelToken((c) => cancel = c),
          onUploadProgress: (e) => emitter({
            progress: Math.round((e.loaded / e.total) * 100),
          }),
        },
      )
      .then((response) => emitter({ response }))
      .catch((error) => emitter({ error }))
      .finally(() => emitter(END));

      // Closing the channel cancels the axios request
      return cancel;
    },
    // Buffer last 2 values to ensure we don't miss last event + END
    buffers.sliding(2),
  );
};
