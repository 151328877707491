import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import cx from 'classnames';

import { DateTimeInput } from '../../components/inputs/DateTimeInput';
import { useReadOnly } from '../../utils/hooks';
import {
  createReport,
  updateReport,
  setStepTitle,
} from '../../redux/reports/actions';
import { GeneralErrors } from '../../components';

import layout from '../../styles/layout.module.scss';
import styles from './Report.module.scss';

export default function ReportInfoForm ({ report }) {
  const readOnly = useReadOnly();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgSlug, id: cycleId } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm({
    defaultValues: report,
  });

  const { isSubmitted } = formState;

  const currReport = useSelector((s) => s.reports.currReport);
  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;

  useEffect(() => {
    dispatch(setStepTitle('Update Report'));
  }, []);

  const submit = useCallback((data) => {
    if (report) {
      dispatch(updateReport({
        ...data,
        id: report.id,
      }));
    } else {
      dispatch(createReport({
        ...data,
        prod_cycle: Number(cycleId),
      }));
    }
  }, [report, cycleId]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      if (!report) {
        // Replace create route with edit for logical back stack
        history.replace(`/app/${orgSlug}/reports/${currReport.id}/edit/info`);
      }
      history.push(`/app/${orgSlug}/reports/${currReport.id}/edit/fish-info`);
    }
  }, [orgSlug, currReport, submitSuccess]);

  return (
    <Form
      className={cx(
        layout.responsiveContainer,
        layout.responsiveContainerButtonsBottom,
      )}
      onSubmit={handleSubmit(submit)}
    >
      <FormGroup>
        <Label>Date *</Label>
        <Controller
          as={DateTimeInput}
          name="date"
          control={control}
          invalid={ isSubmitted && _.has(error, 'date') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'date') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Case Number</Label>
        <Input
          innerRef={register}
          type="text"
          name="case_no"
          invalid={ isSubmitted && _.has(error, 'case_no') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'case_no') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Extension Agent</Label>
        <Input
          innerRef={register}
          type="text"
          name="ext_agent"
          invalid={ isSubmitted && _.has(error, 'ext_agent') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'ext_agent') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Phone Number</Label>
        <Input
          innerRef={register}
          type="text"
          name="phone_number"
          invalid={ isSubmitted && _.has(error, 'phone_number') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'phone_number') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Diagnostician</Label>
        <Input
          innerRef={register}
          type="text"
          name="diagnostician"
          invalid={ isSubmitted && _.has(error, 'diagnostician') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'diagnostician') }
        </FormFeedback>
      </FormGroup>
      { isSubmitted && <GeneralErrors error={error} /> }
      <div className={styles.buttonsBottom}>
        { !readOnly && (
          <Button
            color="primary"
            disabled={pending}
            block
          >
            Save and Continue{' '}
            { pending && <Spinner size="sm" /> }
          </Button>
        )}
        <Link
          className="btn btn-secondary btn-block"
          to={`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`}
        >
          Cancel
        </Link>
      </div>
    </Form>
  );
}
