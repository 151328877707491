import React, { useCallback } from 'react';
import { CustomInput, DropdownItem } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { useSpecies } from '../../utils/hooks';
import { SearchDropdown } from './SearchDropdown';

import text from '../../styles/text.module.scss';
import multiselect from './Multiselect.module.scss';

// eslint-disable-next-line no-unused-vars
export const SpeciesSelect = React.forwardRef((props, ref) => {
  const { value, onChange, invalid } = props;
  const {
    species,
    hasMore,
    loading,
    reset,
    loadMore,
  } = useSpecies();
  const valueLookup = _.keyBy(value, (s) => s.id);
  const displayText = _.join(_.map(value, (s) => s.name), ', ');

  const onRemoveSpecies = useCallback((entry) => {
    onChange(_.filter(value, (s) => s.id != entry.id));
  }, [onChange, value]);

  const onOptionClicked = useCallback((s) => {
    if (!_.hasIn(valueLookup, s.id)) {
      onChange([ ...(value || []), s ]);
    } else {
      onRemoveSpecies(s);
    }
  }, [onChange, value]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder="Select Species"
      displayText={displayText}
      firstLoad={reset}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
    >
      { _.get(value, 'length', 0) == 0 && (
        <div className="text-center">
          No species selected
        </div>
      )}
      { _.get(value, 'length', 0) > 0 &&
        _.map(
          value,
          (s) => (
            <DropdownItem
              key={`species-selected-${s.id}`}
              onClick={() => onRemoveSpecies(s)}
              className={multiselect.item}
              toggle={false}
            >
              <CustomInput
                type="checkbox"
                id={`species-check-${s.id}`}
                className={multiselect.checkbox}
                checked
                readOnly
              />
              <div className={multiselect.content}>
                <i className={text.ellipsize}>
                  { s.name }
                </i>
                { s.common_name && (
                  <div className={text.ellipsize}>
                    { s.common_name }
                  </div>
                )}
              </div>
            </DropdownItem>
          ),
        )
      }
      <DropdownItem divider/>
      { !loading && _.get(species, 'length', 0) == 0 && (
        <div className="text-center">
          No species
        </div>
      )}
      { _.map(
        species,
        (s) => (
          <DropdownItem
            key={`species-${s.id}`}
            onClick={() => onOptionClicked(s)}
            className={multiselect.item}
            toggle={false}
          >
            <CustomInput
              type="checkbox"
              id={`species-check-${s.id}`}
              className={multiselect.checkbox}
              checked={_.hasIn(valueLookup, s.id)}
              readOnly
            />
            <div className={multiselect.content}>
              <i className={text.ellipsize}>
                { s.name }
              </i>
              { s.common_name && (
                <div className={text.ellipsize}>
                  { s.common_name }
                </div>
              )}
            </div>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
SpeciesSelect.displayName = 'SpeciesSelect';
