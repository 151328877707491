import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MediaCarousel } from '../../components';
import { useSampleMedia, useToggle } from '../../utils/hooks';
import { ReportSubsection } from './ReportSubsection';

import styles from './Report.module.scss';

export const ReadOnlySampleMedia = ({ sample }) => {
  const mediaCarousel = useToggle();
  const {
    sampleMedia,
    hasMore,
    loading,
    loadMore,
  } = useSampleMedia(sample.id);

  // Load all media page by page
  useEffect(() => {
    if (
      mediaCarousel.open &&
      sample.num_media &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [mediaCarousel.open, sample, hasMore, loading, loadMore]);

  return (
    <ReportSubsection title="Media">
      { sample.num_media > 0
        ? (
          <Button
            color="link"
            className={styles.viewMediaBtn}
            onClick={mediaCarousel.toggle}
          >
            View { sample.num_media } file{sample.num_media > 1 ? 's' : ''}
            <FontAwesomeIcon icon="photo-film" />
          </Button>
        )
        : '-'
      }
      <MediaCarousel
        media={sampleMedia}
        belongsTo="sample"
        initialIndex={0}
        {...mediaCarousel}
      />
    </ReportSubsection>
  );
};
