import * as _ from 'lodash';

import { compressSearch } from '../../utils/helpers';

export const getWaterBodies = ({ waterBodies }) => waterBodies.waterBodies;

export const getWaterBodyFilters = ({ waterBodies }) => waterBodies.waterBodyFilters;

export const getWaterBodySearchCtx = ({ waterBodies }) => waterBodies.searchCtx;

const shouldOmit = _.overSome(_.isNil, _.isEmpty);

export const getLocalWaterBodySearchCtx = ({ waterBodies }) => {
  const filters = _.omitBy(
    waterBodies.waterBodyFilters,
    (val, key) => {
      if (_.includes(['surface_area', 'volume', 'average_depth'], key)) {
        if (shouldOmit(val['minValue']) && shouldOmit(val['maxValue'])) {
          return true;
        }
      }

      return shouldOmit(val);
    },
  );

  if (!_.isEmpty(filters)) {
    return compressSearch(filters);
  }

  return null;
};

export const getNumFilters = ({ waterBodies }) => {
  return _.sumBy(
    _.keys(waterBodies.waterBodyFilters),
    (key) => {
      const val = waterBodies.waterBodyFilters[key];

      if (_.includes(['surface_area', 'volume', 'average_depth'], key)) {
        if (shouldOmit(val['minValue']) && shouldOmit(val['maxValue'])) {
          return 0;
        }
      }

      if (key == 'client' || shouldOmit(val)) {
        return 0;
      }

      return 1;
    },
  );
};
