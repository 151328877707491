import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import * as _ from 'lodash';

import { updateReport } from '../redux/reports/actions';
import { GeneralErrors } from '../components';
import { useRole } from '../utils/hooks';

export function SubmitReportModal ({ open, toggle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const { orgSlug } = useParams();
  const currReport = useSelector((s) => s.reports.currReport);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const actionSuccess = isSubmitted && !pending && !error;

  const markComplete = useCallback(() => {
    dispatch(updateReport({
      complete: true,
      id: currReport.id,
    }));
    setIsSubmitted(true);
  }, [currReport]);

  useEffect(() => {
    if (actionSuccess) {
      setIsSubmitted(false);

      toggle();
      history.push(`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`);
    }
  }, [actionSuccess, orgSlug, currReport, toggle]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      backdrop={pending ? 'static' : true}
    >
      <ModalHeader>
        Mark report as complete?
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p>
            This will make the report visible for all members in the org. All required fields must be filled out.
          </p>
          { role == 'write' && (
            <p>
              <i>You won&apos;t be able to edit the report once marked complete.</i>
            </p>
          )}
          { isSubmitted && <GeneralErrors error={error} /> }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          disabled={pending}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={markComplete}
          disabled={pending}
        >
          Mark as Complete
          { pending && <Spinner size="sm" /> }
        </Button>
      </ModalFooter>
    </Modal>
  );
}
