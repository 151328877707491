import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { DateTimeInput } from '../../components/inputs/DateTimeInput';
import { createProdCycle, updateProdCycle } from '../../redux/prodcycles/actions';
import { CancelButton, GeneralErrors } from '../../components';
import { SpeciesSelect } from '../../components/inputs/SpeciesSelect';

import layout from '../../styles/layout.module.scss';
import { FishCountInput } from '../../components/inputs/FishCountInput';

export default function ProdCycleForm ({prodCycle, onSubmit}) {
  const dispatch = useDispatch();
  const { id: bodyId } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm({
    defaultValues: prodCycle || {
      starting_inventory: {
        value: '',
        small_percent: 0,
        medium_percent: 0,
        large_percent: 0,
      },
    },
  });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.prodCycles.changeProdCyclePending);
  const error = useSelector((s) => s.prodCycles.changeProdCycleError);
  const submitSuccess = isSubmitted && !pending && !error;

  const submit = useCallback((data) => {
    if (prodCycle) {
      dispatch(updateProdCycle({
        ...data,
        species: _.map(
          _.get(data, 'species', []),
          (s) => s.id,
        ),
        id: prodCycle.id,
      }));
    } else {
      dispatch(createProdCycle({
        ...data,
        water_body: Number(bodyId),
        species: _.map(
          _.get(data, 'species', []),
          (s) => s.id,
        ),
      }));
    }
  }, [prodCycle, bodyId]);

  // Submit success
  useEffect(() => {
    if (submitSuccess && onSubmit) {
      onSubmit();
    }
  }, [submitSuccess, onSubmit]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Name *</Label>
          <Input
            required
            type="text"
            innerRef={register}
            name="name"
            invalid={ isSubmitted && _.has(error, 'name') }
            autoComplete="off"
          />
          <FormFeedback role="alert">
            { _.get(error, 'name') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Start Date *</Label>
          <Controller
            as={DateTimeInput}
            name="start"
            control={control}
            invalid={ isSubmitted && _.has(error, 'start') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'start') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Species</Label>
          <Controller
            as={SpeciesSelect}
            name="species"
            control={control}
            invalid={ isSubmitted && _.has(error, 'species') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'species') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Controller
            as={FishCountInput}
            name="starting_inventory"
            control={control}
            error={isSubmitted && _.get(error, 'starting_inventory')}
            label="Starting Inventory *"
          />
        </FormGroup>
        { isSubmitted && <GeneralErrors error={error} /> }
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Submit{' '}
          { pending && <Spinner size="sm" /> }
        </Button>
        <CancelButton />
      </Form>
    </div>
  );
}
ProdCycleForm.propTypes = {
  prodCycle: PropTypes.object,
  value: PropTypes.string,
  small_percent: PropTypes.string,
  medium_percent: PropTypes.string,
  large_percent: PropTypes.string,
  onSubmit: PropTypes.func,
};
