import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { BackButton, PageHeader } from '../../components';
import ReportSteps from './ReportSteps';
import ReportInfoForm from './ReportInfoForm';
import { CLEAR_CURR_REPORT } from '../../redux/reports/actions';
import { action } from '../../redux/helpers';

import layout from '../../styles/layout.module.scss';

export default function CreateReport () {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action(CLEAR_CURR_REPORT));
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Report"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <ReportSteps />
        <ReportInfoForm />
      </div>
    </div>
  );
}
