import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProdCycles } from '../../redux/prodcycles/selectors';
import { loadProdCycles, RESET_LOAD_PROD_CYCLES } from '../../redux/prodcycles/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { action } from '../../redux/helpers';

export const useProdCycles = (bodyId) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const currOrg = useSelector(getCurrOrg);
  const prodCycles = useSelector(getProdCycles);
  const currPage = useSelector((s) => s.prodCycles.currProdCyclesPage);
  const hasMore = useSelector((s) => s.prodCycles.hasMoreProdCycles);
  const loading = useSelector((s) => s.prodCycles.loadProdCyclesPending);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(prodCycles, 'length', 0) == 0
    );
  }, [loading, hasMore, prodCycles]);

  // Reset list on mount
  useEffect(() => {
    dispatch(action(RESET_LOAD_PROD_CYCLES));
    setResetCalled(true);
  }, []);

  const loadMore = useCallback(() => {
    dispatch(loadProdCycles(bodyId, currPage + 1));
  }, [currPage, currOrg, bodyId]);

  return {
    // Ignore redux list until first load
    prodCycles: firstLoad.current ? prodCycles : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    loadMore,
  };
};
