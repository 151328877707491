import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MediaCarousel } from '../../components';
import { useReportMedia, useToggle } from '../../utils/hooks';
import { ReportSubsection } from './ReportSubsection';

import styles from './Report.module.scss';

export const ReadOnlyReportMedia = () => {
  const { id } = useParams();
  const currReport = useSelector((s) => s.reports.currReport);
  const mediaCarousel = useToggle();
  const {
    reportMedia,
    hasMore,
    loading,
    loadMore,
  } = useReportMedia(id);

  // Load all media page by page
  useEffect(() => {
    if (
      mediaCarousel.open &&
      currReport.num_media &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [mediaCarousel.open, currReport, hasMore, loading, loadMore]);

  return (
    <ReportSubsection title="Media">
      { currReport.num_media > 0
        ? (
          <Button
            color="link"
            className={styles.viewMediaBtn}
            onClick={mediaCarousel.toggle}
          >
            View { currReport.num_media } file{currReport.num_media > 1 ? 's' : ''}
            <FontAwesomeIcon icon="photo-film" />
          </Button>
        )
        : '-'
      }
      <MediaCarousel
        media={reportMedia}
        belongsTo="report"
        initialIndex={0}
        {...mediaCarousel}
      />
    </ReportSubsection>
  );
};
