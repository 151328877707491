import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import layout from '../../styles/layout.module.scss';
import { GeneralErrors, PageHeader } from '../../components';
import { login } from '../../redux/account/actions';

export default function Login ()  {
  const dispatch = useDispatch();
  const loginPending = useSelector((s) => s.account.loginPending);
  const loginError = useSelector((s) => s.account.loginError);

  const { register, handleSubmit, formState } = useForm();
  const { isSubmitted } = formState;

  const onSubmit = useCallback((creds) => {
    dispatch(login(creds));
  }, []);

  return (
    <div className={layout.app}>
      <div className={layout.page}>
        <PageHeader title="Log In" />
        <div className={layout.pageContent}>
          <div className={layout.responsiveContainer}>
            <p className={layout.pageDesc}>
              Welcome back!  Please log in below.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  required
                  innerRef={register}
                  type="email"
                  name="email"
                  placeholder="Email"
                  invalid={ isSubmitted && _.has(loginError, 'email') }
                />
                <FormFeedback role="alert">
                  { _.get(loginError, 'email') }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  required
                  innerRef={register}
                  type="password"
                  name="password"
                  placeholder="Password"
                  invalid={ isSubmitted && _.has(loginError, 'password') }
                />
                <FormFeedback role="alert">
                  { _.get(loginError, 'password') }
                </FormFeedback>
              </FormGroup>
              { isSubmitted && <GeneralErrors error={loginError} /> }
              <Button
                color="primary"
                disabled={loginPending}
                block
              >
                Login{' '}
                { loginPending && <Spinner size="sm" /> }
              </Button>
              <div className="text-center mt-2">
                <Link to="/forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
