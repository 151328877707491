import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClinicalSigns } from '../../redux/samples/selectors';
import { loadClinicalSigns, RESET_LOAD_CLINICAL_SIGNS } from '../../redux/samples/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { action } from '../../redux/helpers';

export const useClinicalSigns = (sampleId) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const currOrg = useSelector(getCurrOrg);
  const clinicalSigns = useSelector(getClinicalSigns);
  const currPage = useSelector((s) => s.samples.currClinicalSignsPage);
  const hasMore = useSelector((s) => s.samples.hasMoreClinicalSigns);
  const loading = useSelector((s) => s.samples.loadClinicalSignsPending);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(clinicalSigns, 'length', 0) == 0
    );
  }, [loading, hasMore, clinicalSigns]);

  // Reset list on mount
  useEffect(() => {
    dispatch(action(RESET_LOAD_CLINICAL_SIGNS));
    setResetCalled(true);
  }, []);

  const loadMore = useCallback(() => {
    dispatch(loadClinicalSigns(sampleId, currPage + 1));
  }, [currPage, currOrg, sampleId]);

  return {
    // Ignore redux list until first load
    clinicalSigns: firstLoad.current ? clinicalSigns : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    loadMore,
  };
};
