import React from 'react';
import cx from 'classnames';
import { Label } from 'reactstrap';

import styles from './LeftNav.module.scss';
import layout from '../../styles/layout.module.scss';
import textStyles from '../../styles/text.module.scss';
export function LeftNavPlaceholder () {
  return (
    <div className={styles.leftNav}>
      <div
        id="menu-toggle"
        className={styles.hamburger}
      >
        <div className={styles.hamburgerBox}>
          <div className={styles.hamburgerInner}></div>
        </div>
      </div>
      <div className={styles.menu}>
        <div className={styles.menuInner}>
          <div className={styles.headerPlaceholder}>
            <h4 className="text-center">
              <span className={textStyles.loadingText}>Menu</span>
            </h4>
            <Label className={cx(textStyles.loadingText, textStyles.navHeading)}>
              orgs
            </Label>
            <div className={layout.inputPlaceholder}></div>
          </div>
          <Label className={cx('ml-3 mb-3', textStyles.loadingText, textStyles.navHeading)}>
            more
          </Label>
          <div className={cx(layout.linkPlaceholder, textStyles.loadingText)}>
            Placeholder
          </div>
          <div className={cx(layout.linkPlaceholder, textStyles.loadingText)}>
            Placeholder
          </div>
          <div className={cx(layout.linkPlaceholder, textStyles.loadingText)}>
            Placeholder
          </div>
        </div>
      </div>
    </div>
  );
}
