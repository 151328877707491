import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';

import cx from 'classnames';
import * as _ from 'lodash';

import { ListCard } from '../../components';
import { formatDate } from '../../utils/helpers';

import tables from '../../styles/tables.module.scss';
import layout from '../../styles/layout.module.scss';
import cards from '../../components/lists/ListCard.module.scss';
import text from '../../styles/text.module.scss';
import styles from './ListReports.module.scss';
import { useReports } from '../../utils/hooks';

export default function ListReports () {
  const { orgSlug, id } = useParams();
  const {
    reports,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useReports(id);

  return(
    <div className={cx(layout.responsiveContainer, 'mx-auto')}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!loading && hasMore}
        useWindow={false}
        className="text-center"
      >
        {
          noResults
            ? (
              <p key="zero" className="text-center">
                You don&apos;t have any reports for this production cycle yet.
              </p>
            )
            : _.map(reports, (r) => (
              <ListCard
                key={`report-${r.id}`}
                linkTo={`/app/${orgSlug}/reports/${r.id}`}
                flex
              >
                <FontAwesomeIcon
                  className={styles.statusIcon}
                  icon={r.complete ? 'check-circle' : ['far', 'hourglass']}
                />
                <span className={text.ellipsize}>
                  {formatDate(r.date)}
                </span>
                <i className={cx(cards.detailSubtext, text.subtext)}>
                  {r.case_no}
                </i>
              </ListCard>
            ))
        }
        { loading && (
          <div
            className="text-center"
            key="spinner"
          >
            <Spinner className={tables.spinner} />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}
