import React, { createContext } from 'react';
import cx from 'classnames';

export const RadioGroupContext = createContext(null);

// eslint-disable-next-line no-unused-vars
export const RadioGroup = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    name,
    invalid,
    children,
    disabled,
  } = props;

  return (
    <RadioGroupContext.Provider value={{ value, onChange, name, disabled }}>
      <div className={cx({'is-invalid': invalid})}>
        { children }
      </div>
    </RadioGroupContext.Provider>
  );
});
RadioGroup.displayName = 'RadioGroup';
