import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';

import {
  updateReport,
  setStepTitle,
} from '../../redux/reports/actions';
import {
  MeasurementInput,
  GeneralErrors,
} from '../../components';
import { useReadOnly } from '../../utils/hooks';
import { Measurements } from '../../components/inputs/Measurements';
import sampleLocations from '../../utils/data/sample_locations';

import layout from '../../styles/layout.module.scss';

export default function WaterQualityForm () {
  const readOnly = useReadOnly();
  const history = useHistory();
  const { orgSlug } = useParams();
  const dispatch = useDispatch();
  const currReport = useSelector((s) => s.reports.currReport);
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm({
    defaultValues: currReport,
  });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;

  useEffect(() => {
    dispatch(setStepTitle('Water Quality'));
  }, []);

  const submit = useCallback((data) => {
    dispatch(updateReport({
      ...data,
      max_step_index: 2,
      id: currReport.id,
    }));
  }, [currReport]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      history.push(`/app/${orgSlug}/reports/${currReport.id}/edit/samples`);
    }
  }, [submitSuccess]);

  return (
    <Form
      className={layout.responsiveContainer}
      onSubmit={handleSubmit(submit)}
    >
      <FormGroup>
        <Label for="select">Location of Sample</Label>
        <CustomInput
          id="select"
          innerRef={register}
          type="select"
          name="water_sample_loc"
          className="form-control"
          disabled={readOnly}
        >
          {_.map(
            sampleLocations,
            (location) => (
              <option
                key={`sample-loc-${location.value}`}
                value={location.value}
              >
                {location.text}
              </option>
            ),
          )}
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Label>Temperature</Label>
        <Controller
          as={MeasurementInput}
          type={Measurements.TEMPERATURE}
          name="water_temp"
          control={control}
          invalid={ isSubmitted && _.has(error, 'water_temp') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_temp') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>DO (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_do"
          invalid={ isSubmitted && _.has(error, 'water_do') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_do') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Alkalinity (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_alkalinity"
          invalid={ isSubmitted && _.has(error, 'water_alkalinity') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_alkalinity') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Hardness (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_hardness"
          invalid={ isSubmitted && _.has(error, 'water_hardness') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_hardness') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>pH</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_ph"
          invalid={ isSubmitted && _.has(error, 'water_ph') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_ph') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Total Ammonia (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_total_amonia"
          invalid={ isSubmitted && _.has(error, 'water_total_amonia') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_total_amonia') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Un-ionized (Toxic) Ammonia (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_toxic_amonia"
          invalid={ isSubmitted && _.has(error, 'water_toxic_amonia') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_toxic_amonia') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Nitrite (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_nitrite"
          invalid={ isSubmitted && _.has(error, 'water_nitrite') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_nitrite') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Chloride (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_chloride"
          invalid={ isSubmitted && _.has(error, 'water_chloride') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_chloride') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Nitrate (ppm)</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_nitrate"
          invalid={ isSubmitted && _.has(error, 'water_nitrate') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_nitrate') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Other</Label>
        <Input
          innerRef={register}
          type="text"
          name="water_other"
          invalid={ isSubmitted && _.has(error, 'water_other') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'water_other') }
        </FormFeedback>
      </FormGroup>
      { isSubmitted && <GeneralErrors error={error} /> }
      { !readOnly && (
        <Button
          color="primary"
          disabled={pending}
          block
        >
          Save and Continue{' '}
          { pending && <Spinner size="sm" /> }
        </Button>
      )}
      <Link
        className="btn btn-secondary btn-block"
        to={`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`}
      >
        Cancel
      </Link>
    </Form>
  );
}
