import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Spinner,
} from 'reactstrap';
import cx from 'classnames';

import {
  updateReport,
  setStepTitle,
} from '../../redux/reports/actions';
import {
  CreateDrawer,
  CreateDrawerItem,
  ListCard,
} from '../../components';
import { useReadOnly } from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import steps from './ReportSteps.module.scss';
import styles from './Report.module.scss';

export default function FishSamples () {
  const { orgSlug } = useParams();
  const readOnly = useReadOnly();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const currReport = useSelector((s) => s.reports.currReport);

  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;

  useEffect(() => {
    dispatch(setStepTitle('Fish Samples'));
  }, []);

  const saveMaxStep = useCallback(() => {
    dispatch(updateReport({
      max_step_index: 3,
      id: currReport.id,
    }));
    setIsSubmitted(true);
  }, [currReport]);

  const gotoCreateSample = useCallback(() => {
    history.push(`/app/${orgSlug}/reports/${currReport.id}/create-sample`);
  }, [history, currReport]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      history.push(`/app/${orgSlug}/reports/${currReport.id}/edit/final-diagnosis`);
    }
  }, [submitSuccess]);

  return (
    <div className={cx(
      layout.responsiveContainer,
      layout.responsiveContainerButtonsBottom,
      layout.responsiveContainerMargin,
    )}>
      { !readOnly && (
        <CreateDrawer className={cx(
          steps.drawerSteps,
          styles.drawerMovedUp,
        )}>
          <CreateDrawerItem
            label="Add Sample"
            icon="fish"
            onClick={() => gotoCreateSample()}
          />
        </CreateDrawer>
      )}
      {!readOnly && (
        <p className="text-center my-3">
          Add fish samples below.  When all samples are accounted for, click Next to continue.
        </p>
      )}
      {readOnly && currReport.samples.length == 0 && (
        <p className="text-center my-3">
          This report doesn&apos;t have any fish samples.
        </p>
      )}
      {_.map(
        currReport.samples,
        (s) => (
          <ListCard
            className='my-1'
            key={`sample-${s.id}`}
            linkTo={`/app/${orgSlug}/samples/${s.id}`}
          >
            ID: {s.fish_id}
          </ListCard>
        ),
      )}
      <div className={styles.buttonsBottom}>
        { !readOnly && (
          <Button
            className='mt-1'
            color="primary"
            disabled={pending}
            onClick={saveMaxStep}
            block
          >
            Save and Continue{' '}
            { pending && <Spinner size="sm" /> }
          </Button>
        )}
        <Link
          className="btn btn-secondary btn-block"
          to={`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`}
        >
          Cancel
        </Link>
      </div>
    </div>
  );
}
