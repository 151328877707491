import {
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_SAMPLES,
  LOAD_SAMPLES_SUCCESS,
  LOAD_SAMPLES_ERROR,
  CREATE_SAMPLE,
  UPDATE_SAMPLE,
  CREATE_SAMPLE_SUCCESS,
  UPDATE_SAMPLE_SUCCESS,
  CREATE_SAMPLE_ERROR,
  UPDATE_SAMPLE_ERROR,
  DELETE_SAMPLE,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_ERROR,
  GET_CURR_SAMPLE,
  GET_CURR_SAMPLE_SUCCESS,
  GET_CURR_SAMPLE_ERROR,
  LOAD_SAMPLE_MEDIA,
  LOAD_SAMPLE_MEDIA_SUCCESS,
  LOAD_SAMPLE_MEDIA_ERROR,
  CREATE_SAMPLE_MEDIA,
  CREATE_SAMPLE_MEDIA_PROGRESS,
  CREATE_SAMPLE_MEDIA_SUCCESS,
  CREATE_SAMPLE_MEDIA_ERROR,
  DELETE_SAMPLE_MEDIA,
  DELETE_SAMPLE_MEDIA_SUCCESS,
  DELETE_SAMPLE_MEDIA_ERROR,
  CANCEL_SAMPLE_UPLOAD,
  LOAD_ISOLATES,
  LOAD_ISOLATES_SUCCESS,
  LOAD_ISOLATES_ERROR,
  CREATE_ISOLATE,
  UPDATE_ISOLATE,
  CREATE_ISOLATE_SUCCESS,
  UPDATE_ISOLATE_SUCCESS,
  CREATE_ISOLATE_ERROR,
  UPDATE_ISOLATE_ERROR,
  DELETE_ISOLATE,
  DELETE_ISOLATE_SUCCESS,
  DELETE_ISOLATE_ERROR,
  GET_CURR_ISOLATE,
  GET_CURR_ISOLATE_SUCCESS,
  GET_CURR_ISOLATE_ERROR,
  LOAD_CLINICAL_SIGNS,
  LOAD_CLINICAL_SIGNS_SUCCESS,
  LOAD_CLINICAL_SIGNS_ERROR,
  CREATE_CLINICAL_SIGN,
  UPDATE_CLINICAL_SIGN,
  CREATE_CLINICAL_SIGN_SUCCESS,
  UPDATE_CLINICAL_SIGN_SUCCESS,
  CREATE_CLINICAL_SIGN_ERROR,
  UPDATE_CLINICAL_SIGN_ERROR,
  DELETE_CLINICAL_SIGN,
  DELETE_CLINICAL_SIGN_SUCCESS,
  DELETE_CLINICAL_SIGN_ERROR,
  GET_CURR_CLINICAL_SIGN,
  GET_CURR_CLINICAL_SIGN_SUCCESS,
  GET_CURR_CLINICAL_SIGN_ERROR,
} from './actions';
import api from '../../utils/api';
import {
  minDelayCall,
  createUploadChannel,
} from '../helpers';

const PAGE_SIZE = 20;

export function* loadSamples (action) {
  const { id, page } = action;

  const params = {
    prod_cycle: id,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield minDelayCall(api.loadSamples, params);

    yield put({ type: LOAD_SAMPLES_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_SAMPLES_ERROR, error: error.response.data });
  }
}

export function* createSample ({ formData }) {
  try {
    const sample = yield minDelayCall(api.createSample, formData);

    yield put({ type: CREATE_SAMPLE_SUCCESS, sample });
  } catch (error) {
    yield put({ type: CREATE_SAMPLE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateSample ({ formData }) {
  try {
    const sample = yield minDelayCall(api.updateSample, formData);

    yield put({ type: UPDATE_SAMPLE_SUCCESS, sample });
  } catch (error) {
    yield put({ type: UPDATE_SAMPLE_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrSample ({ id }) {
  try {
    const sample = yield minDelayCall(api.getSample, id);

    yield put({ type: GET_CURR_SAMPLE_SUCCESS, sample });
  } catch (error) {
    yield put({ type: GET_CURR_SAMPLE_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteSample (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteSample, id);

    yield put({ type: DELETE_SAMPLE_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_SAMPLE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadSampleMedia (action) {
  const { sampleId, page } = action;

  const params = {
    sample: sampleId,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield call(api.loadSampleMedia, params);

    yield put({ type: LOAD_SAMPLE_MEDIA_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_SAMPLE_MEDIA_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* watchForCancel (targetUploadId, channel) {
  while (true) {
    const { uploadId, type } = yield take([
      CANCEL_SAMPLE_UPLOAD,
      CREATE_SAMPLE_MEDIA_SUCCESS,
      CREATE_SAMPLE_MEDIA_ERROR,
    ]);

    if (uploadId == targetUploadId) {
      if (type == CANCEL_SAMPLE_UPLOAD) {
        channel.close();
      }

      // Exit loop
      return;
    }

  }
}

export function* createSampleMedia (action) {
  const { sampleId, file, uploadId } = action;

  const formData = new FormData();
  formData.append('sample', sampleId);
  formData.append('media', file);
  formData.append('name', file.name);
  formData.append('mime_type', file.type);

  // Channel to monitor upload progress
  const channel = yield call(
    createUploadChannel,
    api.createSampleMedia,
    formData,
  );

  // Asynchronously listen for cancel action to close channel
  yield fork(watchForCancel, uploadId, channel);

  try {
    while (true) {
      const {
        progress = 0,
        error,
        response,
      } = yield take(channel);

      if (response) {
        yield put({
          ...action,
          type: CREATE_SAMPLE_MEDIA_SUCCESS,
          media: response,
        });

        continue;
      }

      if (error) {
        yield put({
          ...action,
          type: CREATE_SAMPLE_MEDIA_ERROR,
          error: _.get(error, 'response.data'),
        });

        continue;
      }

      yield put({
        ...action,
        type: CREATE_SAMPLE_MEDIA_PROGRESS,
        progress,
      });
    }
  } finally {
    // The channel most likely closed itself, but this handles accidental
    // usage of takeLatest
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* deleteSampleMedia (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteSampleMedia, id);

    yield put({ type: DELETE_SAMPLE_MEDIA_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_SAMPLE_MEDIA_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadIsolates (action) {
  const { id, page } = action;

  const params = {
    sample: id,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield minDelayCall(api.loadIsolates, params);

    yield put({ type: LOAD_ISOLATES_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_ISOLATES_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* createIsolate ({ formData }) {
  try {
    const isolate = yield minDelayCall(api.createIsolate, formData);

    yield put({ type: CREATE_ISOLATE_SUCCESS, isolate });
  } catch (error) {
    yield put({ type: CREATE_ISOLATE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateIsolate ({ formData }) {
  try {
    const isolate = yield minDelayCall(api.updateIsolate, formData);

    yield put({ type: UPDATE_ISOLATE_SUCCESS, isolate });
  } catch (error) {
    yield put({ type: UPDATE_ISOLATE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* deleteIsolate (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteIsolate, id);

    yield put({ type: DELETE_ISOLATE_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_ISOLATE_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrIsolate ({ id }) {
  try {
    const isolate = yield minDelayCall(api.getIsolate, id);

    yield put({ type: GET_CURR_ISOLATE_SUCCESS, isolate });
  } catch (error) {
    yield put({ type: GET_CURR_ISOLATE_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* loadClinicalSigns (action) {
  const { id, page } = action;

  const params = {
    sample: id,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield minDelayCall(api.loadClinicalSigns, params);

    yield put({ type: LOAD_CLINICAL_SIGNS_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_CLINICAL_SIGNS_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* createClinicalSign ({ formData }) {
  try {
    const clinicalSign = yield minDelayCall(api.createClinicalSign, formData);

    yield put({ type: CREATE_CLINICAL_SIGN_SUCCESS, clinicalSign });
  } catch (error) {
    yield put({ type: CREATE_CLINICAL_SIGN_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateClinicalSign ({ formData }) {
  try {
    const clinicalSign = yield minDelayCall(api.updateClinicalSign, formData);

    yield put({ type: UPDATE_CLINICAL_SIGN_SUCCESS, clinicalSign });
  } catch (error) {
    yield put({ type: UPDATE_CLINICAL_SIGN_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* deleteClinicalSign (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteClinicalSign, id);

    yield put({ type: DELETE_CLINICAL_SIGN_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_CLINICAL_SIGN_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrClinicalSign ({ id }) {
  try {
    const clinicalSign = yield minDelayCall(api.getClinicalSign, id);

    yield put({ type: GET_CURR_CLINICAL_SIGN_SUCCESS, clinicalSign });
  } catch (error) {
    yield put({ type: GET_CURR_CLINICAL_SIGN_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_SAMPLES, loadSamples),
    takeLatest(CREATE_SAMPLE, createSample),
    takeLatest(GET_CURR_SAMPLE, getCurrSample),
    takeLatest(UPDATE_SAMPLE, updateSample),
    takeLatest(DELETE_SAMPLE, deleteSample),
    takeLatest(LOAD_SAMPLE_MEDIA, loadSampleMedia),
    takeEvery(CREATE_SAMPLE_MEDIA, createSampleMedia),
    takeLatest(DELETE_SAMPLE_MEDIA, deleteSampleMedia),
    takeLatest(LOAD_ISOLATES, loadIsolates),
    takeEvery(CREATE_ISOLATE, createIsolate),
    takeLatest(UPDATE_ISOLATE, updateIsolate),
    takeLatest(DELETE_ISOLATE, deleteIsolate),
    takeLatest(GET_CURR_ISOLATE, getCurrIsolate),
    takeLatest(LOAD_CLINICAL_SIGNS, loadClinicalSigns),
    takeEvery(CREATE_CLINICAL_SIGN, createClinicalSign),
    takeLatest(UPDATE_CLINICAL_SIGN, updateClinicalSign),
    takeLatest(DELETE_CLINICAL_SIGN, deleteClinicalSign),
    takeLatest(GET_CURR_CLINICAL_SIGN, getCurrClinicalSign),
  ]);
}
