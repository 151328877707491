import React from 'react';
import cx from 'classnames';

import styles from './Tiles.module.scss';

const BaseTileBoard = (props) => {
  const {
    className,
    ...rest
  } = props;

  return (
    <div
      className={cx(styles.tileBoard, className)}
      {...rest}
    ></div>
  );
};

export const TileBoard = React.memo(BaseTileBoard);
