import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getUser } from '../../redux/account/selectors';
import ProfileForm from './ProfileForm';

import layout from '../../styles/layout.module.scss';

export default function Profile () {
  const user = useSelector(getUser);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Profile"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { (!user)
        &&
        <div className="text-center">
          <Spinner />
        </div>
        ||
        <ProfileForm
          user={user}
        />
      }
    </div>
  );
}
