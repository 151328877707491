import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { keyCodes, key } from '../../utils/helpers';
import { useMobile } from '../../utils/hooks';

import styles from './CreateDrawer.module.scss';

export function CreateDrawer (props) {
  const {
    className,
    children,
    drawerLabel,
  } = props;
  const drawerRef = useRef(null);
  const menuRef = useRef(null);
  const toggleRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const dummySpan = useRef(null);
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState({
    defaultItem: null, // The first item in client embed
    desktop: [],       // All items in original order
    mobile: [],        // Items in reverse order excluding defaultItem
  });
  const mobile = useMobile();
  const numChildren = React.Children.toArray(children).length;

  const toggle = useCallback((e) => {
    if (open && e.type != 'click') {
      // Closing via keyboard. Re-focus toggle
      toggleBtnRef.current.focus();
    }
    if (!open && e.type == 'click') {
      // Opening via click. Focus placeholder
      dummySpan.current.focus();
    }

    setOpen(!open);
  }, [open, menuItems, mobile]);

  const handleDrawerKey = useCallback((e) => {
    const which = key(e);
    let focusDefault = false;

    if (open && e.target.getAttribute('role') === 'menuitem') {
      // Arrows up / down change focus
      if (_.includes([keyCodes.up, keyCodes.down], which)) {
        const items = [].slice.call(drawerRef.current.querySelectorAll('[role="menuitem"]'));
        let index = _.indexOf(items, e.target);
        if (which == keyCodes.up) {
          index = index !== 0 ? index - 1 : items.length - 1;
        } else {
          index = index === items.length - 1 ? 0 : index + 1;
        }
        items[index].focus();
      } else if (_.includes([keyCodes.enter, keyCodes.space], which)) {
        // User interaction triggers menu item click
        e.target.click();
      } else if (_.includes([keyCodes.tab, keyCodes.esc], which)) {
        // Close drawer
        toggle(e);
        toggleBtnRef.current.focus();
      }
    } else if(
      e.target == toggleBtnRef.current &&
      !open && _.includes([keyCodes.enter, keyCodes.space], which)
    ) {
      focusDefault = true;
    } else if (e.target == dummySpan.current) {
      // We can assume we're open.
      if (
        which == keyCodes.esc ||
        (which == keyCodes.tab && e.shiftKey)
      ) {
        // Tab back closes drawer
        toggle(e);
      } else if (which == keyCodes.tab) {
        // Tab forward focuses default
        e.preventDefault();
        focusDefault = true;
      }
    }

    if (focusDefault) {
      let defaultItem = drawerRef.current.querySelector(`.${styles.defaultItem}`);
      if (!mobile) {
        defaultItem = menuRef.current.querySelector("[role='menuitem']:first-child");
      }
      setTimeout(() => _.invoke(defaultItem, 'focus'));
    }
  }, [open, toggle]);

  // Process children for different render states
  useEffect(() => {
    if (numChildren > 1) {
      const [
        firstChild,
        ...allButFirstChild
      ] = React.Children.toArray(children);

      setMenuItems({
        defaultItem: firstChild,
        desktop: children,
        mobile: _.reverse(allButFirstChild),
      });
    } else {
      setMenuItems({
        defaultItem: React.Children.toArray(children)[0],
        desktop: [],
        mobile: [],
      });
    }

  }, [children]);

  // Close drawer when document is clicked
  useEffect(() => {
    if (open) {
      document.addEventListener('click', toggle);
    } else {
      document.removeEventListener('click', toggle);
    }

    return () => document.removeEventListener('click', toggle);
  }, [open]);

  const onToggleClick = useCallback((e) => {
    if (
      numChildren <= 1 ||
      (open && mobile && e.target == toggleRef.current)
    ) {
      _.invoke(menuItems.defaultItem, 'props.onClick');
    } else if (!open) {
      toggle(e);
    }
  }, [numChildren, mobile, open, menuItems]);

  const label = numChildren <= 1
    ? _.get(menuItems, 'defaultItem.props.label', 'New')
    : 'New';

  return (
    <div
      ref={drawerRef}
      className={cx(
        className,
        styles.drawer,
        { [styles.drawerOpen]: open },
      )}
      role="menu"
      tabIndex={-1}
      onKeyDown={handleDrawerKey}
    >
      <span
        ref={dummySpan}
        tabIndex={-1}
      ></span>
      <div
        ref={toggleRef}
        className={styles.toggle}
        onClick={mobile && open ? onToggleClick : undefined}
        onKeyDown={undefined}
        tabIndex={-1}
        role={mobile && open ? 'button' : undefined}
      >
        {menuItems.defaultItem && mobile && (
          React.cloneElement(menuItems.defaultItem, {
            className: styles.defaultItem,
          })
        )}
        <Button
          innerRef={toggleBtnRef}
          color={open && mobile ? 'white' : 'primary'}
          size={mobile ? 'circle' : 'round'}
          onClick={!open ? onToggleClick : undefined}
          tabIndex={open ? -1 : 0}
          aria-label={!open ? 'Expand menu' : undefined}
        >
          <FontAwesomeIcon icon="plus"/> {mobile ? '' : drawerLabel || label}
        </Button>
      </div>
      <div
        ref={menuRef}
        className={styles.menu}
      >
        { mobile ? menuItems.mobile : menuItems.desktop }
      </div>
    </div>
  );
}
CreateDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};
