import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import nav from './nav/reducer';
import account from './account/reducer';
import orgs from './orgs/reducer';
import users from './users/reducer';
import clients from './clients/reducer';
import waterBodies from './waterbodies/reducer';
import prodCycles from './prodcycles/reducer';
import search from './search/reducer';
import activities from './activities/reducer';
import reports from './reports/reducer';
import samples from './samples/reducer';

import accountSagas from './account/sagas';
import orgsSagas from './orgs/sagas';
import usersSagas from './users/sagas';
import clientsSagas from './clients/sagas';
import waterBodiesSagas from './waterbodies/sagas';
import prodCycleSagas from './prodcycles/sagas';
import searchSagas from './search/sagas';
import activitiesSagas from './activities/sagas';
import reportsSagas from './reports/sagas';
import samplesSagas from './samples/sagas';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  nav,
  account,
  orgs,
  users,
  clients,
  waterBodies,
  prodCycles,
  search,
  activities,
  reports,
  samples,
});

const middleware = [
  applyMiddleware(sagaMiddleware),
  ...(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : []
  ),
];

export const store = createStore(
  rootReducer,
  compose(...middleware),
);

sagaMiddleware.run(accountSagas);
sagaMiddleware.run(orgsSagas);
sagaMiddleware.run(usersSagas);
sagaMiddleware.run(clientsSagas);
sagaMiddleware.run(waterBodiesSagas);
sagaMiddleware.run(prodCycleSagas);
sagaMiddleware.run(searchSagas);
sagaMiddleware.run(activitiesSagas);
sagaMiddleware.run(reportsSagas);
sagaMiddleware.run(samplesSagas);
