import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import * as _ from 'lodash';

import { GeneralErrors } from '../components';

export function ConfirmActionModal ({
  open,
  toggle,
  action,
  subjectType,
  subject,
  onConfirm,
  onSuccess,
  pendingPath,
  errorPath,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const pending = useSelector((s) => _.get(s, pendingPath));
  const error = useSelector((s) => _.get(s, errorPath));
  const actionSuccess = isSubmitted && !pending && !error;

  const doAction = useCallback(() => {
    onConfirm();
    setIsSubmitted(true);
  }, [onConfirm]);

  useEffect(() => {
    if (actionSuccess) {
      setIsSubmitted(false);

      if (onSuccess) {
        onSuccess();
      }
      toggle();
    }
  }, [actionSuccess, toggle]);

  useEffect(() => {
    if (!open) {
      setIsSubmitted(false);
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      backdrop={pending ? 'static' : true}
    >
      <ModalHeader>
        {_.capitalize(action)} {_.startCase(subjectType)}?
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p>
            Are you sure that you want to {_.toLower(action)} <b>{subject}</b>?
          </p>
          { action == 'delete' && (
            <p>
              This action cannot be undone and will permanently delete all
              nested data.
            </p>
          ) }
          { isSubmitted && <GeneralErrors error={error} /> }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          disabled={pending}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={doAction}
          disabled={pending}
        >
          {_.capitalize(action)}
          { pending && <Spinner size="sm" /> }
        </Button>
      </ModalFooter>
    </Modal>
  );
}
