import React, { useCallback } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackButton,
  PageHeader,
  KebabDropdown,
} from '../../components';
import NotFound from '../NotFound';
import { useRole, useConfirmAction } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import ReportSteps from './ReportSteps';
import ReportInfoForm from './ReportInfoForm';
import FishInfoForm from './FishInfoForm';
import WaterQualityForm from './WaterQualityForm';
import FishSamples from './FishSamples';
import FinalDiagnosis from './FinalDiagnosis';

import layout from '../../styles/layout.module.scss';
import { deleteReport } from '../../redux/reports/actions';

export default function EditReport () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const role = useRole();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const stepTitle = useSelector((s) => s.reports.stepTitle);
  const currReport = useSelector((s) => s.reports.currReport);
  const pending = useSelector((s) => s.reports.getCurrReportPending);
  const confirmAction = useConfirmAction();

  const removeReport = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'report',
      subject: 'this report',
      onConfirm: () => dispatch(deleteReport(id)),
      pendingPath: 'reports.deleteReportPending',
      errorPath: 'reports.deleteReportError',
      onSuccess: () => {
        history.replace(`/app/${orgSlug}/prod-cycles/${currReport.prod_cycle}`);
      },
    });
  }, [currOrg, currReport, confirmAction]);

  return (
    <div className={layout.page}>
      <PageHeader
        title={stepTitle}
        coverMenu
      >
        <BackButton/>
        { role != 'read' && (
          <KebabDropdown right>
            <DropdownItem onClick={removeReport}>
              <FontAwesomeIcon icon="trash" />
              Delete Report
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      <div className={layout.pageContent}>
        {(!currReport || pending)
          ?
          <div className="text-center">
            <Spinner />
          </div>
          :
          <React.Fragment>
            <ReportSteps />
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${location.pathname}/info`} />
              </Route>
              <Route exact path={`${path}/info`}>
                <ReportInfoForm report={currReport} />
              </Route>
              <Route exact path={`${path}/fish-info`}>
                <FishInfoForm />
              </Route>
              <Route exact path={`${path}/water-quality`}>
                <WaterQualityForm />
              </Route>
              <Route exact path={`${path}/samples`}>
                <FishSamples />
              </Route>
              <Route exact path={`${path}/final-diagnosis`}>
                <FinalDiagnosis />
              </Route>
              <Route path={`${path}/*`}>
                <NotFound />
              </Route>
            </Switch>
          </React.Fragment>
        }
      </div>
    </div>
  );
}
