import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';

import { editUser } from '../../redux/account/actions';
import layout from '../../styles/layout.module.scss';
import { GeneralErrors } from '../../components';

export default function ProfileForm ({ user }) {
  const {
    register,
    handleSubmit,
    formState,
    watch,
    reset,
  } = useForm({ defaultValues: user || {} });
  const { isSubmitted } = formState;
  const dispatch = useDispatch();
  const pending = useSelector((s) => s.account.editUserPending);
  const error = useSelector((s) => s.account.editUserError);
  const submitSuccess = isSubmitted && !pending && !error;
  const formData = watch();

  const submit = useCallback((formData) => {
    dispatch(editUser(_.omitBy(
      formData,
      (val, key) => _.includes(['new_password1', 'new_password2'], key) && !val,
    )));
  }, []);

  const canUpdate = useCallback(() => {
    return (
      !_.isMatch(user, _.pick(formData, ['name', 'email'])) ||
      !!formData.new_password1
    );
  }, [user, formData]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      reset(_.pick(user, ['name', 'email']));
    }
  }, [submitSuccess, reset, user]);

  return (
    <div className={layout.pageContent}>
      <Form
        className={layout.responsiveContainer}
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup>
          <Label>Name</Label>
          <Input
            required
            innerRef={register}
            type="text"
            name="name"
            placeholder="Name"
            invalid={ isSubmitted && _.has(error, 'name') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'name') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            required
            innerRef={register}
            type="text"
            name="email"
            placeholder="Email"
            invalid={ isSubmitted && _.has(error, 'email') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'email') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            innerRef={register}
            type="password"
            name="new_password1"
            placeholder="Password"
            autoComplete="new-password"
            invalid={ isSubmitted && _.has(error, 'new_password1') }
          />
          <FormFeedback role="alert">
            { _.get(error, 'new_password1') }
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Confirm Password</Label>
          <Input
            required
            innerRef={register}
            type="password"
            name="new_password2"
            placeholder="Confirm Password"
            autoComplete="new-password"
            invalid={ isSubmitted && _.has(error, 'new_password2') }
            disabled={ !formData.new_password1 }
          />
          <FormFeedback role="alert">
            { _.get(error, 'new_password2') }
          </FormFeedback>
        </FormGroup>
        { isSubmitted &&
          <GeneralErrors
            error={error}
            includeKeys={['invite']}
          />
        }
        <Button
          color="primary"
          disabled={!canUpdate()}
          block
        >
          Update Profile
          {pending &&
            <Spinner size="sm" />
          }
        </Button>
      </Form>
    </div>
  );
}
