import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, DropdownItem } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { getMiscDetails } from '../../redux/search/selectors';
import {
  loadMiscDetails,
  RESET_LOAD_MISC_DETAILS,
} from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';

import multiselect from './Multiselect.module.scss';
import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const MiscDetailsSelect = React.forwardRef((props, ref) => {
  const { value, onChange, invalid, disabled } = props;
  const dispatch = useDispatch();
  const details = useSelector(getMiscDetails);
  const currPage = useSelector((s) => s.search.currMiscDetailsPage);
  const hasMore = useSelector((s) => s.search.hasMoreMiscDetails);
  const loading = useSelector((s) => s.search.loadMiscDetailsPending);
  const valueLookup = _.keyBy(value, (b) => b.id);
  const displayText = _.join(
    _.map(value, (b) => b.name),
    ', ',
  );

  const onRemoveMiscDetail = useCallback((entry) => {
    onChange(_.filter(value, (b) => b.id != entry.id));
  }, [onChange, value]);

  const onOptionClicked = useCallback((b) => {
    if (!_.hasIn(valueLookup, b.id)) {
      onChange([ ...(value || []), b ]);
    } else {
      onRemoveMiscDetail(b);
    }
  }, [onChange, value]);

  const resetMiscDetails = useCallback(() => {
    dispatch(action(RESET_LOAD_MISC_DETAILS));
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadMiscDetails(currPage + 1, query));
  }, [currPage]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder="Select details"
      displayText={displayText}
      firstLoad={resetMiscDetails}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      disabled={disabled}
    >
      { _.get(value, 'length', 0) == 0 && (
        <div className="text-center">
          No details selected
        </div>
      )}
      { _.get(value, 'length', 0) > 0 &&
        _.map(
          value,
          (d) => (
            <DropdownItem
              key={`details-selected-${d.id}`}
              onClick={() => onRemoveMiscDetail(d)}
              className={multiselect.item}
              toggle={false}
            >
              <CustomInput
                type="checkbox"
                id={`details-selected-${d.id}`}
                className={multiselect.checkbox}
                checked
                readOnly
              />
              <div className={cx(text.ellipsize, multiselect.content)}>
                { d.name }
              </div>
            </DropdownItem>
          ),
        )
      }
      <DropdownItem divider/>
      { !loading && _.get(details, 'length', 0) == 0 && (
        <div className="text-center">
          No details
        </div>
      )}
      { _.map(
        details,
        (d) => (
          <DropdownItem
            key={`details-${d.id}`}
            onClick={() => onOptionClicked(d)}
            className={multiselect.item}
            toggle={false}
          >
            <CustomInput
              type="checkbox"
              id={`details-check-${d.id}`}
              className={multiselect.checkbox}
              checked={_.hasIn(valueLookup, d.id)}
              readOnly
            />
            <div className={cx(text.ellipsize, multiselect.content)}>
              { d.name }
            </div>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
MiscDetailsSelect.displayName = 'MiscDetailsSelect';
