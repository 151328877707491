import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { getEditUser } from '../../redux/users/actions';
import UserForm from './UserForm';

import layout from '../../styles/layout.module.scss';

export default function EditUser () {
  const dispatch = useDispatch();
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const editUser = useSelector((s) => s.users.editUser);
  const getEditUserPending = useSelector((s) => s.users.getEditUserPending);
  const getEditUserError = useSelector((s) => s.users.getEditUserError);

  // Load editUser if not already
  useEffect(() => {
    if (currOrg && _.get(editUser, 'id') != id) {
      dispatch(getEditUser(currOrg.slug, id));
      setStartedLoading(true);
    }
  }, [currOrg, editUser, id]);

  // Failed to load user. Redirect
  useEffect(() => {
    if (startedLoading && getEditUserError) {
      history.replace(`/app/${orgSlug}/users`);
    }
  }, [startedLoading, getEditUserError, history]);

  const gotoUsers = useCallback(() => {
    history.replace(`/app/${currOrg.slug}/users`);
  }, [currOrg]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit User"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { (!editUser || getEditUserPending)
        &&
        <div className="text-center">
          <Spinner />
        </div>
        ||
        <UserForm
          onSubmit={gotoUsers}
          user={editUser}
        />
      }
    </div>
  );
}
