import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_ACTIVITIES,
  LOAD_ACTIVITIES_SUCCESS,
  LOAD_ACTIVITIES_ERROR,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  CREATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_ERROR,
  UPDATE_ACTIVITY_ERROR,
  GET_CURR_ACTIVITY,
  GET_CURR_ACTIVITY_SUCCESS,
  GET_CURR_ACTIVITY_ERROR,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export function* loadActivities (action) {
  const { id, page } = action;

  const params = {
    prod_cycle: id,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield minDelayCall(api.loadActivities, params);

    yield put({ type: LOAD_ACTIVITIES_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_ACTIVITIES_ERROR, error: error.response.data });
  }
}

export function* createActivity ({ formData }) {
  try {
    const activity = yield minDelayCall(api.createActivity, formData);

    yield put({ type: CREATE_ACTIVITY_SUCCESS, activity });
  } catch (error) {
    yield put({ type: CREATE_ACTIVITY_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateActivity ({ formData }) {
  try {
    const activity = yield minDelayCall(api.updateActivity, formData);

    yield put({ type: UPDATE_ACTIVITY_SUCCESS, activity });
  } catch (error) {
    yield put({ type: UPDATE_ACTIVITY_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrActivity ({ id }) {
  try {
    const activity = yield minDelayCall(api.getActivity, id);

    yield put({ type: GET_CURR_ACTIVITY_SUCCESS, activity });
  } catch (error) {
    yield put({ type: GET_CURR_ACTIVITY_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteActivity (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteActivity, id);

    yield put({ type: DELETE_ACTIVITY_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_ACTIVITY_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_ACTIVITIES, loadActivities),
    takeLatest(CREATE_ACTIVITY, createActivity),
    takeLatest(GET_CURR_ACTIVITY, getCurrActivity),
    takeLatest(UPDATE_ACTIVITY, updateActivity),
    takeLatest(DELETE_ACTIVITY, deleteActivity),
  ]);
}
