import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { GeneralErrors } from '../../components';

import styles from './FishCountInput.module.scss';

// eslint-disable-next-line no-unused-vars
const BaseFishCountInput = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    error,
    className,
    label,
    disabled,
  } = props;
  const fish_count = {
    value: '',
    small_percent: 0,
    medium_percent: 0,
    large_percent: 0,
    ...value,
  };

  return (
    <div className={className}>
      <FormGroup>
        <Label>{label}</Label>
        <Input
          type="text"
          value={Math.abs(fish_count.value) || ''}
          onChange={(e) => onChange({ ...fish_count, value: e.target.value })}
          autoComplete="off"
          placeholder="Number of Fish"
          invalid={_.has(error, 'value')}
          disabled={disabled}
        />
        <FormFeedback role="alert">
          { _.get(error, 'value') }
        </FormFeedback>
      </FormGroup>
      <div className={styles.density}>
        <div className={cx(
          styles.input,
          { 'is-invalid': _.has(error, 'small_percent') },
        )}>
          <Label className={styles.inputLabel}>
            Fingerlings (&lt; 7&quot;)
          </Label>
          <Input
            type="text"
            className={styles.inputText}
            value={fish_count.small_percent}
            onChange={(e) => onChange({ ...fish_count, small_percent: e.target.value })}
            autoComplete="off"
            invalid={_.has(error, 'small_percent')}
            disabled={disabled}
          />
          <FontAwesomeIcon
            className={styles.icon}
            icon="percent"
          />
        </div>
        <FormFeedback role="alert">
          { _.get(error, 'small_percent') }
        </FormFeedback>
      </div>
      <div className={styles.density}>
        <div className={cx(
          styles.input,
          { 'is-invalid': _.has(error, 'medium_percent') },
        )}>
          <Label className={styles.inputLabel}>
            Stockers (7-12&quot;)
          </Label>
          <Input
            type="text"
            className={styles.inputText}
            value={fish_count.medium_percent}
            onChange={(e) => onChange({ ...fish_count, medium_percent: e.target.value })}
            autoComplete="off"
            invalid={_.has(error, 'medium_percent')}
            disabled={disabled}
          />
          <FontAwesomeIcon
            className={styles.icon}
            icon="percent"
          />
        </div>
        <FormFeedback role="alert">
          { _.get(error, 'medium_percent') }
        </FormFeedback>
      </div>
      <div className={styles.density}>
        <div className={cx(
          styles.input,
          { 'is-invalid': _.has(error, 'large_percent') },
        )}>
          <Label className={styles.inputLabel}>
            Foodfish (&ge; 13&quot;)
          </Label>
          <Input
            type="text"
            className={styles.inputText}
            value={fish_count.large_percent}
            onChange={(e) => onChange({ ...fish_count, large_percent: e.target.value })}
            autoComplete="off"
            invalid={_.has(error, 'large_percent')}
            disabled={disabled}
          />
          <FontAwesomeIcon
            className={styles.icon}
            icon="percent"
          />
        </div>
        <FormFeedback role="alert">
          { _.get(error, 'large_percent') }
        </FormFeedback>
      </div>
      {error && <GeneralErrors error={error} />}
    </div>
  );
});
BaseFishCountInput.displayName = 'FishCountInput';
BaseFishCountInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
};
BaseFishCountInput.defaultProps = {
  label: 'Number of Fish',
};

export const FishCountInput = React.memo(BaseFishCountInput);
