import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, DropdownItem } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { getPathogens } from '../../redux/search/selectors';
import {
  loadPathogens,
  RESET_LOAD_PATHOGENS,
} from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';

import multiselect from './Multiselect.module.scss';
import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const PathogenMultiselect = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    invalid,
    filter,
    placeholder,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const pathogen = useSelector(getPathogens);
  const currPage = useSelector((s) => s.search.currPathogensPage);
  const hasMore = useSelector((s) => s.search.hasMorePathogens);
  const loading = useSelector((s) => s.search.loadPathogensPending);
  const valueLookup = _.keyBy(value, (b) => b.id);
  const displayText = _.join(
    _.map(value, (b) => b.name),
    ', ',
  );

  const onRemovePathogen = useCallback((entry) => {
    onChange(_.filter(value, (b) => b.id != entry.id));
  }, [onChange, value]);

  const onOptionClicked = useCallback((b) => {
    if (!_.hasIn(valueLookup, b.id)) {
      onChange([ ...(value || []), b ]);
    } else {
      onRemovePathogen(b);
    }
  }, [onChange, value]);

  const resetPathogens = useCallback(() => {
    dispatch(action(RESET_LOAD_PATHOGENS));
  }, []);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadPathogens(currPage + 1, query, filter));
  }, [currPage]);

  return (
    <SearchDropdown
      className={cx({'is-invalid': invalid})}
      placeholder={placeholder}
      displayText={displayText}
      firstLoad={resetPathogens}
      loadMore={loadMore}
      hasMore={hasMore}
      loading={loading}
      onOpen={resetPathogens}
      disabled={disabled}
    >
      { _.get(value, 'length', 0) == 0 && (
        <div className="text-center">
          No pathogen selected
        </div>
      )}
      { _.get(value, 'length', 0) > 0 &&
        _.map(
          value,
          (p) => (
            <DropdownItem
              key={`pathogen-selected-${p.id}`}
              onClick={() => onRemovePathogen(p)}
              className={multiselect.item}
              toggle={false}
            >
              <CustomInput
                type="checkbox"
                id={`pathogen-selected-${p.id}`}
                className={multiselect.checkbox}
                checked
                readOnly
              />
              <div className={multiselect.content}>
                <i className={text.ellipsize}>
                  { p.name }
                </i>
                { p.common_name && (
                  <div className={text.ellipsize}>
                    { p.common_name }
                  </div>
                )}
              </div>
            </DropdownItem>
          ),
        )
      }
      <DropdownItem divider/>
      { !loading && _.get(pathogen, 'length', 0) == 0 && (
        <div className="text-center">
          No pathogen
        </div>
      )}
      { _.map(
        pathogen,
        (p) => (
          <DropdownItem
            key={`pathogen-${p.id}`}
            onClick={() => onOptionClicked(p)}
            className={multiselect.item}
            toggle={false}
          >
            <CustomInput
              type="checkbox"
              id={`pathogen-check-${p.id}`}
              className={multiselect.checkbox}
              checked={_.hasIn(valueLookup, p.id)}
              readOnly
            />
            <div className={multiselect.content}>
              <i className={text.ellipsize}>
                { p.name }
              </i>
              { p.common_name && (
                <div className={text.ellipsize}>
                  { p.common_name }
                </div>
              )}
            </div>
          </DropdownItem>
        ),
      ) }
    </SearchDropdown>
  );
});
PathogenMultiselect.displayName = 'PathogenMultiselect';
PathogenMultiselect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
PathogenMultiselect.defaultProps = {
  placeholder: 'Select Pathogen',
};
