import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Report from './Report';
import NotFound from '../NotFound';

export default function ReportsIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <Report />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
