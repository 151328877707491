import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_ERROR,
  DELETE_INVITE,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_ERROR,
  REMOVE_USER,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
  GET_EDIT_USER,
  GET_EDIT_USER_SUCCESS,
  GET_EDIT_USER_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

function* loadUsers (action) {
  const { slug, page } = action;

  try {
    let invites = null;
    if (page == 1) {
      // Get all pending invites when first page of users loads
      invites = yield call(api.loadInvites, { org__slug: slug });
    }

    const params = {
      organizations__slug: slug,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
    };
    const users = yield minDelayCall(api.loadUsers, params);

    yield put({
      type: LOAD_USERS_SUCCESS,
      users: {
        ...users,
        page,
      },
      invites,
    });
  } catch (error) {
    yield put({ type: LOAD_USERS_ERROR, error: _.get(error, 'response.data') });
  }
}

function* inviteUser (action) {
  const { formData } = action;
  try {
    yield minDelayCall(api.inviteUser, formData);

    yield put({ type: INVITE_USER_SUCCESS });
  } catch (error) {
    yield put({ type: INVITE_USER_ERROR, error: _.get(error, 'response.data') });
  }
}

function* resendInvite (action) {
  const { id } = action;
  try {
    yield minDelayCall(api.resendInvite, id);

    yield put({ type: RESEND_INVITE_SUCCESS, id });
  } catch (error) {
    yield put({ type: RESEND_INVITE_ERROR, id, error: _.get(error, 'response.data') });
  }
}

function* deleteInvite (action) {
  const {  id } = action;
  try {
    yield minDelayCall(api.deleteInvite, id);

    yield put({ type: DELETE_INVITE_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_INVITE_ERROR, id, error: _.get(error, 'response.data') });
  }
}

function* removeUser (action) {
  const { slug, id } = action;
  try {
    yield minDelayCall(api.removeUser, slug, { user: id });

    yield put({ type: REMOVE_USER_SUCCESS, id });
  } catch (error) {
    yield put({ type: REMOVE_USER_ERROR, id, error: _.get(error, 'response.data') });
  }
}

function* setUserRole (action) {
  const { slug, id, role } = action;
  try {
    yield minDelayCall(api.setUserRole, slug, { user: id, role });

    yield put({ type: SET_USER_ROLE_SUCCESS });
  } catch (error) {
    yield put({ type: SET_USER_ROLE_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getEditUser (action) {
  const { slug, id } = action;
  try {
    const user = yield minDelayCall(api.getEditUser, slug, id);

    yield put({ type: GET_EDIT_USER_SUCCESS, user });
  } catch (error) {
    yield put({ type: GET_EDIT_USER_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_USERS, loadUsers),
    takeLatest(INVITE_USER, inviteUser),
    takeLatest(RESEND_INVITE, resendInvite),
    takeLatest(DELETE_INVITE, deleteInvite),
    takeLatest(REMOVE_USER, removeUser),
    takeLatest(SET_USER_ROLE, setUserRole),
    takeLatest(GET_EDIT_USER, getEditUser),
  ]);
}
