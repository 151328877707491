import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';

import {
  BackButton,
  CreateDrawer,
  CreateDrawerItem,
  ListCard,
  PageHeader,
} from '../../components';
import { useClinicalSigns, useReadOnly } from '../../utils/hooks';

import cards from '../../components/lists/ListCard.module.scss';
import layout from '../../styles/layout.module.scss';
import text from '../../styles/text.module.scss';
import tables from '../../styles/tables.module.scss';

export default function ClinicalSigns () {
  const history = useHistory();
  const readOnly = useReadOnly();
  const { orgSlug, id } = useParams();
  const {
    clinicalSigns,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useClinicalSigns(id);

  const gotoCreateClinicalSign = useCallback(() => {
    history.push(`/app/${orgSlug}/samples/${id}/create-clinical-sign`);
  }, [history, orgSlug, id]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Clinical Signs"
        coverMenu
      >
        <BackButton/>
      </PageHeader>
      {!readOnly && (
        <CreateDrawer>
          <CreateDrawerItem
            label="Add Clinical Sign"
            icon="list-ul"
            onClick={gotoCreateClinicalSign}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, layout.pageContentDrawer)}>
        <div className={cx(layout.responsiveContainer, 'text-center')}>
          {noResults
            ? <p>This sample has no clinical signs yet.</p>
            : (
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={!loading && hasMore}
                useWindow={false}
                style={{minWidth: 0}}
              >
                {_.map(clinicalSigns, (clinicalSign) => (
                  <ListCard
                    key={`sign-${clinicalSign.id}`}
                    linkTo={`/app/${orgSlug}/samples/clinical-signs/${clinicalSign.id}`}
                    flex
                  >
                    <span className="flex-grow-1">
                      {clinicalSign.display_name}{clinicalSign.location && ` (${clinicalSign.location})`}
                    </span>
                    <i className={cx(cards.detailSubtext, text.subtext, text.ellipsize)}>
                      {clinicalSign.summary}
                    </i>
                  </ListCard>
                ))}
                { loading && (
                  <div
                    className="text-center"
                    key="spinner"
                  >
                    <Spinner className={cx(tables.spinner, 'mt-3')}  />
                  </div>
                )}
              </InfiniteScroll>
            )
          }
          <Button
            className="mt-3"
            color="secondary"
            onClick={history.goBack}
            block
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
