import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Cookies from 'universal-cookie';

import layout from '../styles/layout.module.scss';
import { LeftNav } from '../components';
import AppIndex from './AppIndex';
import Profile from './account/Profile';
import AcceptInvite from './account/AcceptInvite';
import OrgIndex from './orgs/OrgIndex';
import CreateOrg from './orgs/CreateOrg';
import NotFound from './NotFound';
import { switchOrg } from '../redux/orgs/actions';
import { getCurrOrg } from '../redux/orgs/selectors';
import { LAST_ORG_COOKIE } from '../redux/orgs/sagas';
import { ConfirmActionProvider } from '../utils/hooks/useConfirmAction';

// Component for all authenticated routes
export default function App () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { params: appParams} = useRouteMatch('/app/:orgSlug?');
  const currOrg = useSelector(getCurrOrg);
  const switchOrgError = useSelector((s) => s.orgs.switchOrgError);

  let orgSlug = _.get(appParams, 'orgSlug');
  orgSlug = !_.includes(['profile', 'accept', 'create-org'], orgSlug)
    && orgSlug
    || new Cookies().get(LAST_ORG_COOKIE);

  useEffect(() => {
    if(orgSlug && orgSlug != _.get(currOrg, 'slug')) {
      dispatch(switchOrg(orgSlug));
    }
  }, [ orgSlug ]);

  useEffect(
    () => {
      if (switchOrgError) {
        history.replace('/app');
      }
    },
    [switchOrgError],
  );

  return (
    <div className={layout.app}>
      <LeftNav />
      <ConfirmActionProvider>
        <Switch>
          <Route exact path={path}>
            <AppIndex />
          </Route>
          <Route path={`${path}/profile`}>
            <Profile />
          </Route>
          <Route path={`${path}/accept/:key`}>
            <AcceptInvite />
          </Route>
          <Route path={`${path}/create-org`}>
            <CreateOrg />
          </Route>
          <Route path={`${path}/:orgSlug`}>
            <OrgIndex />
          </Route>
          <Route path={`${path}/*`}>
            <NotFound />
          </Route>
        </Switch>
      </ConfirmActionProvider>
    </div>
  );
}
