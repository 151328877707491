import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClients } from '../../redux/clients/selectors';
import { loadClients, RESET_LOAD_CLIENTS } from '../../redux/clients/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { action } from '../../redux/helpers';

export const useClients = () => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const currOrg = useSelector(getCurrOrg);
  const clients = useSelector(getClients);
  const currPage = useSelector((s) => s.clients.currClientsPage);
  const hasMore = useSelector((s) => s.clients.hasMoreClients);
  const loading = useSelector((s) => s.clients.loadClientsPending);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(clients, 'length', 0) == 0
    );
  }, [loading, hasMore, clients]);

  const reset = useCallback(() => {
    dispatch(action(RESET_LOAD_CLIENTS));
    setResetCalled(true);
  }, [currOrg]);

  const loadMore = useCallback(({ query }) => {
    dispatch(loadClients(currOrg.slug, currPage + 1, query));
  }, [currPage, currOrg]);

  return {
    // Ignore redux list until first load
    clients: firstLoad.current ? clients : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    reset,
    loadMore,
  };
};
