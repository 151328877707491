import React, { Component, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import cx from 'classnames';

import { useMobile } from '../../utils/hooks';
import { onUserInteract } from '../../utils/helpers';

import text from '../../styles/text.module.scss';
import styles from './DateInput.module.scss';

class CustomInput extends Component {
  render () {
    const {
      value,
      onClick,
      placeholderText,
      invalid,
      disabled,
    } = this.props;

    return (
      <div
        className={cx(
          styles.customInput,
          'form-control',
          { disabled, 'is-invalid': invalid },
        )}
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onUserInteract(onClick)}
        role="button"
      >
        <span className={cx(
          styles.inputText,
          text.ellipsize,
          { [styles.placeholder]: !value },
        )}>
          { value || placeholderText }
        </span>
        <FontAwesomeIcon
          className={cx(
            styles.icon,
            { [styles.iconDisabled]: disabled },
          )}
          icon="calendar-alt"
        />
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
export const DateTimeInput = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    disabled,
    invalid,
  } = props;
  const mobile = useMobile();
  const [date, setDate] = useState(value && new Date(value));

  const onDateChange = useCallback((newDate) => {
    setDate(newDate);
    onChange(newDate ? newDate.toISOString() : null);
  }, [setDate]);

  return (
    <div className={cx({'is-invalid': invalid})}>
      <DatePicker
        selected={date}
        onChange={onDateChange}
        customInput={<CustomInput
          placeholderText="MM/DD/YYYY HH:MM AA"
          disabled={disabled}
          invalid={invalid}
        />}
        dateFormat="MM/dd/yyyy h:mm aa"
        disabled={disabled}
        dropdownMode={mobile ? 'select' : 'scroll'}
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={20}
        showTimeInput
        shouldCloseOnSelect={false}
        withPortal={mobile}
        isClearable={!disabled}
        popperPlacement="bottom"
        popperModifiers={{
          flip: {
            behavior: ['bottom'], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view
            // (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
      />
    </div>
  );
});
DateTimeInput.displayName = 'DateTimeInput';
