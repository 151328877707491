import React from 'react';
import PropTypes from 'prop-types';
import { Input, CustomInput } from 'reactstrap';
import cx from 'classnames';
import * as _ from 'lodash';

import { Measurements, UNITS } from './Measurements';

import styles from './MeasurementInput.module.scss';

const BaseMeasurementRangeInput = (props) => {
  const {
    value,
    onChange,
    invalid,
    type,
    unitContext,
  } = props;

  const measurement = value || {
    minValue: '',
    maxValue: '',
    unit: _.get(UNITS, `${type}.${unitContext}[0].value`),
  };

  return (
    <div>
      <div className={cx(styles.measurementInput, 'mb-2')}>
        <Input
          type="number"
          step="0.1"
          value={measurement.minValue}
          min={0}
          onChange={(e) => onChange({ ...measurement, minValue: e.target.value })}
          invalid={invalid}
          autoComplete="off"
          placeholder="Minimum"
        />
        <Input
          type="number"
          step="0.1"
          value={measurement.maxValue}
          min={Number(measurement.minValue)}
          onChange={(e) => onChange({ ...measurement, maxValue: e.target.value })}
          invalid={invalid}
          autoComplete="off"
          placeholder="Maximum"
        />
      </div>
      <CustomInput
        id={`measurement-${type}`}
        type="select"
        className="form-control"
        value={measurement.unit}
        onChange={(e) => onChange({ ...measurement, unit: e.target.value })}
      >
        {
          _.map(
            _.get(UNITS, [type, unitContext], []),
            (u) => (
              <option
                key={`${type}-${u.value}`}
                value={u.value}
              >
                {u.text}
              </option>
            ),
          )
        }
      </CustomInput>
    </div>
  );
};
BaseMeasurementRangeInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  type: PropTypes.oneOf(_.values(Measurements)).isRequired,
  unitContext: PropTypes.string,
};
BaseMeasurementRangeInput.defaultProps = {
  unitContext: 'default',
};

export const MeasurementRangeInput = React.memo(BaseMeasurementRangeInput);
