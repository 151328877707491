export const RESET_LOAD_USERS = 'RESET_LOAD_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const RESEND_INVITE = 'RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_ERROR = 'RESEND_INVITE_ERROR';
export const DELETE_INVITE = 'DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_ERROR = 'DELETE_INVITE_ERROR';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS';
export const SET_USER_ROLE_ERROR = 'SET_USER_ROLE_ERROR';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const GET_EDIT_USER = 'GET_EDIT_USER';
export const GET_EDIT_USER_SUCCESS = 'GET_EDIT_USER_SUCCESS';
export const GET_EDIT_USER_ERROR = 'GET_EDIT_USER_ERROR';

export const loadUsers = (slug, page) => {
  return {
    type: LOAD_USERS,
    slug,
    page,
  };
};

export const inviteUser = (formData) => {
  return {
    type: INVITE_USER,
    formData,
  };
};

export const resendInvite = (id) => {
  return {
    type: RESEND_INVITE,
    id,
  };
};

export const deleteInvite = (id) => {
  return {
    type: DELETE_INVITE,
    id,
  };
};

export const setUserRole = (slug, id, role) => {
  return {
    type: SET_USER_ROLE,
    slug,
    id,
    role,
  };
};

export const removeUser = (slug, id) => {
  return {
    type: REMOVE_USER,
    slug,
    id,
  };
};

export const setEditUser = (user) => {
  return {
    type: SET_EDIT_USER,
    user,
  };
};

export const getEditUser = (slug, id) => {
  return {
    type: GET_EDIT_USER,
    slug,
    id,
  };
};
