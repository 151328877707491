import * as Actions from './actions';
import {
  CREATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
} from '../activities/actions';

import {
  CREATE_REPORT_SUCCESS,
  DELETE_REPORT_SUCCESS,
} from '../reports/actions';

const resetProdCyclesList = {
  prodCycles: [],
  hasMoreProdCycles: true,
  currProdCyclesPage: 0,
};

export const initialState = {
  ...resetProdCyclesList,
  hasMoreProdCycles: false,
  loadProdCyclesPending: false,
  loadProdCyclesError: null,
  currProdCycle: null,
  getCurrProdCyclePending: false,
  getCurrProdCycleError: null,
  changeProdCyclePending: false,
  changeProdCycleError: null,
  deleteProdCyclePending: false,
  deleteProdCycleError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_PROD_CYCLES:
      return {
        ...state,
        ...resetProdCyclesList,
      };
    case Actions.LOAD_PROD_CYCLES:
      return {
        ...state,
        loadProdCyclesError: null,
        loadProdCyclesPending: true,
      };
    case Actions.LOAD_PROD_CYCLES_SUCCESS:
      return {
        ...state,
        loadProdCyclesPending: false,
        prodCycles: [
          ...state.prodCycles,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.prodCycles, 'id'),
        ],
        hasMoreProdCycles: !!action.next,
        currProdCyclesPage: action.page,
      };
    case Actions.LOAD_PROD_CYCLES_ERROR:
      return {
        ...state,
        loadProdCyclesPending: false,
        loadProdCyclesError: action.error,
        hasMoreProdCycles: false,
      };
    case Actions.CREATE_PROD_CYCLE:
    case Actions.UPDATE_PROD_CYCLE:
      return {
        ...state,
        changeProdCycleError: null,
        changeProdCyclePending: true,
      };
    case Actions.CREATE_PROD_CYCLE_SUCCESS:
    case Actions.UPDATE_PROD_CYCLE_SUCCESS:
      return {
        ...state,
        currProdCycle: action.prodCycle,
        changeProdCyclePending: false,
        ...resetProdCyclesList,
      };
    case Actions.CREATE_PROD_CYCLE_ERROR:
    case Actions.UPDATE_PROD_CYCLE_ERROR:
      return {
        ...state,
        changeProdCyclePending: false,
        changeProdCycleError: action.error,
      };
    case Actions.DELETE_PROD_CYCLE:
      return {
        ...state,
        deleteProdCycleError: null,
        deleteProdCyclePending: true,
      };
    case Actions.DELETE_PROD_CYCLE_SUCCESS:
      return {
        ...state,
        deleteProdCyclePending: false,
        ...resetProdCyclesList,
        prodCycles: _.filter(
          state.prodCycles,
          (c) => c.id != action.id,
        ),
      };
    case Actions.DELETE_PROD_CYCLE_ERROR:
      return {
        ...state,
        deleteProdCyclePending: false,
        deleteProdCycleError: action.error,
      };
    case Actions.SET_CURR_PROD_CYCLE:
      return {
        ...state,
        currProdCycle: action.prodCycle,
      };
    case Actions.GET_CURR_PROD_CYCLE:
      return {
        ...state,
        getCurrProdCycleError: null,
        getCurrProdCyclePending: true,
      };
    case Actions.GET_CURR_PROD_CYCLE_SUCCESS:
      return {
        ...state,
        getCurrProdCyclePending: false,
        currProdCycle: action.prodCycle,
      };
    case Actions.GET_CURR_PROD_CYCLE_ERROR:
      return {
        ...state,
        getCurrProdCyclePending: false,
        getCurrProdCycleError: action.error,
      };
    case CREATE_ACTIVITY_SUCCESS:
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        currProdCycle: null,
      };
    case CREATE_REPORT_SUCCESS:
      if (!state.currProdCycle) {
        return state;
      }

      return {
        ...state,
        currProdCycle: {
          ...state.currProdCycle,
          num_reports: state.currProdCycle.num_reports + 1,
        },
      };
    case DELETE_REPORT_SUCCESS:
      if (!state.currProdCycle) {
        return state;
      }

      return {
        ...state,
        currProdCycle: {
          ...state.currProdCycle,
          num_reports: state.currProdCycle.num_reports - 1,
        },
      };
    default:
      return state;
  }
};
