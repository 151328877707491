import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Users from './Users';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import NotFound from '../NotFound';

export default function UsersIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Users />
      </Route>
      <Route path={`${path}/invite`}>
        <CreateUser />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <EditUser />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
