import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownItem,
  Label,
  CustomInput,
  FormFeedback,
  FormGroup,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import cx from 'classnames';

import { getCurrOrg } from '../../redux/orgs/selectors';
import { loadTreatments, RESET_LOAD_TREATMENTS } from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';
import { getTreatments } from '../../redux/search/selectors';
import treatmentTypes from '../../utils/data/treatments';

import styles from './TreatmentInput.module.scss';

// eslint-disable-next-line no-unused-vars
export const PotentialTreatmentInput = React.forwardRef((props, ref) => {
  const {
    value: treatment,
    onChange,
    error,
    className,
    disabled,
  } = props;

  const [selectedType, setSelectedType] = useState(_.get(treatment, 'category'));
  const dispatch = useDispatch();
  const currOrg = useSelector(getCurrOrg);
  const treatments = useSelector(getTreatments);
  const currPage = useSelector((s) => s.search.currTreatmentsPage);
  const hasMore = useSelector((s) => s.search.hasMoreTreatments);
  const loading = useSelector((s) => s.search.loadTreatmentsPending);

  const resetTreatments = useCallback(() => {
    dispatch(action(RESET_LOAD_TREATMENTS));
  }, [currOrg, selectedType]);

  const loadMoreTreatments = useCallback(({ query }) => {
    dispatch(loadTreatments(currPage + 1, query, selectedType));
  }, [currPage, selectedType]);

  const setPotentialTreatment = useCallback((t) => onChange(t), [onChange]);

  const onClear = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setPotentialTreatment(null);
  }, [onChange]);

  return (
    <div className={className}>
      <FormGroup>
        <Label>Treatment Type</Label>
        <CustomInput
          id="treatment-type"
          type="select"
          value={selectedType}
          className="form-control"
          onChange={(e) => (
            setSelectedType(e.target.value),
            setPotentialTreatment(null)
          )}
          disabled={disabled}
        >
          { _.map(
            treatmentTypes,
            (t, index) => (
              <option
                key={`type-${index}`}
                value={t.value}
              >
                {t.text}
              </option>
            ),
          )}
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Label>Treatment</Label>
        <FontAwesomeIcon
          className="ml-2"
          icon="info-circle"
          id="legend-popover"
        />
        <UncontrolledPopover
          placement="right"
          target="legend-popover"
          trigger="hover"
        >
          <PopoverBody>
            <FontAwesomeIcon icon="fish" className="mr-2" />
            = Food Fish Approved
          </PopoverBody>
        </UncontrolledPopover>
        <SearchDropdown
          className={cx({'is-invalid': error})}
          placeholder="Select treatment"
          displayText={_.get(treatment, 'name')}
          firstLoad={resetTreatments}
          loadMore={loadMoreTreatments}
          hasMore={hasMore}
          loading={loading}
          onClear={_.get(treatment, 'name') && !disabled ? onClear : undefined}
          disabled={!selectedType || disabled}
        >
          { !loading && _.get(treatments, 'length', 0) == 0 && (
            <div className="text-center">
              No Treatments
            </div>
          )}
          { _.map(
            treatments,
            (t) => (
              <DropdownItem
                key={`potential_treatment-${t.id}`}
                onClick={() => setPotentialTreatment(t)}
                className={cx(
                  { 'selected': t.id == _.get(treatment, 'id') },
                  styles.itemContainer,
                )}
              >
                <div className={styles.itemBox}>
                  { t.name }
                </div>
                {t.food_fish_approved && (
                  <FontAwesomeIcon
                    icon="fish"
                    className="ml-2"
                  />
                )}
              </DropdownItem>
            ),
          ) }
        </SearchDropdown>
        <FormFeedback role="alert">
          { _.get(error, 'potential_treatment') }
        </FormFeedback>
      </FormGroup>
    </div>
  );
});
PotentialTreatmentInput.displayName = 'PotentialTreatmentInput';
PotentialTreatmentInput.propTypes = {
  treatment: PropTypes.object,
  onChange: PropTypes.func,
};
