import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './CreateDrawer.module.scss';
import { useMobile } from '../../utils/hooks';

export function CreateDrawerItem (props) {
  const {
    label,
    icon,
    className,
    ...rest
  } = props;
  const mobile = useMobile();

  return (
    <div
      className={cx(className, styles.drawerItem)}
      role="menuitem"
      aria-label={label}
      tabIndex={-1}
      {...rest}
    >
      <span
        className={cx(
          styles.icon,
          {
            'btn': mobile,
            'btn-sm-circle': mobile,
          },
        )}
      >
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className={styles.label}>
        { label }
      </span>
    </div>
  );
}
CreateDrawerItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
};
