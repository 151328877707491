export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_ERROR = 'ACCEPT_INVITE_ERROR';
export const VALIDATE_INVITE = 'VALIDATE_INVITE';
export const VALIDATE_INVITE_SUCCESS = 'VALIDATE_INVITE_SUCCESS';
export const VALIDATE_INVITE_ERROR = 'VALIDATE_INVITE_ERROR';
export const SWITCH_ORG = 'SWITCH_ORG';
export const SWITCH_ORG_SUCCESS = 'SWITCH_ORG_SUCCESS';
export const SWITCH_ORG_ERROR = 'SWITCH_ORG_ERROR';
export const RESET_GET_ORGS = 'RESET_GET_ORGS';
export const GET_ORGS = 'GET_ORGS';
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS';
export const GET_ORGS_ERROR = 'GET_ORGS_ERROR';
export const GET_ORG_DETAILS = 'GET_ORG_DETAILS';
export const GET_ORG_DETAILS_SUCCESS = 'GET_ORG_DETAILS_SUCCESS';
export const GET_ORG_DETAILS_ERROR = 'GET_ORG_DETAILS_ERROR';
export const CREATE_ORG = 'CREATE_ORG';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_ERROR = 'CREATE_ORG_ERROR';
export const UPDATE_ORG = 'UPDATE_ORG';
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS';
export const UPDATE_ORG_ERROR = 'UPDATE_ORG_ERROR';
export const DELETE_ORG = 'DELETE_ORG';
export const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS';
export const DELETE_ORG_ERROR = 'DELETE_ORG_ERROR';

export const validateInvite = (key) => {
  return {
    type: VALIDATE_INVITE,
    key,
  };
};

export const acceptInvite = (key, onSuccess) => {
  return {
    type: ACCEPT_INVITE,
    key,
    onSuccess,
  };
};

export const switchOrg = (slug) => {
  return {
    type: SWITCH_ORG,
    slug,
  };
};

export const getOrgs = (page, query) => {
  return {
    type: GET_ORGS,
    page,
    query,
  };
};

export const getOrgDetails = (slug) => {
  return {
    type: GET_ORG_DETAILS,
    slug,
  };
};

export const createOrg = (formData) => {
  return {
    type: CREATE_ORG,
    formData,
  };
};

export const updateOrg = (slug, formData) => {
  return {
    type: UPDATE_ORG,
    slug,
    formData,
  };
};

export const deleteOrg = (slug) => {
  return {
    type: DELETE_ORG,
    slug,
  };
};
