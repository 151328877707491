import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getEditClient } from '../../redux/clients/actions';
import ClientForm from './ClientForm';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';

export default function EditClient () {
  const dispatch = useDispatch();
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const editClient = useSelector((s) => s.clients.editClient);
  const getEditClientPending = useSelector((s) => s.clients.getEditClientPending);
  const getEditClientError = useSelector((s) => s.clients.getEditClientError);

  // Load editClient if not already
  useEffect(() => {
    if (currOrg && _.get(editClient, 'id') != id) {
      dispatch(getEditClient(id));
      setStartedLoading(true);
    }
  }, [currOrg]);

  // Failed to load client. Redirect
  useEffect(() => {
    if (startedLoading && getEditClientError) {
      history.replace(`/app/${orgSlug}/clients`);
    }
  }, [startedLoading, getEditClientError, history]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Client"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { (!editClient || getEditClientPending)
        ?
        <div className="text-center">
          <Spinner />
        </div>
        :
        <ClientForm
          onSubmit={history.goBack}
          client={editClient}
        />
      }
    </div>
  );
}
