import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

import {
  updateReportFilters,
  CLEAR_REPORT_FILTERS,
} from '../../redux/reports/actions';
import {
  getReportFilters,
  getReportSearchCtx,
  getLocalReportSearchCtx,
} from '../../redux/reports/selectors';
import {
  BackButton,
  CancelButton,
  ClientSelect,
  SpeciesSelect,
  PageHeader,
  PathogenMultiselect,
  DateInput,
} from '../../components';
import { action } from '../../redux/helpers';

import layout from '../../styles/layout.module.scss';
import { PotentialTreatmentInput } from '../../components/inputs/PotentialTreatmentInput';
import { MiscDetailsSelect } from '../../components/inputs/MiscDetailsSelect';

export default function SearchFilters () {
  const filters = useSelector(getReportFilters);
  const searchCtx = useSelector(getReportSearchCtx);
  const localSearchCtx = useSelector(getLocalReportSearchCtx);
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilter = useCallback((key, value) => {
    dispatch(updateReportFilters({ [key]: value }));
  }, []);

  const clearFilters = useCallback(() => {
    dispatch(action(CLEAR_REPORT_FILTERS));
  }, []);

  const submit = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Filter Reports"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <Form
          className={layout.responsiveContainer}
          onSubmit={submit}
        >
          <Button
            color="link"
            className="float-right"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
          <div className="clearfix"></div>
          <Row>
            <FormGroup className="col">
              <Label>Start Date</Label>
              <DateInput
                value={filters.startDate}
                onChange={(d) => updateFilter('startDate', d)}
              />
            </FormGroup>
            <FormGroup className="col">
              <Label>End Date</Label>
              <DateInput
                value={filters.endDate}
                onChange={(d) => updateFilter('endDate', d)}
              />
            </FormGroup>
          </Row>
          <FormGroup>
            <Label>Client</Label>
            <ClientSelect
              value={filters.client}
              onChange={(c) => updateFilter('client', c)}
              onClear={() => updateFilter('client', null)}
              clearable
            />
          </FormGroup>
          <FormGroup>
            <Label>Species</Label>
            <SpeciesSelect
              value={filters.species}
              onChange={(s) => updateFilter('species', s)}
            />
          </FormGroup>
          <h4>Diagnosis</h4>
          <FormGroup>
            <Label>Parasites</Label>
            <PathogenMultiselect
              value={filters.parasites}
              onChange={(p) => updateFilter('parasites', p)}
              placeholder="Select"
              filter={
                [
                  'apicomp', 'acanth', 'cestodes', 'crust',
                  'micro', 'monodig', 'myxo', 'nema', 'pro', 'other',
                ]
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Bacteria</Label>
            <PathogenMultiselect
              value={filters.bacteria}
              onChange={(p) => updateFilter('bacteria', p)}
              placeholder="Select"
              filter="bac"
            />
          </FormGroup>
          <FormGroup>
            <Label>Fungi</Label>
            <PathogenMultiselect
              value={filters.fungi}
              onChange={(p) => updateFilter('fungi', p)}
              placeholder="Select"
              filter="fungi"
            />
          </FormGroup>
          <FormGroup>
            <Label>Viruses</Label>
            <PathogenMultiselect
              value={filters.viruses}
              onChange={(p) => updateFilter('viruses', p)}
              placeholder="Select"
              filter="virus"
            />
          </FormGroup>
          <FormGroup>
            <Label>Misc. Detail</Label>
            <MiscDetailsSelect
              value={filters.misc_details}
              onChange={(p) => updateFilter('misc_details', p)}
            />
          </FormGroup>
          <hr/>
          <FormGroup>
            <Label>Potential Treatment</Label>
            <PotentialTreatmentInput
              value={filters.potential_treatment}
              onChange={(p) => updateFilter('potential_treatment', p)}
            />
          </FormGroup>
          <Button
            color="primary"
            disabled={searchCtx == localSearchCtx}
            block
          >
            Apply Filters
          </Button>
          <CancelButton />
        </Form>
      </div>
    </div>
  );
}
