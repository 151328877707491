export default [
  {
    text: 'Select Type',
    value: '',
  },
  {
    text: 'Medicated Feed',
    value: 'med_feed',
  },
  {
    text: 'Water Treatment',
    value: 'water_treat',
  },
  {
    text: 'Injection',
    value: 'inj',
  },
  {
    text: 'Management',
    value: 'man',
  },
];
