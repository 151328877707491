import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import * as _ from 'lodash';

import { resendInvite, deleteInvite } from '../../redux/users/actions';
import { useConfirmAction, useRole } from '../../utils/hooks';

import styles from './Invite.module.scss';
import text from '../../styles/text.module.scss';
import tables from '../../styles/tables.module.scss';

export default function Invite ({ invite }) {
  const dispatch = useDispatch();
  const role = useRole();
  const confirmAction = useConfirmAction();
  const {
    pending: resendPending,
    error: resendError,
  } = useSelector((s) => _.get(s.users, `inviteStatus[${invite.id}]`, {}));
  const [resent, setResent] = useState(false);
  const resentSuccess = resent && !resendPending && !resendError;

  const doResend = useCallback(() => {
    if (resendPending || resent) {
      // Ignore clicks during grace period to avoid duplicates
      return;
    }

    dispatch(resendInvite(invite.id));
    setResent(true);
  }, [invite, resent, resendPending, resentSuccess, resendError]);

  const doDeleteInvite = useCallback(() => {
    confirmAction({
      action: 'uninvite',
      subjectType: 'user',
      subject: invite.email,
      onConfirm: () => dispatch(deleteInvite(invite.id)),
      pendingPath: 'users.deleteInvitePending',
      errorPath: 'users.deleteInviteError',
    });
  }, [invite, confirmAction]);

  // Communicate success and disable interaction with timeout
  useEffect(() => {
    if (resentSuccess || resendError) {
      const timeout = setTimeout(() => {
        setResent(false);
      }, 2500);

      return () => clearTimeout(timeout);
    }
  }, [resentSuccess, resendError]);

  return (
    <tr className={styles.invite}>
      <td className={cx(styles.italics, text.ellipsize)}>
        { invite.email }
      </td>
      <td className={cx(styles.italics, 'text-center')}>
        { _.upperFirst(invite.role) }
      </td>
      <td className={tables.actions}>
        { _.includes(['admin', 'super'], role) && (
          <div>
            <Button
              color="link"
              title={resendPending || resentSuccess
                ? 'Resending'
                : `Resend invite to ${invite.email}`
              }
              onClick={doResend}
            >
              {
                (resendPending && <Spinner size="sm" />) ||
                <FontAwesomeIcon
                  icon={(
                    resent && (
                      (resentSuccess && 'check') ||
                      (resendError && 'exclamation-triangle')
                    ) ||
                    'paper-plane'
                  )}
                  color={resent && resendError ? styles.darkYellow : 'inherit'}
                />
              }
            </Button>
            <Button
              color="link"
              title={`Remove invite for ${invite.email}`}
              onClick={doDeleteInvite}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
}
