import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  BackButton,
  ListCard,
  PageHeader,
} from '../../components';
import { useClients } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import { subtext } from '../../styles/text.module.scss';

export default function SelectClient () {
  const currOrg = useSelector(getCurrOrg);
  const location = useLocation();
  const { orgSlug } = useParams();
  const {
    clients,
    hasMore,
    loading,
    noResults,
    loadMore,
    reset,
  } = useClients();

  // Reset list anytime currOrg changes
  useEffect(() => {
    if (currOrg) {
      reset();
    }
  }, [currOrg]);

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Select Client" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader
        title="Select Client"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <div className={layout.pageContent}>
        <div className={layout.responsiveContainer}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!loading && hasMore}
            useWindow={false}
            className="d-flex flex-column"
          >
            {
              noResults
                ? (
                  <p key="zero" className="text-center mt-3">
                    You don&apos;t have any clients for this organization yet.
                  </p>
                )
                : _.map(clients, (client) =>
                  <ListCard
                    key={`client-${client.id}`}
                    linkTo={client.num_water_bodies == 0
                      ? undefined
                      : {
                        pathname: `/app/${orgSlug}/clients/${client.id}/select-body`,
                        search: location.search,
                      }
                    }
                    className={cx({ disabled: client.num_water_bodies == 0 })}
                  >
                    { client.name }
                    <div className={subtext}>
                      <b>{client.num_water_bodies}</b> water bod{client.num_water_bodies != 1 ? 'ies' : 'y'}
                    </div>
                  </ListCard>,
                )
            }
            { loading && (
              <div
                className="text-center"
                key="spinner"
              >
                <Spinner className={cx(tables.spinner, 'mt-3')}  />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
