import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import { getCurrOrg } from '../../redux/orgs/selectors';
import UserForm from './UserForm';

import layout from '../../styles/layout.module.scss';

export default function CreateUser () {
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);

  const gotoUsers = useCallback(() => {
    history.replace(`/app/${currOrg.slug}/users`);
  }, [currOrg]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New User"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <UserForm onSubmit={gotoUsers} />
    </div>
  );
}
