import axios from 'axios';
import * as _ from 'lodash';

const parse = (promise) => promise.then((r) => r.data);

const queryParams = (params) =>_.map(
  _.omitBy(_.keys(params), (k) => !params[k]),
  (k) => `${k}=${encodeURIComponent(params[k])}`,
).join('&');

export default {
  /************ Authentication ************/
  login: (data) => parse(axios.post('/api/login/', data)),

  signup: (data) => parse(axios.post('/api/signup/', data)),

  forgotPassword: (email) => parse(axios.post('/api/password/reset/', { email })),

  resetPassword: (data) => parse(axios.post('/api/password/reset/confirm/', data)),

  loadUser: (token) => parse(axios.get(
    '/api/user/',
    { headers: { Authorization: `Token ${token}` } },
  )),

  editUser: (data) => parse(axios.patch('/api/user/', data)),

  validateInvite: (key) => parse(axios.get(`/api/validate-invite/${key}/`)),

  acceptInvite: (key) => parse(axios.get(`/api/accept-invite/${key}/`)),

  /************ Organizations ************/
  getOrgDetails: (slug) => parse(axios.get(`/api/org-details/${slug}/`)),

  getOrgs: (p) => parse(axios.get(`/api/organizations/?${queryParams(p)}`)),

  getOrg: (slug) => parse(axios.get(`/api/organizations/${slug}/`)),

  createOrg: (data) => parse(axios.post(`/api/organizations/`, data)),

  updateOrg: (slug, data) => parse(axios.patch(`/api/organizations/${slug}/`, data)),

  deleteOrg: (slug) => parse(axios.delete(`/api/organizations/${slug}/`)),

  removeUser: (slug, data) => parse(axios.post(`/api/organizations/${slug}/remove_user/`, data)),

  setUserRole: (slug, data) => parse(axios.post(`/api/organizations/${slug}/set_user_role/`, data)),

  getEditUser: (slug, id) => parse(axios.get(`/api/organizations/${slug}/users/${id}/`)),

  /************ Users ************/
  loadUsers: (p) => parse(axios.get(`/api/users/?${queryParams(p)}`)),

  loadInvites: (p) => parse(axios.get(`/api/invites/?${queryParams(p)}`)),

  inviteUser: (data) => parse(axios.post(`/api/invites/`, data)),

  resendInvite: (id) => parse(axios.post(`/api/invites/${id}/resend/`)),

  deleteInvite: (id) => parse(axios.delete(`/api/invites/${id}/`)),

  /************ Clients ************/
  loadClients: (p) => parse(axios.get(`/api/clients/?${queryParams(p)}`)),

  createClient: (formData) => parse(axios.post(`/api/clients/`, formData)),

  getEditClient: (id) => parse(axios.get(`/api/clients/${id}/`)),

  updateClient: (formData) => parse(axios.patch(`/api/clients/${formData.id}/`, formData)),

  deleteClient: (id) => parse(axios.delete(`/api/clients/${id}/`)),

  /************ Water Bodies ************/
  loadWaterBodies: (p) => parse(axios.get(`/api/water-bodies/?${queryParams(p)}`)),

  createWaterBody: (formData) => parse(axios.post(`/api/water-bodies/`, formData)),

  updateWaterBody: (formData) => parse(axios.patch(`/api/water-bodies/${formData.id}/`, formData)),

  getWaterBody: (id) => parse(axios.get(`/api/water-bodies/${id}/`)),

  deleteWaterBody: (id) => parse(axios.delete(`/api/water-bodies/${id}/`)),

  /************ Prod Cycles************/
  loadProdCycles: (p) => parse(axios.get(`/api/prod-cycles/?${queryParams(p)}`)),

  createProdCycle: (formData) => parse(axios.post(`/api/prod-cycles/`, formData)),

  getProdCycle: (id) => parse(axios.get(`/api/prod-cycles/${id}/`)),

  updateProdCycle: (formData) => parse(axios.patch(`/api/prod-cycles/${formData.id}/`, formData)),

  deleteProdCycle: (id) => parse(axios.delete(`/api/prod-cycles/${id}/`)),

  /************ Search ************/
  loadSpecies: (p) => parse(axios.get(`/api/fish-species/?${queryParams(p)}`)),

  loadPathogens: (p) => parse(axios.get(`/api/pathogens/?${queryParams(p)}`)),

  loadBehaviors: (p) => parse(axios.get(`/api/behaviors/?${queryParams(p)}`)),

  loadTreatments: (p) => parse(axios.get(`/api/treatment-types/?${queryParams(p)}`)),

  loadMiscDetails: (p) => parse(axios.get(`/api/misc-details/?${queryParams(p)}`)),

  /************ Activities ************/
  createActivity: (formData) => parse(axios.post(`/api/activities/`, formData)),

  updateActivity: (formData) => parse(axios.patch(`/api/activities/${formData.id}/`, formData)),

  loadActivities: (p) => parse(axios.get(`/api/activities/?${queryParams(p)}`)),

  getActivity: (id) => parse(axios.get(`/api/activities/${id}/`)),

  deleteActivity: (id) => parse(axios.delete(`/api/activities/${id}/`)),

  /************ Reports ************/
  createReport: (formData) => parse(axios.post(`/api/reports/`, formData)),

  updateReport: (formData) => parse(axios.patch(`/api/reports/${formData.id}/`, formData)),

  loadReports: (p) => parse(axios.get(`/api/reports/?${queryParams(p)}`)),

  getReport: (id) => parse(axios.get(`/api/reports/${id}/`)),

  deleteReport: (id) => parse(axios.delete(`/api/reports/${id}/`)),

  loadReportMedia: (p) => parse(axios.get(`/api/report-media/?${queryParams(p)}`)),

  createReportMedia: (formData, config) => parse(axios.post(
    '/api/report-media/',
    formData,
    config,
  )),

  deleteReportMedia: (id) => parse(axios.delete(`/api/report-media/${id}/`)),

  /************ Samples ************/
  createSample: (formData) => parse(axios.post(`/api/samples/`, formData)),

  updateSample: (formData) => parse(axios.patch(`/api/samples/${formData.id}/`, formData)),

  loadSamples: (p) => parse(axios.get(`/api/samples/?${queryParams(p)}`)),

  getSample: (id) => parse(axios.get(`/api/samples/${id}/`)),

  deleteSample: (id) => parse(axios.delete(`/api/samples/${id}/`)),

  loadSampleMedia: (p) => parse(axios.get(`/api/sample-media/?${queryParams(p)}`)),

  createSampleMedia: (formData, config) => parse(axios.post(
    '/api/sample-media/',
    formData,
    config,
  )),

  deleteSampleMedia: (id) => parse(axios.delete(`/api/sample-media/${id}/`)),

  /************ Bacteriology ************/

  loadIsolates: (p) => parse(axios.get(`/api/isolates/?${queryParams(p)}`)),

  getIsolate: (id) => parse(axios.get(`/api/isolates/${id}/`)),

  createIsolate: (formData) => parse(axios.post(`/api/isolates/`, formData)),

  updateIsolate: (formData) => parse(axios.patch(`/api/isolates/${formData.id}/`, formData)),

  deleteIsolate: (id) => parse(axios.delete(`/api/isolates/${id}/`)),

  /************ Clinical Signs ************/

  loadClinicalSigns: (p) => parse(axios.get(`/api/clinical-signs/?${queryParams(p)}`)),

  getClinicalSign: (id) => parse(axios.get(`/api/clinical-signs/${id}/`)),

  createClinicalSign: (formData) => parse(axios.post(`/api/clinical-signs/`, formData)),

  updateClinicalSign: (formData) => parse(axios.patch(`/api/clinical-signs/${formData.id}/`, formData)),

  deleteClinicalSign: (id) => parse(axios.delete(`/api/clinical-signs/${id}/`)),
};
