export const RESET_LOAD_ACTIVITIES = 'RESET_LOAD_PROD_CYLES';
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_ACTIVITIES_SUCCESS = 'LOAD_ACTIVITIES_SUCCESS';
export const LOAD_ACTIVITIES_ERROR = 'LOAD_ACTIVITIES_ERROR';
export const GET_CURR_ACTIVITY = 'GET_CURR_ACTIVITY';
export const GET_CURR_ACTIVITY_SUCCESS = 'GET_CURR_ACTIVITY_SUCCESS';
export const GET_CURR_ACTIVITY_ERROR = 'GET_CURR_ACTIVITY_ERROR';
export const SET_CURR_ACTIVITY = 'SET_CURR_ACTIVITY';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';
export const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';

export const loadActivities = (id, page) => {
  return {
    type: LOAD_ACTIVITIES,
    id,
    page,
  };
};

export const createActivity = (formData) => {
  return {
    type: CREATE_ACTIVITY,
    formData,
  };
};

export const updateActivity = (formData) => {
  return {
    type: UPDATE_ACTIVITY,
    formData,
  };
};

export const deleteActivity = (id) => {
  return {
    type: DELETE_ACTIVITY,
    id,
  };
};

export const setCurrActivity = (activity) => {
  return {
    type: SET_CURR_ACTIVITY,
    activity,
  };
};

export const getCurrActivity = (id) => {
  return {
    type: GET_CURR_ACTIVITY,
    id,
  };
};
