import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import NotFound from '../NotFound';
import EditActivity from './EditActivity';

export default function ActivitiesIndex () {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:id/edit`}>
        <EditActivity />
      </Route>
      <Route path={`${path}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
