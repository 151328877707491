import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getWaterBodies,
  getLocalWaterBodySearchCtx,
} from '../../redux/waterbodies/selectors';
import {
  loadWaterBodies,
  resetWaterBodies,
  CLEAR_WATERBODY_FILTERS,
} from '../../redux/waterbodies/actions';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { action } from '../../redux/helpers';

export const useWaterBodies = (clientId) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const [resetCalled, setResetCalled] = useState(false);
  const currOrg = useSelector(getCurrOrg);
  const localSearchCtx = useSelector(getLocalWaterBodySearchCtx);
  const waterBodies = useSelector(getWaterBodies);
  const currPage = useSelector((s) => s.waterBodies.currWaterBodiesPage);
  const hasMore = useSelector((s) => s.waterBodies.hasMoreWaterBodies);
  const loading = useSelector((s) => s.waterBodies.loadWaterBodiesPending);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(waterBodies, 'length', 0) == 0
    );
  }, [loading, hasMore, waterBodies]);

  const reset = useCallback(() => {
    dispatch(resetWaterBodies(clientId ? { id: clientId } : undefined));
    setResetCalled(true);
  }, [clientId]);

  const clearFilters = useCallback(() => {
    dispatch(action(CLEAR_WATERBODY_FILTERS));
  }, []);

  const loadMore = useCallback(() => {
    dispatch(loadWaterBodies(currOrg.slug, currPage + 1, localSearchCtx));
  }, [currPage, currOrg, localSearchCtx]);

  // Reset list anytime currOrg or search ctx changes
  useEffect(() => {
    if (currOrg) {
      reset();
    }
  }, [currOrg, localSearchCtx]);

  // Clear filters if currOrg changes
  useEffect(() => {
    if (currOrg && firstLoad.current) {
      clearFilters();
    }
  }, [currOrg]);

  return {
    // Ignore redux list until first load
    waterBodies: firstLoad.current ? waterBodies : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    clearFilters,
    loadMore,
  };
};
