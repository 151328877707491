import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import Cookies from 'universal-cookie';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import cx from 'classnames';

import { useReadOnly } from '../../utils/hooks';
import {
  updateReport,
  setStepTitle,
} from '../../redux/reports/actions';
import feedingActivities from '../../utils/data/feeding_activities';
import { DateTimeInput } from '../../components/inputs/DateTimeInput';

import layout from '../../styles/layout.module.scss';
import styles from './Report.module.scss';
import { BehaviorSelect } from '../../components/inputs/BehaviorSelect';
import { GeneralErrors, ListCard } from '../../components';
export const FISHINFO_FORM_DATA = '_ksu_fifd';

export default function FishInfoForm () {
  const readOnly = useReadOnly();
  const history = useHistory();
  const { orgSlug } = useParams();
  const dispatch = useDispatch();
  const currReport = useSelector((s) => s.reports.currReport);
  const {
    register,
    handleSubmit,
    formState,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      ...currReport,
      ...(new Cookies().get(FISHINFO_FORM_DATA) || {}),
    },
  });
  new Cookies().remove(FISHINFO_FORM_DATA, { path: '/' });

  const { isSubmitted } = formState;

  const pending = useSelector((s) => s.reports.changeReportPending);
  const error = useSelector((s) => s.reports.changeReportError);
  const submitSuccess = isSubmitted && !pending && !error;

  useEffect(() => {
    dispatch(setStepTitle('Fish Information'));
  }, []);

  const submit = useCallback((data) => {
    dispatch(updateReport({
      ...data,
      max_step_index: 1,
      fish_behavior: _.map(
        _.get(data, 'fish_behavior', []),
        (s) => s.id,
      ),
      id: currReport.id,
    }));
  }, [currReport]);

  const saveFormState = useCallback(() => {
    new Cookies().set(FISHINFO_FORM_DATA, getValues(), { path: '/' });
  }, [getValues]);

  // Submit success
  useEffect(() => {
    if (submitSuccess) {
      history.push(`/app/${orgSlug}/reports/${currReport.id}/edit/water-quality`);
    }
  }, [submitSuccess]);

  return (
    <Form
      className={cx(
        layout.responsiveContainer,
        layout.responsiveContainerButtonsBottom,
      )}
      onSubmit={handleSubmit(submit)}
    >
      <FormGroup>
        <Label>Problem Description</Label>
        <Input
          innerRef={register}
          type="textarea"
          name="prob_desc"
          invalid={ isSubmitted && _.has(error, 'prob_desc') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'prob_desc') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>
          Problem First Noticed
        </Label>
        <Controller
          required
          as={DateTimeInput}
          name="prob_first_noticied"
          control={control}
          invalid={ isSubmitted && _.has(error, 'prob_first_noticied') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'prob_first_noticied') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="select">Feeding Activity</Label>
        <CustomInput
          id="select"
          innerRef={register}
          type="select"
          name="feeding_activity"
          className="form-control"
          disabled={readOnly}
        >
          {_.map(
            feedingActivities,
            (activity) => (
              <option
                key={`feed-act-${activity.value}`}
                value={activity.value}
              >
                {activity.text}
              </option>
            ),
          )}
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Label>Fish Behavior Prior to Death</Label>
        <Controller
          as={BehaviorSelect}
          name="fish_behavior"
          control={control}
          invalid={ isSubmitted && _.has(error, 'fish_behavior') }
          disabled={readOnly}
        />
        <FormFeedback role="alert">
          { _.get(error, 'fish_behavior') }
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Past Activities</Label>
        { !readOnly && (
          <Link
            to={`/app/${orgSlug}/prod-cycles/${_.get(currReport, 'prod_cycle')}/log-activity`}
            className="float-right"
            onClick={saveFormState}
          >
            <FontAwesomeIcon icon="external-link-alt" /> Log activity
          </Link>
        )}
        <ListCard
          linkTo={`/app/${orgSlug}/prod-cycles/${_.get(currReport, 'prod_cycle')}/activities`}
          onClick={saveFormState}
        >
          {currReport.num_activities} activit{currReport.num_activities == 1 ? 'y' : 'ies'} logged
        </ListCard>
      </FormGroup>
      { isSubmitted && <GeneralErrors error={error} /> }
      <div className={styles.buttonsBottom}>
        { !readOnly && (
          <Button
            color="primary"
            disabled={pending}
            block
          >
            Save and Continue{' '}
            { pending && <Spinner size="sm" /> }
          </Button>
        )}
        <Link
          className="btn btn-secondary btn-block"
          to={`/app/${orgSlug}/reports/${_.get(currReport, 'id')}`}
        >
          Cancel
        </Link>
      </div>
    </Form>
  );
}
