import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { BackButton, PageHeader } from '../../components';
import { getCurrOrg } from '../../redux/orgs/selectors';
import OrgForm from './OrgForm';

import layout from '../../styles/layout.module.scss';

export default function EditOrg () {
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const pending = useSelector((s) => s.orgs.switchOrgPending);

  const gotoOrg = useCallback((slug) => {
    history.replace(`/app/${slug}`);
  }, []);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Edit Org"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      { pending
        &&
        <div className="text-center">
          <Spinner />
        </div>
        ||
        <OrgForm
          onSubmit={gotoOrg}
          org={currOrg}
        />
      }
    </div>
  );
}
