import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import cx from 'classnames';
import * as _ from 'lodash';

import {
  CreateDrawer,
  CreateDrawerItem,
  ListCard,
  PageHeader,
  SimpleWaterBodyFilter,
} from '../../components';
import { useRole, useWaterBodies } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import { getLocalWaterBodySearchCtx } from '../../redux/waterbodies/selectors';
import { setCurrWaterBody } from '../../redux/waterbodies/actions';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';
import { subtext } from '../../styles/text.module.scss';

export default function WaterBodies () {
  const history = useHistory();
  const currOrg = useSelector(getCurrOrg);
  const dispatch = useDispatch();
  const { orgSlug } = useParams();
  const role = useRole();
  const localSearchCtx = useSelector(getLocalWaterBodySearchCtx);
  const {
    waterBodies,
    hasMore,
    loading,
    noResults,
    loadMore,
  } = useWaterBodies();

  const gotoCreateWaterBody = useCallback(
    () => history.push(`/app/${orgSlug}/water-bodies/create`),
    [history, orgSlug],
  );

  const gotoWaterbody = useCallback((body) => {
    dispatch(setCurrWaterBody(body));
    history.push(`/app/${currOrg.slug}/water-bodies/${body.id}`);
  }, [currOrg, history]);

  if (!currOrg) {
    return (
      <div className={layout.page}>
        <PageHeader title="Water Bodies" />
        <div className={cx(layout.pageContent, 'p-0')}>
          <Spinner className="mt-3 align-self-center" />
        </div>
      </div>
    );
  }

  return (
    <div className={layout.page}>
      <PageHeader title="Water Bodies" />
      { !_.includes(['read'], role) && (
        <CreateDrawer>
          <CreateDrawerItem
            label="New Water Body"
            icon="tint"
            onClick={gotoCreateWaterBody}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, layout.pageContentDrawer)}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          className={layout.responsiveContainer}
        >
          <SimpleWaterBodyFilter />
          <div className="d-flex flex-column">
            {
              noResults
                ? (
                  <p key="zero" className="text-center mt-3">
                    {localSearchCtx ?
                      'No water bodies match your current filters'
                      :
                      'You don\'t have any water bodies for this organization yet.'
                    }
                  </p>
                )
                : _.map(waterBodies, (body) =>
                  <ListCard
                    key={`body-${body.id}`}
                    onClick={() => gotoWaterbody(body)}
                  >
                    { body.name }
                    <div className={subtext}>
                      <b>{body.num_prod_cycles}</b> production cycle{body.num_prod_cycles != 1 && 's'}
                    </div>
                  </ListCard>,
                )
            }
            { loading && (
              <div
                className="text-center"
                key="spinner"
              >
                <Spinner className={cx(tables.spinner, 'mt-3')}  />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
