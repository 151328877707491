import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_PROD_CYCLES,
  LOAD_PROD_CYCLES_SUCCESS,
  LOAD_PROD_CYCLES_ERROR,
  CREATE_PROD_CYCLE,
  UPDATE_PROD_CYCLE,
  CREATE_PROD_CYCLE_SUCCESS,
  UPDATE_PROD_CYCLE_SUCCESS,
  CREATE_PROD_CYCLE_ERROR,
  UPDATE_PROD_CYCLE_ERROR,
  GET_CURR_PROD_CYCLE,
  GET_CURR_PROD_CYCLE_SUCCESS,
  GET_CURR_PROD_CYCLE_ERROR,
  DELETE_PROD_CYCLE,
  DELETE_PROD_CYCLE_SUCCESS,
  DELETE_PROD_CYCLE_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export function* loadProdCycles (action) {
  const { id, page } = action;

  const params = {
    water_body: id,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield minDelayCall(api.loadProdCycles, params);

    yield put({ type: LOAD_PROD_CYCLES_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_PROD_CYCLES_ERROR, error: error.response.data });
  }
}

export function* createProdCycle ({ formData }) {
  try {
    const prodCycle = yield minDelayCall(api.createProdCycle, formData);

    yield put({ type: CREATE_PROD_CYCLE_SUCCESS, prodCycle });
  } catch (error) {
    yield put({ type: CREATE_PROD_CYCLE_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateProdCycle ({ formData }) {
  try {
    const prodCycle = yield minDelayCall(api.updateProdCycle, formData);

    yield put({ type: UPDATE_PROD_CYCLE_SUCCESS, prodCycle });
  } catch (error) {
    yield put({ type: UPDATE_PROD_CYCLE_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrProdCycle ({ id }) {
  try {
    const prodCycle = yield minDelayCall(api.getProdCycle, id);

    yield put({ type: GET_CURR_PROD_CYCLE_SUCCESS, prodCycle });
  } catch (error) {
    yield put({ type: GET_CURR_PROD_CYCLE_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteProdCycle (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteProdCycle, id);

    yield put({ type: DELETE_PROD_CYCLE_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_PROD_CYCLE_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_PROD_CYCLES, loadProdCycles),
    takeLatest(CREATE_PROD_CYCLE, createProdCycle),
    takeLatest(GET_CURR_PROD_CYCLE, getCurrProdCycle),
    takeLatest(UPDATE_PROD_CYCLE, updateProdCycle),
    takeLatest(DELETE_PROD_CYCLE, deleteProdCycle),
  ]);
}
