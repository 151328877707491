import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_SPECIES,
  LOAD_PATHOGENS,
  LOAD_BEHAVIORS,
  LOAD_TREATMENTS,
  LOAD_MISC_DETAILS,
  LOAD_SPECIES_SUCCESS,
  LOAD_PATHOGENS_SUCCESS,
  LOAD_BEHAVIORS_SUCCESS,
  LOAD_TREATMENTS_SUCCESS,
  LOAD_MISC_DETAILS_SUCCESS,
  LOAD_SPECIES_ERROR,
  LOAD_PATHOGENS_ERROR,
  LOAD_BEHAVIORS_ERROR,
  LOAD_TREATMENTS_ERROR,
  LOAD_MISC_DETAILS_ERROR,
} from './actions';
import api from '../../utils/api';
import { minDelayCall } from '../helpers';

const PAGE_SIZE = 20;

export function* loadSpecies (action) {
  const { page, query } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
  };

  try {
    const response = yield minDelayCall(api.loadSpecies, params);

    yield put({ type: LOAD_SPECIES_SUCCESS, ...response, page});
  } catch (error) {
    yield put({ type: LOAD_SPECIES_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadPathogens (action) {
  const { page, query, pathogenType } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
    pathogen_type: pathogenType,
  };

  try {
    const response = yield minDelayCall(api.loadPathogens, params);

    yield put({ type: LOAD_PATHOGENS_SUCCESS, ...response, page});
  } catch (error) {
    yield put({ type: LOAD_PATHOGENS_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadBehaviors (action) {
  const { page, query } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
  };

  try {
    const response = yield minDelayCall(api.loadBehaviors, params);

    yield put({ type: LOAD_BEHAVIORS_SUCCESS, ...response, page});
  } catch (error) {
    yield put({ type: LOAD_BEHAVIORS_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadMiscDetails (action) {
  const { page, query } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
  };

  try {
    const response = yield minDelayCall(api.loadMiscDetails, params);

    yield put({ type: LOAD_MISC_DETAILS_SUCCESS, ...response, page});
  } catch (error) {
    yield put({ type: LOAD_MISC_DETAILS_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadTreatments (action) {
  const { page, query, treatmentType } = action;

  const params = {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search: _.trim(query),
    category: treatmentType,
  };

  try {
    const response = yield minDelayCall(api.loadTreatments, params);

    yield put({ type: LOAD_TREATMENTS_SUCCESS, ...response, page});
  } catch (error) {
    yield put({ type: LOAD_TREATMENTS_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_SPECIES, loadSpecies),
    takeLatest(LOAD_PATHOGENS, loadPathogens),
    takeLatest(LOAD_BEHAVIORS, loadBehaviors),
    takeLatest(LOAD_TREATMENTS, loadTreatments),
    takeLatest(LOAD_MISC_DETAILS, loadMiscDetails),
  ]);
}
