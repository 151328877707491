import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  LOAD_REPORTS,
  LOAD_REPORTS_SUCCESS,
  LOAD_REPORTS_ERROR,
  CREATE_REPORT,
  UPDATE_REPORT,
  CREATE_REPORT_SUCCESS,
  UPDATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR,
  UPDATE_REPORT_ERROR,
  GET_CURR_REPORT,
  GET_CURR_REPORT_SUCCESS,
  GET_CURR_REPORT_ERROR,
  DELETE_REPORT,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
  LOAD_REPORT_MEDIA,
  LOAD_REPORT_MEDIA_SUCCESS,
  LOAD_REPORT_MEDIA_ERROR,
  CREATE_REPORT_MEDIA,
  CREATE_REPORT_MEDIA_PROGRESS,
  CREATE_REPORT_MEDIA_SUCCESS,
  CREATE_REPORT_MEDIA_ERROR,
  DELETE_REPORT_MEDIA,
  DELETE_REPORT_MEDIA_SUCCESS,
  DELETE_REPORT_MEDIA_ERROR,
  CANCEL_REPORT_UPLOAD,
} from './actions';
import api from '../../utils/api';
import {
  minDelayCall,
  createUploadChannel,
} from '../helpers';

const PAGE_SIZE = 20;

export function* loadReports (action) {
  const sortDesc = yield select(({ reports }) => reports.sortDesc);
  const { cycleId, slug, page, searchCtx } = action;

  const params = {
    prod_cycle: cycleId,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    s: searchCtx,
    sortBy: `${sortDesc ? '-' : ''}date`,
  };

  if (!cycleId) {
    params.prod_cycle__water_body__client__org__slug = slug;
  }

  try {
    const response = yield minDelayCall(api.loadReports, params);

    yield put({ type: LOAD_REPORTS_SUCCESS, ...response, page, searchCtx });
  } catch (error) {
    yield put({ type: LOAD_REPORTS_ERROR, error: error.response.data });
  }
}

export function* createReport ({ formData }) {
  try {
    const report = yield minDelayCall(api.createReport, formData);

    yield put({ type: CREATE_REPORT_SUCCESS, report });
  } catch (error) {
    yield put({ type: CREATE_REPORT_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* updateReport ({ formData }) {
  try {
    const report = yield minDelayCall(api.updateReport, formData);

    yield put({ type: UPDATE_REPORT_SUCCESS, report });
  } catch (error) {
    yield put({ type: UPDATE_REPORT_ERROR, error: _.get(error, 'response.data') });
  }
}

function* getCurrReport ({ id }) {
  try {
    const report = yield minDelayCall(api.getReport, id);

    yield put({ type: GET_CURR_REPORT_SUCCESS, report });
  } catch (error) {
    yield put({ type: GET_CURR_REPORT_ERROR, id, error: _.get(error, 'response.data') });
  }
}

export function* deleteReport (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteReport, id);

    yield put({ type: DELETE_REPORT_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_REPORT_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* loadReportMedia (action) {
  const { reportId, page } = action;

  const params = {
    report: reportId,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
  };

  try {
    const response = yield call(api.loadReportMedia, params);

    yield put({ type: LOAD_REPORT_MEDIA_SUCCESS, ...response, page });
  } catch (error) {
    yield put({ type: LOAD_REPORT_MEDIA_ERROR, error: _.get(error, 'response.data') });
  }
}

export function* watchForCancel (targetUploadId, channel) {
  while (true) {
    const { uploadId, type } = yield take([
      CANCEL_REPORT_UPLOAD,
      CREATE_REPORT_MEDIA_SUCCESS,
      CREATE_REPORT_MEDIA_ERROR,
    ]);

    if (uploadId == targetUploadId) {
      if (type == CANCEL_REPORT_UPLOAD) {
        channel.close();
      }

      // Exit loop
      return;
    }

  }
}

export function* createReportMedia (action) {
  const { reportId, file, uploadId } = action;

  const formData = new FormData();
  formData.append('report', reportId);
  formData.append('media', file);
  formData.append('name', file.name);
  formData.append('mime_type', file.type);

  // Channel to monitor upload progress
  const channel = yield call(
    createUploadChannel,
    api.createReportMedia,
    formData,
  );

  // Asynchronously listen for cancel action to close channel
  yield fork(watchForCancel, uploadId, channel);

  try {
    while (true) {
      const {
        progress = 0,
        error,
        response,
      } = yield take(channel);

      if (response) {
        yield put({
          ...action,
          type: CREATE_REPORT_MEDIA_SUCCESS,
          media: response,
        });

        continue;
      }

      if (error) {
        yield put({
          ...action,
          type: CREATE_REPORT_MEDIA_ERROR,
          error: _.get(error, 'response.data'),
        });

        continue;
      }

      yield put({
        ...action,
        type: CREATE_REPORT_MEDIA_PROGRESS,
        progress,
      });
    }
  } finally {
    // The channel most likely closed itself, but this handles accidental
    // usage of takeLatest
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* deleteReportMedia (action) {
  const { id } = action;

  try {
    yield minDelayCall(api.deleteReportMedia, id);

    yield put({ type: DELETE_REPORT_MEDIA_SUCCESS, id });
  } catch (error) {
    yield put({ type: DELETE_REPORT_MEDIA_ERROR, error: _.get(error, 'response.data') });
  }
}

export default function* rootSaga () {
  yield all([
    takeLatest(LOAD_REPORTS, loadReports),
    takeLatest(CREATE_REPORT, createReport),
    takeLatest(GET_CURR_REPORT, getCurrReport),
    takeLatest(UPDATE_REPORT, updateReport),
    takeLatest(DELETE_REPORT, deleteReport),
    takeLatest(LOAD_REPORT_MEDIA, loadReportMedia),
    takeEvery(CREATE_REPORT_MEDIA, createReportMedia),
    takeLatest(DELETE_REPORT_MEDIA, deleteReportMedia),
  ]);
}
