import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getUser } from '../redux/account/selectors';
import Loading from '../pages/Loading';

// A wrapper for <Route> that only renders if not authenticated.
// It redirects to the desired page if the user becomes authenticated.
export default function AnonymousRoute ({ children, ...rest }) {
  const user = useSelector(getUser);
  const userLoading = useSelector((s) => s.account.userLoading);
  const redirectLoc = useSelector((s) => s.account.redirectLoc);

  return (
    <Route
      {...rest}
      render={() => {
        if (userLoading) {
          return <Loading />;
        }

        return (!user
          ? children
          : <Redirect to={redirectLoc || '/'} />
        );
      }}
    />
  );
}
