import React from 'react';

import layout from '../styles/layout.module.scss';
import { PageHeader, LeftNavPlaceholder } from '../components';

// Zero-state navigation while user is loading
export default function Loading () {

  return (
    <div className={layout.app}>
      <LeftNavPlaceholder />
      <div className={layout.page}>
        <PageHeader placeholder />
        <div className={layout.pageContent}>
          <div className={layout.responsiveContainer}>
            <div className={layout.listCardPlaceholder}></div>
            <div className={layout.listCardPlaceholder}></div>
            <div className={layout.listCardPlaceholder}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
