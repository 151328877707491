import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackButton,
  PageHeader,
  KebabDropdown,
} from '../../components';
import { useRole, useConfirmAction } from '../../utils/hooks';
import { getCurrOrg } from '../../redux/orgs/selectors';
import ActivityForm from './ActivityForm';

import layout from '../../styles/layout.module.scss';
import { getCurrActivity, deleteActivity } from '../../redux/activities/actions';

export default function EditActivity () {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useRole();
  const currOrg = useSelector(getCurrOrg);
  const { id, orgSlug } = useParams();
  const [startedLoading, setStartedLoading] = useState(false);
  const currActivity = useSelector((s) => s.activities.currActivity);
  const pending = useSelector((s) => s.activities.getCurrActivityPending);
  const error = useSelector((s) => s.activities.getCurrActivityError);
  const confirmAction = useConfirmAction();

  // Load currActivity if not already
  useEffect(() => {
    if (currOrg && _.get(currActivity, 'id') != id) {
      dispatch(getCurrActivity(id));
      setStartedLoading(true);
    }
  }, [currOrg, currActivity]);

  // Failed to load Activity. Redirect
  useEffect(() => {
    if (startedLoading && error) {
      history.replace(`/app/${orgSlug}/`);
    }
  }, [startedLoading, error, history]);

  const removeActivity = useCallback(() => {
    confirmAction({
      action: 'delete',
      subjectType: 'activity',
      subject: 'this activity',
      onConfirm: () => dispatch(deleteActivity(
        id,
      )),
      pendingPath: 'activities.deleteActivityPending',
      errorPath: 'activities.deleteActivityError',
      onSuccess: history.goBack,
    });
  }, [currOrg, currActivity, confirmAction]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Update Activity"
        coverMenu
      >
        <BackButton/>
        { role != 'read' && (
          <KebabDropdown right>
            <DropdownItem onClick={removeActivity}>
              <FontAwesomeIcon icon="trash" />
              Delete Activity
            </DropdownItem>
          </KebabDropdown>
        )}
      </PageHeader>
      { (!currActivity || pending)
        ?
        <div className="text-center">
          <Spinner />
        </div>
        :
        <ActivityForm
          onSubmit={history.goBack}
          activity={currActivity}
        />
      }
    </div>
  );
}
