import { SET_MENU_OPEN } from './actions';

export const initialState = {
  menuOpen: false,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.open,
      };
    default:
      return state;
  }
};
