import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  DropdownItem,
  Label,
  CustomInput,
  FormFeedback,
  FormGroup,
} from 'reactstrap';
import cx from 'classnames';

import { getCurrOrg } from '../../redux/orgs/selectors';
import { loadPathogens, RESET_LOAD_PATHOGENS } from '../../redux/search/actions';
import { action } from '../../redux/helpers';
import { SearchDropdown } from './SearchDropdown';
import { getPathogens } from '../../redux/search/selectors';
import pathogenTypes from '../../utils/data/pathogens';

import text from '../../styles/text.module.scss';

// eslint-disable-next-line no-unused-vars
export const VaccinationInput = React.forwardRef((props, ref) => {
  const {
    value: vaccination,
    onChange,
    error,
    className,
    disabled,
  } = props;
  const [selectedType, setSelectedType] = useState(_.get(vaccination, 'pathogen.pathogen_type'));
  const dispatch = useDispatch();
  const currOrg = useSelector(getCurrOrg);
  const vaccinations = useSelector(getPathogens);
  const currPage = useSelector((s) => s.search.currPathogensPage);
  const hasMore = useSelector((s) => s.search.hasMorePathogens);
  const loading = useSelector((s) => s.search.loadPathogensPending);

  const resetVaccinations = useCallback(() => {
    dispatch(action(RESET_LOAD_PATHOGENS));
  }, [currOrg, selectedType]);

  const loadMoreVaccinations = useCallback(({ query }) => {
    dispatch(loadPathogens(currPage + 1, query, selectedType));
  }, [currPage, selectedType]);

  return (
    <div className={className}>
      <FormGroup>
        <Label>Vaccination Type *</Label>
        <CustomInput
          id="vaccination-type"
          type="select"
          value={selectedType}
          className="form-control"
          onChange={(e) => (
            setSelectedType(e.target.value),
            onChange({ ...vaccination, pathogen: '' })
          )}
          disabled={disabled}
        >
          { _.map(
            pathogenTypes,
            (p, index) => (
              <option
                key={`type-${index}`}
                value={p.value}
              >
                {p.text}
              </option>
            ),
          )}
        </CustomInput>
      </FormGroup>
      <Label>Vaccination *</Label>
      <SearchDropdown
        className={cx({'is-invalid': error})}
        placeholder="Select vaccination"
        displayText={_.get(vaccination, 'pathogen.name')}
        firstLoad={resetVaccinations}
        loadMore={loadMoreVaccinations}
        hasMore={hasMore}
        loading={loading}
        disabled={!selectedType || disabled}
      >
        { !loading && _.get(vaccinations, 'length', 0) == 0 && (
          <div className="text-center">
            No Vaccinations
          </div>
        )}
        { _.map(
          vaccinations,
          (v) => (
            <DropdownItem
              key={`vaccination-${v.id}`}
              onClick={() => onChange({ ...vaccination, pathogen: v })}
              className={cx({'selected': v.id == _.get(vaccination, 'pathogen.id')})}
            >
              <div className={text.ellipsize}>
                { v.name }
              </div>
            </DropdownItem>
          ),
        ) }
      </SearchDropdown>
      <FormFeedback role="alert">
        { _.get(error, 'pathogen') }
      </FormFeedback>
    </div>
  );
});
VaccinationInput.displayName = 'VaccinationInput';
VaccinationInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
VaccinationInput.defaultProps = {
  placeholder: 'Select vaccination',
  invalid: false,
};
