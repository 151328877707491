export default [
  {
    text: 'Select Type',
    value: '',
  },
  {
    text: 'Bacteria',
    value: 'bac',
  },
  {
    text: 'Apicomplexans',
    value: 'apicomp',
  },
  {
    text: 'Acanthocephalans',
    value: 'acanth',
  },
  {
    text: 'Cestodes',
    value: 'cestodes',
  },
  {
    text: 'Crustaceans',
    value: 'crust',
  },
  {
    text: 'Fungi and Fungus-like',
    value: 'fungi',
  },
  {
    text: 'Microsporidia',
    value: 'micro',
  },
  {
    text: 'Monogeneans and Digeneans',
    value: 'monodig',
  },
  {
    text: 'Myxozoans',
    value: 'myxo',
  },
  {
    text: 'Nematodes',
    value: 'nema',
  },
  {
    text: 'Viruses',
    value: 'virus',
  },
  {
    text: 'Protists',
    value: 'pro',
  },
  {
    text: 'Other Parasites',
    value: 'other',
  },
  {
    text: 'No Pathogen',
    value: 'no',
  },
];
