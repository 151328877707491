import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';

import { loginRequired } from '../redux/account/actions';
import { getUser } from '../redux/account/selectors';
import Loading from '../pages/Loading';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function LoggedInRoute ({ children, ...rest }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userLoading = useSelector((s) => s.account.userLoading);
  const location = useLocation();
  const inviteMatch = useRouteMatch('/app/accept/:key');

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(loginRequired(location));
    }
  }, [dispatch, location, user, userLoading]);

  return (
    <Route
      {...rest}
      render={() => {
        if (userLoading) {
          return <Loading />;
        }

        return (!!user
          ? children
          : (
            <Redirect to={inviteMatch
              ? `/signup/${inviteMatch.params.key}`
              : '/login'
            } />
          )
        );
      }}
    />
  );
}
