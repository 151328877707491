import { useEffect, useRef, useState } from 'react';

// Credit https://usehooks.com/useDebounce/
export function useDebounce (value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const didMount = useRef(false);

  useEffect(
    () => {
      if (didMount.current) {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed
        // within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      } else {
        didMount.current = true;
      }
    },
    [value, delay, didMount], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
