export default {
  physicalexternal: {
    display_name: 'Physical External',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'emaciated', label: 'Emaciated' },
      { key: 'depigmented', label: 'Depigmented' },
      { key: 'hyperpigmented', label: 'Hyperpigmented' },
      { key: 'exophthalmia', label: 'Exophthalmia' },
      { key: 'endophthalmia', label: 'Endophthalmia' },
      { key: 'swollen_abdomen', label: 'Swollen Abdomen' },
      { key: 'scolio_lord', label: 'Scoliosis/Lordosis' },
    ],
  },
  physicalinternal: {
    display_name: 'Physical Internal',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'postmortem_change', label: 'Postmortem Change' },
    ],
  },
  intestine: {
    display_name: 'Intestine',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'gas', label: 'Gas' },
      { key: 'mucus', label: 'Mucus' },
      { key: 'feces', label: 'Feces' },
      { key: 'fluid', label: 'Fluid' },
      { key: 'intussusception', label: 'Intussusception' },
      { key: 'severed', label: 'Severed' },
      { key: 'algae', label: 'Algae' },
    ],
  },
  liver: {
    display_name: 'Liver',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'pale', label: 'Pale' },
      { key: 'brown', label: 'Brown' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'friable', label: 'Friable' },
      { key: 'enlarged', label: 'Enlarged' },
      { key: 'congested', label: 'Congested' },
      { key: 'mottled', label: 'Mottled' },
      { key: 'green', label: 'Green' },
      { key: 'orange', label: 'Orange' },
    ],
  },
  kidney: {
    display_name: 'Kidney',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'pale', label: 'Pale' },
      { key: 'brown', label: 'Brown' },
      { key: 'friable', label: 'Friable' },
      { key: 'mottled', label: 'Mottled' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'enlarged', label: 'Enlarged' },
      { key: 'discolored', label: 'Discolored' },
      { key: 'soft', label: 'Soft' },
    ],
  },
  spleen: {
    display_name: 'Spleen',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'pale', label: 'Pale' },
      { key: 'brown', label: 'Brown' },
      { key: 'friable', label: 'Friable' },
      { key: 'enlarged', label: 'Enlarged' },
      { key: 'discolored', label: 'Discolored' },
      { key: 'atrophied', label: 'Atrophied' },
    ],
  },
  blood: {
    display_name: 'Blood',
    options: [
      { key: 'anemic', label: 'Anemic' },
      { key: 'brown', label: 'Brown' },
      { key: 'bright_red', label: 'Bright Red' },
    ],
  },
  stomach: {
    display_name: 'Stomach',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'gas', label: 'Gas' },
      { key: 'mucus', label: 'Mucus' },
      { key: 'food', label: 'Food' },
      { key: 'fluid', label: 'Fluid' },
      { key: 'algae', label: 'Algae' },
    ],
  },
  gills: {
    display_name: 'Gills',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'ulcerated', label: 'Ulcerated' },
      { key: 'pale', label: 'Pale' },
      { key: 'brown', label: 'Brown' },
      { key: 'bright_red', label: 'Bright Red' },
      { key: 'hyperplastic', label: 'Hyperplastic' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'aneurisms', label: 'Aneurisms' },
      { key: 'hamburger', label: 'Hamburger Gill-Like' },
      { key: 'clubbed', label: 'Clubbed' },
      { key: 'swollen', label: 'Swollen' },
      { key: 'cartilaginous', label: 'Cartilaginous Changes' },
      { key: 'eroded', label: 'Eroded' },
      { key: 'postmortem_change', label: 'Postmortem Changes' },
      { key: 'fungus', label: 'Fungus' },
      { key: 'columnaris', label: 'Columnaris' },
    ],
  },
  coelom: {
    display_name: 'Coelom',
    options: [
      { key: 'unremarkable', label: 'Unremarkable' },
      { key: 'ascities', label: 'Ascities' },
      { key: 'cloudy', label: 'Cloudy' },
      { key: 'hemorrhagic', label: 'Hemorrhagic' },
      { key: 'serous', label: 'Serous' },
      { key: 'gas', label: 'Gas' },
    ],
  },
  lesion: {
    display_name: 'Lesion',
    options: [
      { key: 'petechiae', label: 'Petechiae' },
      { key: 'ecchymosis', label: 'Ecchymosis' },
      { key: 'ulcerated', label: 'Ulcerated' },
      { key: 'smooth', label: 'Smooth' },
      { key: 'elevated', label: 'Elevated' },
      { key: 'eroded_fins', label: 'Eroded Fins' },
      { key: 'clean', label: 'Clean' },
      { key: 'dirty', label: 'Dirty' },
      { key: 'yellow', label: 'Yellow' },
      { key: 'red', label: 'Red' },
      { key: 'white', label: 'White' },
      { key: 'fungus', label: 'Fungus' },
      { key: 'columnaris', label: 'Columnaris' },
    ],
  },
};
