import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';

import layout from '../../styles/layout.module.scss';

import { forgotPassword } from '../../redux/account/actions';
import { GeneralErrors, PageHeader } from '../../components';

export default function ForgotPassword () {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitted } = formState;
  const pending = useSelector((s) => s.account.forgotPasswordPending);
  const error = useSelector((s) => s.account.forgotPasswordError);

  const submitSuccess = isSubmitted && !pending && !error;

  const onSubmit = useCallback((data) => {
    dispatch(forgotPassword(data.email));
  }, []);

  return (
    <div className={layout.app}>
      <div className={layout.page}>
        <PageHeader title={submitSuccess ? 'Check Your Email' : 'Forgot Password'} />
        <div className={layout.pageContent}>
          <div className={layout.responsiveContainer}>
            <p className={layout.pageDesc}>
              {
                submitSuccess
                  ? 'If an account is associated with the email provided, an ' +
                    'email will be sent over with instructions to reset your password.'
                  : 'Enter your email below and we will send you instructions ' +
                    'to reset your password.'
              }
            </p>
            { !submitSuccess && (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    required
                    innerRef={register}
                    type="email"
                    name="email"
                    placeholder="Email"
                    invalid={ isSubmitted && _.has(error, 'email') }
                  />
                  <FormFeedback role="alert">
                    { _.get(error, 'email') }
                  </FormFeedback>
                </FormGroup>
                { isSubmitted && <GeneralErrors error={error} /> }
                <Button
                  color="primary"
                  disabled={pending}
                  block
                >
                  Submit
                  { pending && <Spinner size="sm" /> }
                </Button>
                <Link
                  className="btn btn-secondary btn-block"
                  to="/login"
                >
                  Cancel
                </Link>
              </Form>
            )}
            { submitSuccess && (
              <Link
                className="btn btn-primary btn-block"
                to="/login"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
