import React, { useCallback, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import cx from 'classnames';

import {
  createSampleMedia,
  cancelSampleUpload,
} from '../../redux/samples/actions';
import {
  BackButton,
  MediaCard,
  MediaCarousel,
  PageHeader,
} from '../../components';
import {
  deleteSampleMedia,
  deleteSampleUpload,
} from '../../redux/samples/actions';
import {
  useConfirmAction,
  useReadOnly,
  useSampleMedia,
  useToggle,
} from '../../utils/hooks';

import layout from '../../styles/layout.module.scss';
import tables from '../../styles/tables.module.scss';

export default function SampleMedia () {
  const readOnly = useReadOnly();
  const history = useHistory();
  const uploadRef = useRef();
  const mediaCarousel = useToggle();
  const dispatch = useDispatch();
  const { id } = useParams();
  const confirmAction = useConfirmAction();
  const [initialIndex, setInitialIndex] = useState(0);
  const {
    sampleMedia,
    uploads,
    hasMore,
    loading,
    loadMore,
  } = useSampleMedia(id);

  const uploadMedia = useCallback((files) => {
    _.forEach(
      files,
      (file) => dispatch(createSampleMedia(id, file)),
    );

    uploadRef.current.value = '';
  }, [id]);

  const onRemoveMedia = useCallback((media) => {
    confirmAction({
      action: 'delete',
      subjectType: 'media',
      subject: media.name,
      onConfirm: () => dispatch(deleteSampleMedia(media.id)),
      pendingPath: 'samples.deleteSampleMediaPending',
      errorPath: 'samples.deleteSampleMediaError',
    });
  }, []);

  const onRemoveUpload = useCallback((upload) => {
    dispatch(deleteSampleUpload(id, upload.id));
  }, [id]);

  const onCancelUpload = useCallback((upload) => {
    dispatch(cancelSampleUpload(id, upload.id));
  }, [id]);

  return (

    <div className={layout.page}>
      <PageHeader
        title="Media"
        coverMenu
      >
        <BackButton/>
      </PageHeader>
      <div className={layout.pageContent}>
        <div className={cx(layout.responsiveContainer, 'text-center')}>
          {!readOnly && (
            <>
              <input
                id="media-files"
                ref={uploadRef}
                type="file"
                accept="image/*,video/*"
                onChange={(e) => uploadMedia(e.target.files)}
                className="btn-upload"
                multiple
              />
              <label
                htmlFor="media-files"
                className="btn btn-primary btn-round my-3"
                style={{marginBottom: 0}}
              >
                <FontAwesomeIcon icon="plus"/>
                Upload Photo or Video
              </label>
            </>
          )}
          {/* Uploads */}
          {_.map(uploads, (upload) => (
            <MediaCard
              key={`upload-${upload.id}`}
              media={upload}
              belongsTo="sample"
              onRemove={onRemoveUpload}
              onCancel={onCancelUpload}
            />
          ))}
          {/* Media */}
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!loading && hasMore}
            useWindow={false}
            style={{minWidth: 0}}
          >
            {_.map(sampleMedia, (media, idx) => (
              <MediaCard
                key={`media-${media.id}`}
                media={media}
                belongsTo="sample"
                onRemove={onRemoveMedia}
                onClick={() => {
                  setInitialIndex(idx);
                  mediaCarousel.toggle();
                }}
              />
            ))}
            { loading && (
              <div
                className="text-center"
                key="spinner"
              >
                <Spinner className={cx(tables.spinner, 'mt-3')}  />
              </div>
            )}
          </InfiniteScroll>
          <Button
            className="mt-3"
            color="secondary"
            onClick={history.goBack}
            block
          >
            Back
          </Button>
        </div>
      </div>
      <MediaCarousel
        media={sampleMedia}
        belongsTo="sample"
        initialIndex={initialIndex}
        {...mediaCarousel}
      />
    </div>
  );
}
