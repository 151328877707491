import * as _ from 'lodash';

import { compressSearch } from '../../utils/helpers';

export const getReports = ({ reports }) => reports.reports;
export const getReportMedia = ({ reports }) => reports.reportMedia;
export const getReportFilters = ({ reports }) => reports.reportFilters;
export const getReportSearchCtx = ({ reports }) => reports.searchCtx;

const shouldOmit = _.overSome(_.isNil, _.isEmpty);

export const getLocalReportSearchCtx = ({ reports }) => {
  const filters = _.omitBy(
    reports.reportFilters,
    shouldOmit,
  );

  if (!_.isEmpty(filters)) {
    return compressSearch(filters);
  }

  return null;
};

export const getNumFilters = ({ reports }) => {
  return _.sumBy(
    _.keys(reports.reportFilters),
    (key) => {
      const val = reports.reportFilters[key];

      if (shouldOmit(val)) {
        return 0;
      }

      return 1;
    },
  );
};
