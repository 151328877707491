export const RESET_LOAD_PROD_CYCLES = 'RESET_LOAD_PROD_CYCLES';
export const LOAD_PROD_CYCLES = 'LOAD_PROD_CYCLES';
export const LOAD_PROD_CYCLES_SUCCESS = 'LOAD_PROD_CYCLES_SUCCESS';
export const LOAD_PROD_CYCLES_ERROR = 'LOAD_PROD_CYCLES_ERROR';
export const GET_CURR_PROD_CYCLE = 'GET_CURR_PROD_CYCLE';
export const GET_CURR_PROD_CYCLE_SUCCESS = 'GET_CURR_PROD_CYCLE_SUCCESS';
export const GET_CURR_PROD_CYCLE_ERROR = 'GET_CURR_PROD_CYCLE_ERROR';
export const SET_CURR_PROD_CYCLE = 'SET_CURR_PROD_CYCLE';
export const CREATE_PROD_CYCLE = 'CREATE_PROD_CYCLE';
export const UPDATE_PROD_CYCLE = 'UPDATE_PROD_CYCLE';
export const CREATE_PROD_CYCLE_SUCCESS = 'CREATE_PROD_CYCLE_SUCCESS';
export const UPDATE_PROD_CYCLE_SUCCESS = 'UPDATE_PROD_CYCLE_SUCCESS';
export const CREATE_PROD_CYCLE_ERROR = 'CREATE_PROD_CYCLE_ERROR';
export const UPDATE_PROD_CYCLE_ERROR = 'UPDATE_PROD_CYCLE_ERROR';
export const DELETE_PROD_CYCLE = 'DELETE_PROD_CYCLE';
export const DELETE_PROD_CYCLE_SUCCESS = 'DELETE_PROD_CYCLE_SUCCESS';
export const DELETE_PROD_CYCLE_ERROR = 'DELETE_PROD_CYCLE_ERROR';

export const loadProdCycles = (id, page) => {
  return {
    type: LOAD_PROD_CYCLES,
    id,
    page,
  };
};

export const createProdCycle = (formData) => {
  return {
    type: CREATE_PROD_CYCLE,
    formData,
  };
};

export const updateProdCycle = (formData) => {
  return {
    type: UPDATE_PROD_CYCLE,
    formData,
  };
};

export const deleteProdCycle = (id) => {
  return {
    type: DELETE_PROD_CYCLE,
    id,
  };
};

export const setCurrProdCycle = (prodCycle) => {
  return {
    type: SET_CURR_PROD_CYCLE,
    prodCycle,
  };
};

export const getCurrProdCycle = (id) => {
  return {
    type: GET_CURR_PROD_CYCLE,
    id,
  };
};
