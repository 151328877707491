import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getLocalReportSearchCtx, getReports } from '../../redux/reports/selectors';
import { CLEAR_REPORT_FILTERS, loadReports, RESET_LOAD_REPORTS } from '../../redux/reports/actions';
import { action } from '../../redux/helpers';
import { getCurrOrg } from '../../redux/orgs/selectors';
import _ from 'lodash';

export const useReports = (cycleId) => {
  const dispatch = useDispatch();
  const firstLoad = useRef(false);
  const { orgSlug } = useParams();
  const [resetCalled, setResetCalled] = useState(false);
  const reports = useSelector(getReports);
  const currOrg = useSelector(getCurrOrg);
  const currPage = useSelector((s) => s.reports.currReportsPage);
  const hasMore = useSelector((s) => s.reports.hasMoreReports);
  const loading = useSelector((s) => s.reports.loadReportsPending);
  const sortDesc = useSelector((s) => s.reports.sortDesc);
  const localSearchCtx = useSelector(getLocalReportSearchCtx);

  useEffect(() => {
    if (loading) {
      firstLoad.current = true;
    }
  }, [loading]);

  const noResults = useMemo(() => {
    return (
      firstLoad.current &&
      !loading &&
      !hasMore &&
      _.get(reports, 'length', 0) == 0
    );
  }, [loading, hasMore, reports]);

  const reset = useCallback(() => {
    if (cycleId) {
      dispatch(action(CLEAR_REPORT_FILTERS));
    }
    dispatch(action(RESET_LOAD_REPORTS));
    setResetCalled(true);
  }, [cycleId, currOrg]);

  // Reset list anytime currOrg or search criteria changes
  useEffect(() => {
    if (currOrg || cycleId) {
      reset();
    }
  }, [cycleId, currOrg, localSearchCtx, sortDesc]);

  // Clear filters if currOrg changes
  useEffect(() => {
    if (currOrg && firstLoad.current) {
      dispatch(action(CLEAR_REPORT_FILTERS));
    }
  }, [currOrg]);

  const loadMore = useCallback(() => {
    dispatch(loadReports({
      slug: orgSlug,
      cycleId,
      searchCtx: localSearchCtx,
      page: currPage + 1,
    }));
  }, [cycleId, orgSlug, localSearchCtx, currPage]);

  return {
    // Ignore redux list until first load
    reports: firstLoad.current ? reports : [],
    hasMore: resetCalled && hasMore,
    loading,
    noResults,
    loadMore,
  };
};
