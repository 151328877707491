import * as Actions from './actions';
import {
  LOGOUT,
  SIGNUP_SUCCESS,
} from '../account/actions';
import * as _ from 'lodash';

const resetOrgList = {
  organizations: [],
  hasMoreOrgs: true,
  currOrgsPage: 0,
};

export const initialState = {
  ...resetOrgList,
  hasMoreOrgs: false,
  invite: null,
  invitePending: false,
  inviteError: null,
  currOrg: null,
  switchOrgPending: false,
  orgDetails: null,
  getOrgDetailsPending: false,
  getOrgsPending: false,
  getOrgsError: null,
  changeOrgPending: false,
  changeOrgError: null,
  deleteOrgPending: false,
  deleteOrgError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.VALIDATE_INVITE:
    case Actions.ACCEPT_INVITE:
      return {
        ...state,
        invite: null,
        invitePending: true,
        inviteError: null,
      };
    case Actions.VALIDATE_INVITE_SUCCESS:
      return {
        ...state,
        invite: _.pick(action, ['org', 'key']),
        invitePending: false,
        inviteError: null,
      };
    case Actions.ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        ...resetOrgList,
        invite: _.pick(action, ['org', 'key']),
        invitePending: false,
        inviteError: null,
      };
    case Actions.VALIDATE_INVITE_ERROR:
    case Actions.ACCEPT_INVITE_ERROR:
      return {
        ...state,
        invitePending: false,
        inviteError: action.error,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        invite: null,
        invitePending: false,
        inviteError: null,
      };
    case Actions.SWITCH_ORG:
      return {
        ...state,
        switchOrgPending: true,
      };
    case Actions.SWITCH_ORG_SUCCESS:
      return {
        ...state,
        currOrg: action.org,
        switchOrgPending: false,
        switchOrgError: null,
      };
    case Actions.SWITCH_ORG_ERROR:
      return {
        ...state,
        switchOrgPending: false,
        switchOrgError: action.error,
      };
    case Actions.GET_ORG_DETAILS:
      return {
        ...state,
        getOrgDetailsPending: true,
      };
    case Actions.GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        orgDetails: action.details,
        getOrgDetailsPending: false,
        getOrgDetailsError: null,
      };
    case Actions.GET_ORG_DETAILS_ERROR:
      return {
        ...state,
        getOrgDetailsPending: false,
        getOrgDetailsError: action.error,
      };
    case Actions.RESET_GET_ORGS:
      return {
        ...state,
        ...resetOrgList,
      };
    case Actions.GET_ORGS:
      return {
        ...state,
        getOrgsPending: true,
      };
    case Actions.GET_ORGS_SUCCESS:
      return {
        ...state,
        getOrgsPending: false,
        getOrgsError: null,
        hasMoreOrgs: !!action.next,
        currOrgsPage: action.page,
        organizations: [
          ...state.organizations,
          // Discard duplicates in case of page shifts
          ..._.differenceBy(action.results, state.organizations, 'slug'),
        ],
      };
    case Actions.GET_ORGS_ERROR:
      return {
        ...state,
        getOrgsPending: false,
        getOrgsError: action.error,
        hasMoreOrgs: false,
      };
    case Actions.CREATE_ORG:
    case Actions.UPDATE_ORG:
      return {
        ...state,
        changeOrgPending: true,
      };
    case Actions.CREATE_ORG_SUCCESS:
      return {
        ...state,
        changeOrgPending: false,
        changeOrgError: null,
        ...resetOrgList,
      };
    case Actions.UPDATE_ORG_SUCCESS:
      return {
        ...state,
        changeOrgPending: false,
        changeOrgError: null,
        currOrg: action.org,
        ...resetOrgList,
      };
    case Actions.CREATE_ORG_ERROR:
    case Actions.UPDATE_ORG_ERROR:
      return {
        ...state,
        changeOrgPending: false,
        changeOrgError: action.error,
      };
    case Actions.DELETE_ORG:
      return {
        ...state,
        deleteOrgPending: true,
      };
    case Actions.DELETE_ORG_SUCCESS:
      return {
        ...state,
        deleteOrgPending: false,
        deleteOrgError: null,
        ...resetOrgList,
      };
    case Actions.DELETE_ORG_ERROR:
      return {
        ...state,
        deleteOrgPending: false,
        deleteOrgError: action.error,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
