import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import ActivityForm from './ActivityForm';

import layout from '../../styles/layout.module.scss';

export default function CreateActivity () {
  const history = useHistory();
  const { orgSlug } = useParams();
  const currActivity = useSelector((s) => s.activities.currActivity);

  const goBack = useCallback(() => {
    history.replace(`/app/${orgSlug}/activities/${_.get(currActivity, 'id')}/edit`);
    history.goBack();
  }, [orgSlug, currActivity]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="Log Activity"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <ActivityForm onSubmit={goBack} />
    </div>
  );
}
