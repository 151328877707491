import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swiper from 'react-id-swiper';
import cx from 'classnames';

import { VideoPlayer } from '../video/VideoPlayer';

import text from '../../styles/text.module.scss';
import styles from './MediaCarousel.module.scss';

export const NO_SWIPING = 'swiper-no-swiping';

const swiperParams = {
  slidesPerView: 'auto',
  zoom: true,
  preventClick: false,
  preventClickPropagation: false,
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
};

export function MediaCarousel (props) {
  const {
    open,
    toggle,
    media,
    belongsTo,
    initialIndex = 0,
  } = props;
  const swiperRef = useRef();
  const [swiperMounted, setSwiperMounted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const getSwiper = useCallback(() => {
    return _.get(swiperRef, 'current.swiper');
  }, []);

  const gotoPrev = useCallback(() => {
    const swiper = getSwiper();
    if (swiper) {
      swiper.slideTo(Math.max(swiper.activeIndex - 1, 0));
    }
  }, [media]);

  const gotoNext = useCallback(() => {
    const swiper = getSwiper();
    if (swiper) {
      swiper.slideTo(Math.min(swiper.activeIndex + 1, media.length - 1));
    }
  }, [media]);

  // Update swiper if media changes after first render
  useEffect(() => {
    if (swiperMounted) {
      getSwiper().update();
    }
  }, [media, swiperMounted]);

  // Listen for slide change
  useEffect(() => {
    if (swiperMounted) {
      const swiper = getSwiper();
      const listener = () => setActiveIndex(swiper.activeIndex);

      swiper.on('slideChange', listener);

      return () => swiper.off('slideChange', listener);
    }
  }, [swiperMounted]);

  useEffect(() => {
    if (!open) {
      setSwiperMounted(false);
    } else {
      // Wait for DOM to render swiper before initializing
      setTimeout(() => {
        if (getSwiper()) {
          getSwiper().slideTo(initialIndex);
          setActiveIndex(initialIndex);
          setSwiperMounted(true);
        }
      });
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={styles.carousel}
    >
      <div className={styles.controls}>
        <span className={cx(styles.title, text.ellipsize)}>
          {_.get(media, `${activeIndex}.name`)}
        </span>

        <a
          href={`/api/${belongsTo}-media/${_.get(media, `${activeIndex}.id`)}/download/`}
          aria-label="Download media file"
          download={_.get(media, `${activeIndex}.name`)}
          onClick={(e) => e.currentTarget.blur()}
        >
          <FontAwesomeIcon icon="download" />
        </a>
        <Button
          color="link"
          onClick={gotoPrev}
          disabled={activeIndex == 0}
        >
          <FontAwesomeIcon icon="angle-left" />
        </Button>
        <Button
          color="link"
          onClick={gotoNext}
          disabled={activeIndex >= media.length - 1}
        >
          <FontAwesomeIcon icon="angle-right" />
        </Button>
        <Button
          color="link"
          onClick={toggle}
        >
          <FontAwesomeIcon icon="times" />
        </Button>
      </div>
      <Swiper
        ref={swiperRef}
        {...swiperParams}
      >
        {_.map(
          media,
          (m, idx) => {
            const isImage = _.startsWith(m.mime_type, 'image/');
            const isVideo = _.startsWith(m.mime_type, 'video/');

            return (
              <div
                key={`carousel-${m.id}`}
                className={cx(
                  styles.slide,
                  { 'swiper-zoom-container': isImage && !_.endsWith(m.media, '.svg') },
                )}
              >
                {
                  _.endsWith(m.media, '.svg') && (
                    <object
                      data={m.media}
                      type={m.mime_type}
                      width="100%"
                    >
                      Unable to display SVG
                    </object>
                  ) ||
                  isImage && (
                    <img
                      className={styles.image}
                      src={m.media}
                      alt={m.name}
                    />
                  ) ||
                  isVideo && (
                    <VideoPlayer
                      mediaUrl={m.media}
                      active={idx == activeIndex}
                    />
                  ) ||
                  <div>File not previewable</div>
                }
              </div>
            );
          },
        )}
      </Swiper>
    </Modal>
  );
}
MediaCarousel.propTypes = {
  belongsTo: PropTypes.oneOf(['report', 'sample']).isRequired,
};
