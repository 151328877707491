import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackButton, PageHeader } from '../../components';
import { getCurrOrg } from '../../redux/orgs/selectors';
import SampleForm from './SampleForm';

import layout from '../../styles/layout.module.scss';

export default function CreateSample () {
  const currOrg = useSelector(getCurrOrg);
  const currSample= useSelector((s) => s.samples.currSample);
  const history = useHistory();

  const editSample = useCallback(() => {
    history.replace(`/app/${currOrg.slug}/samples/${_.get(currSample, 'id')}`);
  }, [currOrg, currSample]);

  return (
    <div className={layout.page}>
      <PageHeader
        title="New Fish Sample"
        coverMenu
      >
        <BackButton />
      </PageHeader>
      <SampleForm onSubmit={editSample} />
    </div>
  );
}
