import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import * as _ from 'lodash';
import cx from 'classnames';

import layout from '../styles/layout.module.scss';
import {
  CreateDrawer,
  CreateDrawerItem,
  PageHeader,
} from '../components';
import { getUser } from '../redux/account/selectors';

export default function AppIndex () {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    if (
      !_.get(user, 'is_superuser', false) &&
      _.get(user, 'default_org_slug')
    ) {
      history.replace(`/app/${user.default_org_slug}`);
    }
  }, [user, dispatch]);

  const gotoCreateOrg = useCallback(
    () => history.push('/app/create-org'),
    [history],
  );

  return (
    <div className={layout.page}>
      <PageHeader title="Global Dashboard" />
      {user.is_superuser && (
        <CreateDrawer>
          <CreateDrawerItem
            label="New Org"
            icon="users"
            onClick={gotoCreateOrg}
          />
        </CreateDrawer>
      )}
      <div className={cx(layout.pageContent, layout.pageContentDrawer)}>
        <Card>
          <CardBody className="text-center">
            {user.is_superuser
              ? 'You\'re logged in as a super user!'
              : 'You don\'t have any organizations'
            }

          </CardBody>
        </Card>
      </div>
    </div>
  );
}
