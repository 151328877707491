import React from 'react';
import * as pako from 'pako';
import * as _ from 'lodash';

export const difference = (object, base) => {
  function changes (object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
};

export const keyCodes = {
  tab: 9,
  enter: 13,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
};

export const key = (e) => e.which || e.keyCode || 0;

export const onUserInteract = (callback) => {
  return (e) => {
    if (
      e.type == 'click' ||
      _.includes([keyCodes.space, keyCodes.enter], key(e))
    ) {
      callback(e);
    }
  };
};

export const compressSearch = (params) => {
  if (_.has(params, 'startDate')) {
    // Adjust local date to start of day in UTC
    params.startDate = params.startDate.replace(
      /T\d{2}:\d{2}:\d{2}\.\d{3}Z/,
      'T00:00:00.000Z',
    );
  }

  if (_.has(params, 'endDate')) {
    // Adjust local date to end of day in UTC
    params.endDate = params.endDate.replace(
      /T\d{2}:\d{2}:\d{2}\.\d{3}Z/,
      'T23:59:59.000Z',
    );
  }
  const compressedZlib = pako.deflate(
    JSON.stringify(params),
    { to: 'string' },
  );

  // We use this instead of btoa() to allow for server-side execution
  return Buffer.from(compressedZlib, 'binary').toString('base64');
};

export const decompressSearch = (searchCtx) => {
  // We use this instead of atob() to allow for server-side execution
  const decoded = Buffer.from(searchCtx, 'base64').toString('binary');
  let uncompressed = pako.inflate(decoded, { to: 'string' });
  uncompressed = JSON.parse(uncompressed);

  return {
    ...uncompressed,
    ..._.mapValues(
      _.pick(uncompressed, ['startDate', 'endDate']),
      (utc_iso) => {
        if (utc_iso) {
          // Add tz offset
          const date = new Date(utc_iso);
          const tzOffset = date.getTimezoneOffset();

          return new Date(date.getTime() + (tzOffset * 60 * 1000));
        }

        return utc_iso;
      },
    ),
  };
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  let result = new Date(date.valueOf());

  return result.toLocaleDateString(
    'default',
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  );
};

export const formatDateShort = (date) => {
  if (!date) {
    return '';
  }

  let result = new Date(date.valueOf());

  return result.toLocaleDateString(
    'default',
    {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    },
  );
};

export const formatTemp = (temp) => {
  if (!temp) {
    return '-';
  }

  return `${temp.value}° ${temp.unit.toUpperCase()}`;
};

export const lookupOptionValue = (arr, v) => {
  if (_.isEmpty(v) || _.isNil(v)) {
    return '-';
  }

  const option = _.find(arr, ({ value }) => v == value );

  return _.get(option, 'text', '-');
};

export const display = (obj, key) => _.get(obj, key) || '-';

export const displayArrayNames = (arr) => {
  if (_.isEmpty(arr) || _.isNil(arr)) {
    return '-';
  }

  return (
    <>
      { _.map(
        arr,
        (obj) => (
          <div key={_.uniqueId('obj-')}>
            {obj.name}
          </div>
        ),
      )}
    </>
  );
};
