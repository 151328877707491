import * as Actions from './actions';

const resetSpeciesList = {
  species: [],
  hasMoreSpecies: true,
  currSpeciesPage: 0,
};

const resetPathogensList = {
  pathogens: [],
  hasMorePathogens: true,
  currPathogensPage: 0,
};

const resetBehaviorsList = {
  behaviors: [],
  hasMoreBehaviors: true,
  currBehaviorsPage: 0,
};

const resetMiscDetailsList = {
  details: [],
  hasMoreMiscDetails: true,
  currMiscDetailsPage: 0,
};

const resetTreatmentsList = {
  treatments: [],
  hasMoreTreatments: true,
  currTreatmentsPage: 0,
};

export const initialState = {
  ...resetSpeciesList,
  ...resetPathogensList,
  ...resetBehaviorsList,
  ...resetTreatmentsList,
  ...resetMiscDetailsList,
  hasMoreSpecies: false,
  hasMorePathogens: false,
  hasMoreBehaviors: false,
  hasMoreTreatments: false,
  hasMoreMiscDetails: false,
  loadSpeciesPending: false,
  loadPathogensPending: false,
  loadBehaviorsPending: false,
  loadTreatmentsPending: false,
  loadMiscDetailsPending: false,
  loadSpeciesError: null,
  loadPathogensError: null,
  loadBehaviorsError: null,
  loadTreatmentsError: null,
  loadMiscDetailsError: null,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case Actions.RESET_LOAD_SPECIES:
      return {
        ...state,
        ...resetSpeciesList,
      };
    case Actions.LOAD_SPECIES:
      return {
        ...state,
        loadSpeciesError: null,
        loadSpeciesPending: true,
      };
    case Actions.LOAD_SPECIES_SUCCESS:
      return {
        ...state,
        loadSpeciesPending: false,
        species: [
          ...state.species,
          ...action.results,
        ],
        hasMoreSpecies: !!action.next,
        currSpeciesPage: action.page,
      };
    case Actions.LOAD_SPECIES_ERROR:
      return {
        ...state,
        loadSpeciesPending: false,
        loadSpeciesError: action.error,
        hasMoreSpecies: false,
      };
    case Actions.RESET_LOAD_PATHOGENS:
      return {
        ...state,
        ...resetPathogensList,
      };
    case Actions.LOAD_PATHOGENS:
      return {
        ...state,
        loadPathogensError: null,
        loadPathogensPending: true,
      };
    case Actions.LOAD_PATHOGENS_SUCCESS:
      return {
        ...state,
        loadPathogensPending: false,
        pathogens: [
          ...state.pathogens,
          ...action.results,
        ],
        hasMorePathogens: !!action.next,
        currPathogensPage: action.page,
      };
    case Actions.LOAD_PATHOGENS_ERROR:
      return {
        ...state,
        loadPathogensPending: false,
        loadPathogensError: action.error,
        hasMorePathogens: false,
      };
    case Actions.RESET_LOAD_BEHAVIORS:
      return {
        ...state,
        ...resetBehaviorsList,
      };
    case Actions.LOAD_BEHAVIORS:
      return {
        ...state,
        loadBehaviorsError: null,
        loadBehaviorsPending: true,
      };
    case Actions.LOAD_BEHAVIORS_SUCCESS:
      return {
        ...state,
        loadBehaviorsPending: false,
        behaviors: [
          ...state.behaviors,
          ...action.results,
        ],
        hasMoreBehaviors: !!action.next,
        currBehaviorsPage: action.page,
      };
    case Actions.LOAD_BEHAVIORS_ERROR:
      return {
        ...state,
        loadBehaviorsPending: false,
        loadBehaviorsError: action.error,
        hasMoreBehaviors: false,
      };
    case Actions.RESET_LOAD_TREATMENTS:
      return {
        ...state,
        ...resetTreatmentsList,
      };
    case Actions.LOAD_TREATMENTS:
      return {
        ...state,
        loadTreatmentsError: null,
        loadTreatmentsPending: true,
      };
    case Actions.LOAD_TREATMENTS_SUCCESS:
      return {
        ...state,
        loadTreatmentsPending: false,
        treatments: [
          ...state.treatments,
          ...action.results,
        ],
        hasMoreTreatments: !!action.next,
        currTreatmentsPage: action.page,
      };
    case Actions.LOAD_TREATMENTS_ERROR:
      return {
        ...state,
        loadTreatmentsPending: false,
        loadTreatmentsError: action.error,
        hasMoreTreatments: false,
      };
    case Actions.RESET_LOAD_MISC_DETAILS:
      return {
        ...state,
        ...resetMiscDetailsList,
      };
    case Actions.LOAD_MISC_DETAILS:
      return {
        ...state,
        loadMiscDetailsError: null,
        loadMiscDetailsPending: true,
      };
    case Actions.LOAD_MISC_DETAILS_SUCCESS:
      return {
        ...state,
        loadMiscDetailsPending: false,
        details: [
          ...state.details,
          ...action.results,
        ],
        hasMoreMiscDetails: !!action.next,
        currMiscDetailsPage: action.page,
      };
    case Actions.LOAD_MISC_DETAILS_ERROR:
      return {
        ...state,
        loadMiscDetailsPending: false,
        loadMiscDetailsError: action.error,
        hasMoreMiscDetails: false,
      };
    default:
      return state;
  }
};
